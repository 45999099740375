import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0.5rem 0 0.5rem;

  .close-bt {
    padding: 0;
  }
`

export const Content = styled.div`
  padding: 0.5rem 1.5rem;
`

export const Footer = styled.div`
  --button-background-color: var(--primary500);
  --button-border-radius: 8px;
  margin: 0 0.5rem 0.5rem 0.5rem;

  .submit-bt {
    width: 100%;
  }
`
