import React, { useEffect, useState } from 'react'

import { useConfigs } from 'contexts/ConfigsContext'
import { ECurrentMainTab } from 'enums/EContexts'

import { Redirect, RouteComponentProps } from '@reach/router'

const RouterPage = (_: RouteComponentProps) => {
  const { configs } = useConfigs()
  const [redirectLP, setRedirectLP] = useState<boolean>(false)

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (params.chkrdt === 'seyrdt') setRedirectLP(true)
    if (params.isDeliveryAccess === 'true') {
      localStorage.setItem('DUOGOURMET.isDeliveryAccess', 'true')
      localStorage.setItem('DUOGOURMET.deliveryAccessDate', String(Date.now()))
    }
  }, [])

  if (!configs?.subscriber?.duoGourmetInterCustomer || redirectLP) {
    return <Redirect noThrow to="/landing-page" />
  }

  return <Redirect noThrow to={`/home/${ECurrentMainTab.home}`} />
}

export default RouterPage
