import React, { useEffect } from 'react'

import ImgSuccess from 'assets/imgs/blackcard/success.svg'
import ImgError from 'assets/imgs/blackcard/error.svg'
import { ECurrentMainTab } from 'enums/EContexts'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { useNavigate } from '@reach/router'

import * as S from './ExperiencesRating.styles'

interface RatingProps {
  success: boolean
}

const RatingResponse = ({ success }: RatingProps) => {
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' })
  }, [])

  return (
    <S.ResponseWrapper>
      <S.ResponseHeader>
        <Text variant="headline-h3" semiBold>
          {success ? 'Avaliação realizada' : 'Avaliação não realizada'}
        </Text>
      </S.ResponseHeader>
      <S.ResponseContainer>
        <S.ResponseImg>
          <img src={success ? ImgSuccess : ImgError} alt="" />
        </S.ResponseImg>
        <S.ResponseText>
          <Text variant="headline-h1" semiBold>
            {success ? 'Agradecemos pela sua avaliação!' : 'Experiência não avaliada'}
          </Text>
          <Text variant="body-3">
            {success
              ? 'Ela é importante e faz o Duo Gourmet ficar cada vez melhor pra você.'
              : 'Tivemos um problema ao enviar sua avaliação. Você pode tentar novamente mais tarde.'}
          </Text>
        </S.ResponseText>
      </S.ResponseContainer>
      <S.ResponseButtons>
        {success ? (
          <Button onClick={() => navigate(`/home/${ECurrentMainTab.experiences}`)}>
            Voltar para o Duo Gourmet
          </Button>
        ) : (
          <>
            <Button onClick={() => navigate(-1)}>Tentar novamente</Button>
            <Button
              variant="secondary"
              onClick={() => navigate(`/home/${ECurrentMainTab.experiences}`)}
            >
              Voltar para o Duo Gourmet
            </Button>
          </>
        )}
      </S.ResponseButtons>
    </S.ResponseWrapper>
  )
}

export default RatingResponse
