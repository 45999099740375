import React, { useContext, useState } from 'react'

import { bridgeNative } from 'App'
import { AppContainer } from 'App.styles'
import AppBar from 'components/AppBar'
import BlackcardContext from 'contexts/BlackcardContext'
import Divider from 'components/Divider'
import useAsync from 'hooks/UseAsync'
import { applyMask } from 'utils/inputUtils'
import Error from 'components/Error'

import { interWbNavigate } from '@interco/inter-webview-bridge'
import { Button } from '@interco/inter-ui/components/Button'
import { Checkbox } from '@interco/inter-ui/components/Checkbox'
import { Skeleton } from '@interco/inter-ui/components/Skeleton'
import { Text } from '@interco/inter-ui/components/Text'

import { ButtonContent, DataReview } from './BlackCard.styles'
import RequestStatus from './RequestStatus'
import * as T from './BlackCard.tags'

interface IUserData {
  fullName: string
  cpf: string
  dateOfBirth: string
  motherName: string
  cardName: string
  address: string
}

interface RequestResponse {
  isEligible: boolean
}

const StepTwo = () => {
  const { goToNext } = useContext(BlackcardContext)
  const [termsOfUse, setTermsOfUse] = useState(false)
  const [userData, getUserData] = useAsync<IUserData>('get')
  const [, postCardRequest] = useAsync<RequestResponse>('post')
  const [isLoadingBt, setIsLoadingBt] = useState(false)
  const [cardRequested, setCardRequested] = useState(false)
  const [requestFailed, setRequestFailed] = useState(false)

  const handleOnChangeTermsOfUse = () => {
    setTermsOfUse(!termsOfUse)
  }

  const handleOpenPdf = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    bridgeNative.execute({
      action: 'sendToTermsSubscription',
      metadata: {
        pdfUrl: 'https://marketplace.bancointer.com.br/foods/blackcardterm.pdf',
      },
    })
  }

  const onClickHandler = async () => {
    try {
      setIsLoadingBt(true)
      T.handleTagRequestStep({ ref_figma: '4', step: '2' })
      const response = await postCardRequest(`app-pf/foods/subscription/v1/blackCard/request`)
      if (response.isEligible) {
        goToNext()
      } else {
        setCardRequested(true)
      }
    } catch (error) {
      window.console.log(error)
      setRequestFailed(true)
    }
  }

  const handleUpdateData = async () => {
    try {
      T.handleTagUpdateData()
      await interWbNavigate.openDeepLink('bancointer://atualizacaoCadastral')
    } catch (error) {
      window.console.error(error)
    }
  }

  const standardScreen = () =>
    (userData.isSuccess || userData.isPending) && !cardRequested && !requestFailed

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await getUserData(`app-pf/foods/subscription/v1/blackCard/info`)
      } catch (error) {
        window.console.error(error)
      }
    }
    fetchData()
  }, [])

  if (standardScreen()) {
    const shimmerHeight = '1.3rem'
    return (
      <>
        <AppBar
          name="Revisão dos dados"
          backTag={() => T.handleTagHeaderStep({ ref_figma: '10', step: '2' })}
        />
        <AppContainer
          fullHeight
          style={{
            paddingTop: '1.5rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <DataReview>
            <div className="header">
              <Text variant="headline-h3">Dados do cartão Inter Black</Text>
            </div>
            <div className="item-title">
              <Text variant="body-3">Nome completo</Text>
            </div>
            <div className="item-body">
              {userData.isSuccess && (
                <Text variant="body-3" bold colorWeight={500}>
                  {userData.data.fullName}
                </Text>
              )}
              {userData.isPending && <Skeleton width="15rem" height={shimmerHeight} />}
            </div>
            <div className="item-title">
              <Text variant="body-3">CPF</Text>
            </div>
            <div className="item-body">
              {userData.isSuccess && (
                <Text variant="body-3" bold colorWeight={500}>
                  {applyMask(userData.data.cpf, 'cpf')}
                </Text>
              )}
              {userData.isPending && <Skeleton width="7rem" height={shimmerHeight} />}
            </div>
            <div className="item-title">
              <Text variant="body-3">Data de nascimento</Text>
            </div>
            <div className="item-body">
              {userData.isSuccess && (
                <Text variant="body-3" bold colorWeight={500}>
                  {userData.data.dateOfBirth}
                </Text>
              )}
              {userData.isPending && <Skeleton width="5rem" height={shimmerHeight} />}
            </div>
            <div className="item-title">
              <Text variant="body-3">Nome da mãe</Text>
            </div>
            <div className="item-body">
              {userData.isSuccess && (
                <Text variant="body-3" bold colorWeight={500}>
                  {userData.data.motherName}
                </Text>
              )}
              {userData.isPending && <Skeleton width="15rem" height={shimmerHeight} />}
            </div>
            <div className="item-title">
              <Text variant="body-3">Nome exibido no cartão</Text>
            </div>
            <div className="item-body">
              {userData.isSuccess && (
                <Text variant="body-3" bold colorWeight={500}>
                  {userData.data.cardName}
                </Text>
              )}
              {userData.isPending && <Skeleton width="10rem" height={shimmerHeight} />}
            </div>
            <Divider type="dashed" />
            <div className="header">
              <Text variant="headline-h3">Endereço de entrega</Text>
            </div>
            <div className="item-title">
              {userData.isSuccess && <Text variant="body-3">{userData.data.address}</Text>}
              {userData.isPending && <Skeleton width="20rem" height="2rem" />}
            </div>

            <div style={{ marginTop: 22, display: 'flex', alignItems: 'center' }}>
              <Text variant="caption-1">
                Li e concordo com o{' '}
                <button
                  type="button"
                  onClick={handleOpenPdf}
                  style={{
                    fontWeight: 700,
                    fontSize: '12px',
                    backgroundColor: 'transparent',
                    color: 'var(--primary500)',
                  }}
                >
                  Regulamento
                </button>
              </Text>
              <Checkbox
                checked={termsOfUse}
                onChange={handleOnChangeTermsOfUse}
                size={30}
                color={termsOfUse ? 'var(--primary500)' : 'var(--gray300)'}
              />
            </div>
          </DataReview>
          <ButtonContent>
            <div className="bt-spacer">
              <Button
                className="bt-style"
                disabled={!termsOfUse}
                isLoading={isLoadingBt}
                onClick={onClickHandler}
              >
                Solicitar cartão Black
              </Button>
            </div>
            <Button variant="secondary" className="bt-style" onClick={handleUpdateData}>
              Alterar dados cadastrais
            </Button>
          </ButtonContent>
        </AppContainer>
      </>
    )
  }

  if (cardRequested) {
    return <RequestStatus issue={2} />
  }

  if (requestFailed) {
    return <Error />
  }

  return null
}

export default StepTwo
