import React from 'react'

import AppBar from 'components/AppBar'
import Divider from 'components/Divider'

import { Skeleton } from '@interco/inter-ui/components/Skeleton'

import { Container } from './LandingPage.styles'

const LandingPageShimmer = () => (
  <>
    <AppBar name="Duo Gourmet" goBackNative showHomeAction />
    <Skeleton width="100%" height="280px" />
    <Container>
      <Skeleton width="100%" height="120px" />
      <Skeleton width="100%" height="80px" />
      <Skeleton width="100%" height="40px" style={{ marginTop: '10px' }} />
      <Skeleton width="100%" height="40px" style={{ marginTop: '10px ' }} />
    </Container>
    <Divider borderWidth={8} color="var(--gray100)" style={{ margin: '0' }} />
    <Container>
      <Skeleton width="80%" height="30px" style={{ marginBottom: '10px' }} />
      <div style={{ margin: '10px 0' }}>
        <Skeleton width="80%" height="20px" />
        <Skeleton width="100%" height="40px" />
      </div>
      <div style={{ margin: '10px 0' }}>
        <Skeleton width="80%" height="20px" />
        <Skeleton width="100%" height="40px" />
      </div>
      <div style={{ margin: '10px 0' }}>
        <Skeleton width="80%" height="20px" />
        <Skeleton width="100%" height="40px" />
      </div>
      <div style={{ margin: '10px 0' }}>
        <Skeleton width="80%" height="20px" />
        <Skeleton width="100%" height="40px" />
      </div>
      <div style={{ margin: '10px 0' }}>
        <Skeleton width="80%" height="20px" />
        <Skeleton width="100%" height="40px" />
      </div>
    </Container>
    <Divider borderWidth={8} color="var(--gray100)" style={{ margin: '0' }} />
    <Container>
      <Skeleton width="100%" height="40px" style={{ margin: '10px 0' }} />
      <Skeleton width="100%" height="80px" style={{ marginBottom: '10px ' }} />
    </Container>
  </>
)

export default LandingPageShimmer
