import React from 'react'

import ExperiencesRecords from 'pages/ExperiencesRecords'

import { RouteComponentProps, Router } from '@reach/router'

import MenuUsuario from '.'
import Subscription from '../Subscription'
import CancelSubscription from '../Subscription/CancelSubscription'
import RestaurantsRecords from '../RestaurantsRecords'
import TermsAndConditions from './TermsAndConditions'

const MenuUserRoutes = (_: RouteComponentProps) => (
  <Router className="container-routers">
    <MenuUsuario path="/" />
    <RestaurantsRecords path="/utilizations" />
    <Subscription path="/assinatura-pagamento" />
    <ExperiencesRecords path="/experiences" />
    <CancelSubscription path="/assinatura-pagamento/cancelamento/:subscriptionId" />
    <TermsAndConditions path="/terms" />
  </Router>
)

export default MenuUserRoutes
