import React, { useCallback, useEffect, useState } from 'react'

import IconPin from 'assets/icons/location.svg'
import IconUserLocation from 'assets/icons/markerLocation.svg'
import LatLng from 'types/LatLng'

import IconFocus from '@interco/icons/orangeds/LD/focus'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

import { CenterIconContainer, Container, MapStyles } from './RestaurantsMap.styles'

interface RestaurantsMapProps {
  restaurantCoordinates?: LatLng
  userCoordinates: LatLng
  children?: React.ReactNode
  boundsCoordinates: LatLng[]
}

const REACT_GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_API_KEY as string

const RestaurantsMap = ({
  restaurantCoordinates,
  userCoordinates,
  children,
  boundsCoordinates,
}: RestaurantsMapProps) => {
  const [map, setMap] = useState<google.maps.Map>()
  const [clicksCounter, setClicksCounter] = useState(0)
  const [positionCenter, setPositionCenter] = useState<LatLng>()
  const userLocationExist = !!userCoordinates.lat && !!userCoordinates.lng

  const IconRestaurantPin = { url: IconPin } as google.maps.Icon

  const style = [
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ]

  const mapOptions = {
    zoomControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    styles: style,
  }

  const fitBounds = (coordinates: LatLng[]) => {
    const bounds = new window.google.maps.LatLngBounds()
    coordinates?.map((item) => bounds.extend(new window.google.maps.LatLng(item)))
    map?.fitBounds(bounds)
  }

  const handleCenter = () => {
    if (userLocationExist) {
      switch (clicksCounter) {
        case 0:
          setPositionCenter(userCoordinates)
          setClicksCounter(clicksCounter + 1)
          break
        case 1:
          if (restaurantCoordinates) fitBounds([restaurantCoordinates, userCoordinates])
          if (boundsCoordinates) fitBounds(boundsCoordinates.concat(userCoordinates))
          setClicksCounter(clicksCounter + 1)
          break
        default:
          if (restaurantCoordinates) fitBounds([restaurantCoordinates])
          if (boundsCoordinates) fitBounds(boundsCoordinates)
          setClicksCounter(0)
          break
      }
    } else if (boundsCoordinates) {
      fitBounds(boundsCoordinates)
    } else if (restaurantCoordinates) {
      fitBounds([restaurantCoordinates])
    }
  }

  const handleLoad = useCallback(
    function callback(googleMap) {
      setMap(googleMap)
    },
    [map],
  )

  useEffect(() => {
    if (!map || !boundsCoordinates) return
    fitBounds(boundsCoordinates)
  }, [map])

  return (
    <LoadScript googleMapsApiKey={REACT_GOOGLE_MAPS_KEY}>
      <Container>
        <GoogleMap
          center={positionCenter}
          mapContainerStyle={MapStyles}
          zoom={15}
          data-testid="map"
          options={mapOptions}
          onLoad={handleLoad}
        >
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { map })
            }
            return null
          })}
          {userLocationExist && <Marker position={userCoordinates} icon={IconUserLocation} />}
          {restaurantCoordinates && (
            <Marker position={restaurantCoordinates} icon={IconRestaurantPin} />
          )}
          <CenterIconContainer>
            <IconFocus width={22} height={22} color="var(--primary500)" onClick={handleCenter} />
          </CenterIconContainer>
        </GoogleMap>
      </Container>
    </LoadScript>
  )
}

export default RestaurantsMap
