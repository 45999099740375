import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  padding: 0 1.3rem 0.5rem;
`

export const Card = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  .imageStyle {
    height: 22vh;
    width: 90vw;
    border-radius: 0.5rem;
    object-fit: cover;
    align-self: center;
  }
`
