import React from 'react'

import { AppContainer } from 'App.styles'
import AppBar from 'components/AppBar'
import ImgSuccess from 'assets/imgs/blackcard/success.svg'

import { interWbNavigate } from '@interco/inter-webview-bridge'
import { Alert } from '@interco/inter-ui/components/Alert'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { useNavigate } from '@reach/router'

import { ImgContent, TextContent, ButtonContent, AlertStyle } from './BlackCard.styles'
import * as T from './BlackCard.tags'

const RequestSuccess = () => {
  const navigate = useNavigate()

  const handleGoBack = async () => {
    T.handleTagHome()
    navigate('/')
  }

  const handleGoCards = async () => {
    try {
      T.handleTagCards()
      await interWbNavigate.openDeepLink('bancointer://cartoes')
    } catch (error) {
      window.console.error(error)
    }
  }

  return (
    <>
      <AppBar
        name="Cartão Inter Black"
        backTag={() => T.handleTagHeaderStep({ ref_figma: '11', step: '3' })}
      />
      <AppContainer
        fullHeight
        style={{
          paddingTop: '5rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <ImgContent>
            <img src={ImgSuccess} alt="" />
          </ImgContent>
          <TextContent>
            <Text
              variant="headline-h1"
              semiBold
              style={{ marginBottom: 10 }}
              className="center-text"
            >
              Seu Cartão Inter Black foi solicitado!
            </Text>
            <Text variant="body-3" className="center-text">
              Seu Cartão Black foi solicitado e em breve chegará pra você em sua residência!
            </Text>
            <AlertStyle>
              <Alert type="grayscale">
                <Text variant="caption-1">
                  Acompanhe os detalhes da sua solicitação na área de Cartões do app.
                </Text>
              </Alert>
            </AlertStyle>
          </TextContent>
        </div>
        <ButtonContent>
          <div className="bt-spacer">
            <Button data-testid="back-button" className="bt-style" onClick={handleGoBack}>
              Voltar para o Duo Gourmet
            </Button>
          </div>
          <Button
            data-testid="card-button"
            variant="secondary"
            className="bt-style"
            onClick={handleGoCards}
          >
            Ir para cartões
          </Button>
        </ButtonContent>
      </AppContainer>
    </>
  )
}

export default RequestSuccess
