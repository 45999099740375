import React from 'react'

import { Text } from '@interco/inter-ui/components/Text'
import ChrevronRightIcon from '@interco/icons/orangeds/SM/chevron-right'

import * as S from './ListItemWithSelection.styles'
import { ListItemWithSelectionProps } from '.'

const ListItemWithSelection: React.FC<ListItemWithSelectionProps> = ({
  label,
  icon,
  arrow,
  disabled,
  selection,
  ...props
}: ListItemWithSelectionProps) => (
  <S.ListItemStyles
    {...props}
    data-testid="list-item"
    onClick={(e) => {
      if (!disabled) props.onClick && props.onClick(e)
    }}
  >
    <S.ListItemContainer>
      {icon && (
        <S.ListItemIconStyles>
          {React.cloneElement(icon as React.ReactElement, {
            stroke: disabled ? 'var(--gray300)' : undefined,
            color: disabled ? 'var(--gray300)' : 'var(--gray500)',
          })}
        </S.ListItemIconStyles>
      )}
      <S.Content>
        <Text variant="body-3" colorWeight={500} bold>
          {label}
        </Text>
        <Text variant="caption-1" color="primary">
          {selection}
        </Text>
      </S.Content>
    </S.ListItemContainer>
    {arrow && (
      <ChrevronRightIcon
        style={{ float: 'right' }}
        color={disabled ? 'var(--gray300)' : 'var(--primary500)'}
      />
    )}
  </S.ListItemStyles>
)

export default ListItemWithSelection
