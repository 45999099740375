import React from 'react'

import { ExperienceUseEvents } from 'types/Experiences'
import { handleGoToBenefit } from 'pages/ExperiencesRecords/ExperiencesRecords.tags'
import { useConfigs } from 'contexts/ConfigsContext'

import { Text } from '@interco/inter-ui/components/Text'
import ChrevronRightIcon from '@interco/icons/orangeds/SM/chevron-right'
import { interWbNavigate } from '@interco/inter-webview-bridge'
import { useNavigate } from '@reach/router'

import * as S from './RecordCard.styles'

interface RecordCardProps {
  experienceEvent: ExperienceUseEvents
  active?: boolean
  externalEvent: boolean
}

const RecordCard = ({
  experienceEvent,
  active = false,
  externalEvent = false,
}: RecordCardProps) => {
  const navigate = useNavigate()
  const { configs } = useConfigs()
  const hasDiscountCode = !!experienceEvent.coupon

  const handleOnClick = async () => {
    if (externalEvent) {
      interWbNavigate.openDeepLink(experienceEvent.externalLink)
    } else {
      navigate('/experience-benefit', {
        state: { id: experienceEvent.experienceId },
      })
    }

    handleGoToBenefit({
      ticket: experienceEvent.experienceTitle,
      date: experienceEvent.createdAt,
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
    })
  }

  return (
    <S.Wrapper onClick={handleOnClick}>
      <S.TextContainer>
        <Text variant="body-3" bold colorWeight={active ? 500 : 400} className="title">
          {experienceEvent.experienceTitle}
        </Text>
        {hasDiscountCode ? (
          <Text variant="caption-1">Código de desconto: {experienceEvent.coupon}</Text>
        ) : (
          <Text variant="caption-1">Código: {experienceEvent.code}</Text>
        )}
        <Text variant="caption-1">{experienceEvent.useDate}</Text>
      </S.TextContainer>
      <S.BtContainer>
        <ChrevronRightIcon width={25} height={25} color="var(--primary500)" />
      </S.BtContainer>
    </S.Wrapper>
  )
}

export default RecordCard
