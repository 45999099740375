import { ETrackEvent } from 'enums/ETrackEvent'
import { SubscriberTag } from 'types/AnalyticsTag'

import { interWbSession } from '@interco/inter-webview-bridge'

interface GoToBenefit extends SubscriberTag {
  ticket: string
  date: string
}

export const handleHeaderGoBack = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_HEADER, {
      ref_figma: '1',
      flow: ETrackEvent.F_EXPERIENCES,
      screen: ETrackEvent.S_EXPERIENCES_RECORDS,
      action_id: 'back',
      product: ETrackEvent.T_PRODUCT,
    })
    .catch(window.console.log)
}

export const handleGoToBenefit = async ({
  ticket,
  date,
  plan_name = '',
  plan_value = '',
}: GoToBenefit) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '8',
      flow: ETrackEvent.F_EXPERIENCES,
      screen: ETrackEvent.S_EXPERIENCES_RECORDS,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'ver experiencia',

      ticket,
      date,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}
