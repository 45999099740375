import { ETrackEvent } from 'enums/ETrackEvent'
import { SubscriberTag } from 'types/AnalyticsTag'

import { interWbSession } from '@interco/inter-webview-bridge'

export const handleTagCancelation = async ({ plan_name = '', plan_value = '' }: SubscriberTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '1',
      flow: ETrackEvent.F_SIG_CANCELATION,
      screen: ETrackEvent.S_SIGNATURE_PAYMENT,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: ETrackEvent.F_SIG_CANCELATION,

      plan_name,
      plan_value,

      product: ETrackEvent.T_PRODUCT,
    })
    .catch(window.console.log)
}

export const handleTagTwo = async ({ plan_name = '', plan_value = '' }: SubscriberTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '2',
      flow: ETrackEvent.F_SIG_CANCELATION,
      screen: ETrackEvent.S_SIG_CANCELATION,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'continuar economizando',

      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagThree = async ({ plan_name = '', plan_value = '' }: SubscriberTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '3',
      flow: ETrackEvent.F_SIG_CANCELATION,
      screen: ETrackEvent.S_SIG_CANCELATION,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'cancelar',

      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagHeader = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_HEADER, {
      ref_figma: '12',
      flow: ETrackEvent.F_SIG_CANCELATION,
      screen: ETrackEvent.S_SIG_CANCELATION,
      action_id: 'back',
      product: ETrackEvent.T_PRODUCT,
    })
    .catch(window.console.log)
}
