import React from 'react'

import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Text } from '@interco/inter-ui/components/Text'
import { useNavigate } from '@reach/router'

import * as S from './SpecialSelections.styles'
import { handleTagItem } from './SpecialSelections.tags'
import { SpecialSelection, SpecialSelectionsProps } from './SpecialSelections.types'

const SpecialSelections = ({ selections, city }: SpecialSelectionsProps) => {
  const navigate = useNavigate()

  const onClickHandler = (item: SpecialSelection, position: number) => {
    handleTagItem({ item_name: item.label, position: (position + 1).toString() })

    navigate(`/selections/${item.id}`, {
      state: { selectionsTitle: item.label, city },
    }).catch(window.console.log)
  }

  return (
    <S.Wrapper>
      {selections.map((item, idx) => (
        <S.SelectionItem key={item.id} onClick={() => onClickHandler(item, idx)}>
          <S.Card backgroundColor={item.backgroundColor}>
            <LazyLoadImage
              src={item.iconUrl}
              style={{
                height: '24px',
                width: '24px',
                margin: '12px',
              }}
            />
          </S.Card>
          <Text variant="caption-2" colorWeight={500}>
            {item.label}
          </Text>
        </S.SelectionItem>
      ))}
    </S.Wrapper>
  )
}

export default SpecialSelections
