import styled from 'styled-components'

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  span {
    display: flex;
    margin: 0;
  }
`

export const TagSpace = styled.div`
  margin: 0.1rem 0.3rem 0 0;
`
