import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  padding: 1rem;

  overflow-x: auto;
  clip-path: inset(0 0 1rem 0);
`

export const SelectionItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  width: min-content;
  max-width: 60px;

  span {
    text-align: center;
  }
`

export const Card = styled.div<{ backgroundColor: string }>`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  border: 1px solid ${({ backgroundColor = 'var(--neutral-theme)' }) => backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ backgroundColor = 'var(--neutral-theme)' }) => backgroundColor};
`
