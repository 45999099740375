import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1.3rem 0 1rem;
  margin-top: 3rem;
  overflow: hidden;

  .dividerStyle {
    margin: 0 -2rem;
  }

  .locationStyle {
    margin: 0.8rem 0;
    padding: 0.5rem 0;
    width: 100%;
  }

  .topAnnouncements {
    margin: 1rem 0;
  }
`

export const Carousel = styled.div`
  margin: 1rem 0;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`

export const Content = styled.div`
  display: flex;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
`
