import { ETrackEvent } from 'enums/ETrackEvent'

import { interWbSession } from '@interco/inter-webview-bridge'

interface RestaurantSubTag {
  restaurant: string
  plan_name: string
  plan_value: string
}

interface RestaurantTag {
  restaurant: string
}

interface FavoriteTag {
  favorite: string
  restaurant: string
  neighborhood: string
  cooking: string
  modality: string
  plan_name: string
  plan_value: string
}

interface UseBenefitTag {
  restaurant: string
  favorite: string
  neighborhood: string
  cooking: string
  modality: string
  plan_name: string
  plan_value: string
}

export const handleTagHeader = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_HEADER, {
      ref_figma: '1',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_RESTAURANT_DETAILS,
      action_id: 'back',
      product: ETrackEvent.T_PRODUCT,
    })
    .catch(window.console.log)
}

export const handleTagFavorite = async ({
  favorite,
  restaurant,
  neighborhood,
  cooking,
  modality,
  plan_name,
  plan_value,
}: FavoriteTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '8',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_RESTAURANT_DETAILS,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'favoritar',

      favorite,
      restaurant,
      neighborhood,
      cooking,
      modality,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagOpenMenu = async ({
  restaurant,
  plan_name,
  plan_value,
}: RestaurantSubTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '9',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_RESTAURANT_DETAILS,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'abrir o cardápio',

      restaurant,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagShare = async ({ restaurant, plan_name, plan_value }: RestaurantSubTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '10',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_RESTAURANT_DETAILS,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'compartilhar',

      restaurant,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagSocials = async ({ restaurant, plan_name, plan_value }: RestaurantSubTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '11',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_RESTAURANT_DETAILS,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'rede social',

      restaurant,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagMap = async ({ restaurant, plan_name, plan_value }: RestaurantSubTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '13',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_RESTAURANT_DETAILS,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'abrir mapa',

      restaurant,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

interface ScheduleTag {
  restaurant: string
  action_id: string
  plan_name: string
  plan_value: string
}

export const handleTagSchedule = async ({
  restaurant,
  action_id,
  plan_name,
  plan_value,
}: ScheduleTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '14',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_RESTAURANT_DETAILS,
      ref_type: 'botão',
      content_action: 'toque',
      action_id,

      restaurant,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagRules = async ({ restaurant, plan_name, plan_value }: RestaurantSubTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '15',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_RESTAURANT_DETAILS,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'regras de uso',

      restaurant,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagContact = async ({ restaurant, plan_name, plan_value }: RestaurantSubTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '16',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_RESTAURANT_DETAILS,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'entre em contato com o restaurante',

      restaurant,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagUseBenefit = async ({
  restaurant,
  favorite,
  neighborhood,
  cooking,
  modality,
  plan_name,
  plan_value,
}: UseBenefitTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '17',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_RESTAURANT_DETAILS,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'usar meu benefício',

      restaurant,
      favorite,
      neighborhood,
      cooking,
      modality,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagAccess = async ({ restaurant }: RestaurantTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_ACCESS, {
      ref_figma: '21',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_RESTAURANT_DETAILS,
      content_action: 'dado carregado',
      action_id: 'acesso restaurante',

      restaurant,
    })
    .catch(window.console.log)
}

export const handleTagCheckSubscription = async ({ ...props }) => {
  const { plan_name, plan_value } = props

  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '22',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_BS_USE_CONFIRM,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'verificar minha assinatura',
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagSubscribe = async ({ ...props }) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '23',
      flow: ETrackEvent.F_SUBSCRIPTION,
      screen: ETrackEvent.S_LANDING_PAGE,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'assinar o inter duo gourmet',

      ...props,
    })
    .catch(window.console.log)
}
