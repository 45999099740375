import { AppContainer, SpaceBetween } from 'App.styles'
import styled from 'styled-components'

export const Wrapper = styled(AppContainer)``

export const SubscriptionInfo = styled(SpaceBetween)`
  height: auto;
  direction: column;
  margin-top: 1rem;
  gap: 1.5rem;

  .discount {
    color: var(--success500);
  }
`

export const AlertsContainer = styled(SpaceBetween)`
  height: auto;
  direction: column;
  margin-top: 1.5rem;
  gap: 1rem;
`

export const LinkMenu = styled(SpaceBetween)`
  height: auto;
  direction: column;
  margin-top: 3rem;
  gap: 0.4rem;
`
