import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: -webkit-fill-available;
`

export const ContainerLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ButtonContent = styled.div`
  --button-background-color: var(--primary500);
  --button-border-radius: 8px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
`

export const RestaurantAddress = styled.div`
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    background-color: transparent;
    border: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
`

export const ContainerCookingContent = styled.div`
  margin: 1rem 0 0;
`
export const CookingContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
  font-weight: normal;
`

export const TagsContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: wrap;

  span {
    display: flex;
    margin: 0;
  }
`

export const TagSpace = styled.div`
  margin: 0.1rem 0.3rem 0 0;
`

export const ScheduleContainer = styled.div`
  border: 1px solid var(--gray200);
  border-radius: 0.5rem;
  --accordion-container-background-color: none;

  padding: 0.5rem;
  margin-top: 0.7rem;

  .schedule-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-right: 0.5rem;
  }

  .schedule-line {
    display: flex;
    width: 100%;
    padding: 0.3rem 0.5rem 0.3rem 0;
    justify-content: space-between;
  }
`

export const ScheduleHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DeliveryAlert = styled.div`
  margin: 1rem 0;
`

export const SeeAllRestBt = styled.div`
  display: flex;
  justify-content: center;
`
