import styled from 'styled-components'

export const MapWrapper = styled.div`
  background: transparent;
  bottom: 0;
  right: 0;
  position: fixed;
  outline: none;

  .notSubscriber {
    margin: 6rem 1rem;
  }

  .isSubscriber {
    margin: 1.5rem 1rem;
  }

  button,
  button:hover:enabled {
    background: var(--gray500);
    border-radius: 28px;
    display: inline-block;
    color: var(--neutral-theme);
    padding: 0.8rem 1rem 0.8rem 0.8rem;

    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`

export const BtContent = styled.div`
  display: flex;
  gap: 0.3rem;
  align-items: center;
`
