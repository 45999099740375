import React from 'react'

import DynamicIcon from 'components/DynamicIcon'

import { Text } from '@interco/inter-ui/components/Text'

interface IListIconText {
  list: {
    text: string
    icon: string
  }[]
  width?: number
  height?: number
}

const ListIconText = ({ list, width, height }: IListIconText) => (
  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
    {list.map((item) => (
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '8px',
        }}
        key={item.text}
      >
        <div style={{ width: `${width}`, height: `${height}` }}>
          <DynamicIcon
            name={item.icon}
            height={width ?? 24}
            width={height ?? 24}
            color="var(--gray500)"
          />
        </div>
        <Text variant="body-3" style={{ marginLeft: '8px' }}>
          {item.text}
        </Text>
      </div>
    ))}
  </div>
)

export default ListIconText
