import React from 'react'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import Divider from 'components/Divider'
import { useConfigs } from 'contexts/ConfigsContext'
import { ExperienceResume } from 'types/Experiences'

import { interWbNavigate } from '@interco/inter-webview-bridge'
import { Text } from '@interco/inter-ui/components/Text'
import { navigate } from '@reach/router'
import { Tag } from '@interco/inter-ui/components/Tag'

import * as S from './ExperienceCard.styles'

interface ExperienceCardProps {
  experience: ExperienceResume
}

const ExperienceCard = ({ experience }: ExperienceCardProps) => {
  const { configs } = useConfigs()

  const handleClick = async () => {
    if (experience.deepLink) {
      await interWbNavigate.openDeepLink(experience.deepLink)
    } else {
      navigate(`/experiences/${experience.id}`, { state: { name: experience.title } }).catch(
        window.console.log,
      )
    }
  }

  return (
    <S.ExperienceContainer>
      <S.Item onClick={handleClick}>
        <S.Content>
          <LazyLoadImage
            effect="blur"
            src={experience.imgSmallCoverUrl}
            style={{
              height: '83px',
              width: '83px',
              borderRadius: '0.5rem',
              objectFit: 'cover',
            }}
          />
          <S.ExperienceDetails>
            <Text variant="caption-1" colorWeight={500} bold className="wrap-text">
              {experience.title}
            </Text>

            {configs.subscriber.duoGourmetInterCustomer &&
              experience.type === 'fixed' &&
              experience.useNow && (
                <S.TagsContainer>
                  <S.TagSpace>
                    <Tag color="var(--success100)" colorText="var(--success500)">
                      Use agora
                    </Tag>
                  </S.TagSpace>
                </S.TagsContainer>
              )}

            {experience.type === 'temporary' && experience.eventDate && (
              <Text variant="caption-2">{experience.eventDate}</Text>
            )}

            <Text variant="caption-2">{experience.category}</Text>
            <Text variant="caption-2">{experience.city}</Text>

            {configs.subscriber.duoGourmetInterCustomer && (
              <S.TagsContainer>
                <S.TagSpace>
                  <Tag color="var(--success500)" colorText="var(--success100)">
                    {experience.shortBenefit}
                  </Tag>
                </S.TagSpace>
              </S.TagsContainer>
            )}
          </S.ExperienceDetails>
        </S.Content>
        <Divider color="var(--gray100)" style={{ margin: '0' }} />
      </S.Item>
    </S.ExperienceContainer>
  )
}

export default ExperienceCard
