import styled from 'styled-components'

export const Wrapper = styled.div`
  overflow: auto;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  margin: 4rem 0 0 0;

  .dividerStyle {
    margin: 1rem -2rem;
  }

  .lineStyle {
    margin: 0;
  }
`

export const ShimmerTotalSaved = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  margin: 1rem 0;
`

export const ShimmerLink = styled.div`
  margin: 0.5rem 1.5rem;
`
