import React from 'react'

import { QueryParamRestaurant, useFilters } from 'contexts/FiltersContext'
import { HomeResponse } from 'types/Restaurant'
import { UseAsyncResponse } from 'hooks/UseAsync'
import { ApiResponsePagination } from 'types/http/ApiResponse'
import { useConfigs } from 'contexts/ConfigsContext'
import { handleTagFilter } from 'pages/Home/RestaurantsTab/RestaurantsTab.tags'
import FilterButton from 'components/FilterButton'

import { useNavigate } from '@reach/router'
import HeartIcon from '@interco/icons/orangeds/XL/heart'
import StarIcon from '@interco/icons/orangeds/XL/star'
import FilterIcon from '@interco/icons/bidis/v2/action/filter'

import { colorBlack, colorWhite, FilterContent } from './RestaurantsFilters.styles'

interface RestaurantFilterProps {
  restaurants: UseAsyncResponse<ApiResponsePagination<HomeResponse>>
  SubscriberInter: boolean
  isFiltersSelected: boolean
  fetchRestaurants: (newQueryParam: QueryParamRestaurant) => void
  city: string
  className?: string
}

const RestaurantsFilters = React.forwardRef(
  (
    {
      restaurants,
      SubscriberInter,
      isFiltersSelected,
      fetchRestaurants,
      city,
      className,
    }: RestaurantFilterProps,
    ref,
  ) => {
    const { queryParam } = useFilters()
    const { configs } = useConfigs()
    const { data } = restaurants
    const navigate = useNavigate()

    const onFilterFavorites = async () => {
      const newQueryParam = {
        ...queryParam,
        favorites: !queryParam.favorites,
      }
      if (ref) {
        ;(ref as unknown as React.MutableRefObject<number>).current = 0
      }
      handleTagFilter({
        filter: 'favoritos',
        plan_name: configs.subscriber.planName,
        plan_value: configs.subscriber.planValue,
        subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
      })
      fetchRestaurants(newQueryParam)
    }

    const onFilterNewRestaurants = async () => {
      const newQueryParam = {
        ...queryParam,
        newRestaurant: !queryParam.newRestaurant,
      }
      if (ref) {
        ;(ref as unknown as React.MutableRefObject<number>).current = 0
      }
      handleTagFilter({
        filter: 'novos',
        plan_name: configs.subscriber.planName,
        plan_value: configs.subscriber.planValue,
        subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
      })
      fetchRestaurants(newQueryParam)
    }

    const handleFilters = () => {
      handleTagFilter({
        filter: 'filtros',
        plan_name: configs.subscriber.planName,
        plan_value: configs.subscriber.planValue,
        subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
      })
      navigate(`/restaurants/filters`, {
        state: {
          city,
          days: data?.filters?.days,
          price: data?.filters?.price,
          cookings: data?.filters?.cookings,
          districts: data?.filters?.districts,
          mealTimes: data?.filters?.mealTimes,
          orderMethod: data?.filters?.orderMethod,
        },
      }).catch(window.console.log)
    }

    return (
      <FilterContent className={className}>
        <FilterButton
          label="Filtros"
          icon={
            <FilterIcon
              height={16}
              width={16}
              color={isFiltersSelected ? colorWhite : colorBlack}
            />
          }
          handleClick={handleFilters}
          isSelected={isFiltersSelected}
        />
        {SubscriberInter && (
          <FilterButton
            label="Favoritos"
            icon={
              <HeartIcon
                height={16}
                width={16}
                color={queryParam.favorites ? colorWhite : colorBlack}
              />
            }
            handleClick={onFilterFavorites}
            isSelected={queryParam.favorites}
          />
        )}
        {SubscriberInter && (
          <FilterButton
            label="Novos"
            icon={
              <StarIcon
                height={16}
                width={16}
                color={queryParam.newRestaurant ? colorWhite : colorBlack}
              />
            }
            handleClick={onFilterNewRestaurants}
            isSelected={queryParam.newRestaurant}
          />
        )}
      </FilterContent>
    )
  },
)

export default RestaurantsFilters
