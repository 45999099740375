import React from 'react'

import { TabPager, TabDefinition } from 'components/TabPager'
import { ModalityInfo } from 'types/Restaurant'
import { handleTagSchedule } from 'pages/RestaurantsDetails/RestaurantsDetails.tags'
import { useConfigs } from 'contexts/ConfigsContext'

import ScheduleChart from './ScheduleChart'
import { ScheduleLineProps } from './OperationCalendar'
import { MainContainer } from './OperationCalendar.styles'

interface OperationCalendarProps {
  choices: ModalityInfo[]
  presentialSchedule: ScheduleLineProps[]
  takeOutSchedule: ScheduleLineProps[]
  deliverySchedule: ScheduleLineProps[]
  restaurantName: string
}

export const OperationCalendar = ({
  choices,
  presentialSchedule,
  takeOutSchedule,
  deliverySchedule,
  restaurantName,
}: OperationCalendarProps) => {
  const [activeTab, setActiveTab] = React.useState<string | number>(choices[0].title)
  const { configs } = useConfigs()

  const getContent = (item: ModalityInfo) => {
    switch (item.title) {
      case 'Delivery':
        return <ScheduleChart modality={item.title} schedule={deliverySchedule} />
      case 'Retirada':
        return <ScheduleChart modality={item.title} schedule={takeOutSchedule} />
      default:
        return <ScheduleChart modality={item.title} schedule={presentialSchedule} />
    }
  }

  const tabDefinitions: TabDefinition[] = choices.map((item) => ({
    key: item.title,
    tabId: item.title,
    label: item.title,
    content: getContent(item),
  }))

  const handleTabChange = (tabId: string | number) => {
    handleTagSchedule({
      restaurant: restaurantName,
      action_id: tabId.toString(),
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
    })
    setActiveTab(tabId)
  }

  return (
    <MainContainer>
      {choices.length > 1 ? (
        <TabPager
          currentTabId={activeTab}
          onTabSelect={handleTabChange}
          tabDefinitions={tabDefinitions}
        />
      ) : (
        getContent(choices[0])
      )}
    </MainContainer>
  )
}
