import { ETrackEvent } from 'enums/ETrackEvent'
import { SearchTag } from 'types/AnalyticsTag'

import { interWbSession } from '@interco/inter-webview-bridge'

export const handleTagClearFilter = async (screen: string) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '1',
      flow: ETrackEvent.F_SEARCH_FILTER,
      screen,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'limpar filtros',
    })
    .catch(window.console.log)
}

interface SelectedFilters {
  selected_filter: string
}

export const handleTagApplyFilter = async ({ selected_filter }: SelectedFilters) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '2',
      flow: ETrackEvent.F_SEARCH_FILTER,
      screen: ETrackEvent.S_FILTERS,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'aplicar filtros',

      selected_filter,
    })
    .catch(window.console.log)
}

interface SelectedBSFilters extends SelectedFilters {
  screen: string
}

export const handleTagSelectFilter = async ({ selected_filter, screen }: SelectedBSFilters) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '3',
      flow: ETrackEvent.F_SEARCH_FILTER,
      screen,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'aplicar filtros',

      selected_filter,
    })
    .catch(window.console.log)
}

interface FilterSearch extends SearchTag {
  screen: string
}

export const handleTagSearch = async ({ search_term, screen }: FilterSearch) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_SEARCH_TERM, {
      ref_figma: '4',
      flow: ETrackEvent.F_SEARCH_FILTER,
      screen,
      search_term,
      product: ETrackEvent.T_PRODUCT,
    })
    .catch(window.console.log)
}
