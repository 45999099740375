import { ETrackEvent } from 'enums/ETrackEvent'
import { SearchTag, SubscriberTag } from 'types/AnalyticsTag'

import { interWbSession } from '@interco/inter-webview-bridge'

interface FilterTag extends SubscriberTag {
  filter: string
}

export const handleTagFilter = async ({
  filter,
  plan_name = '',
  plan_value = '',
  subscriber = '',
}: FilterTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '3',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_RESTAURANTS_LIST,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'filtros',

      filter,
      plan_name,
      plan_value,
      subscriber,
    })
    .catch(window.console.log)
}

export const handleTagBackToTop = async ({
  plan_name = '',
  plan_value = '',
  subscriber = '',
}: SubscriberTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '5',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_RESTAURANTS_LIST,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'voltar ao topo',

      plan_name,
      plan_value,
      subscriber,
    })
    .catch(window.console.log)
}

export const handleTagSearch = async ({ search_term, subscriber = '' }: SearchTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_SEARCH_TERM, {
      ref_figma: '6',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_RESTAURANTS_LIST,
      search_term,
      product: ETrackEvent.T_PRODUCT,
      subscriber,
    })
    .catch(window.console.log)
}

export const handleTagLocation = async ({ plan_name = '', plan_value = '' }: SubscriberTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '7',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_RESTAURANTS_LIST,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'estado e cidade',

      plan_name,
      plan_value,
      subscriber: 'true',
    })
    .catch(window.console.log)
}

export const handleTagSubscribe = async ({ ...props }) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '9',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_RESTAURANTS_LIST,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'assinar o inter duo gourmet',

      ...props,
    })
    .catch(window.console.log)
}

export const handleTagLoad = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_ACCESS, {
      ref_figma: '24',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_RESTAURANTS_LIST,
      content_action: 'dado carregado',
    })
    .catch(window.console.log)
}
