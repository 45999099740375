import React, { useContext } from 'react'

import { AppContainer } from 'App.styles'
import AppBar from 'components/AppBar'
import BlackcardContext from 'contexts/BlackcardContext'
import ImgBlack from 'assets/imgs/blackcard/onboarding.png'

import AirportIcon from '@interco/icons/orangeds/XL/airport'
import SegurosIcon from '@interco/icons/orangeds/XL/seguros'
import ConciergeIcon from '@interco/icons/orangeds/XL/concierge'
import OutboundIcon from '@interco/icons/orangeds/XL/outbound'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import ChevronRightIcon from '@interco/icons/orangeds/XL/chevron-right'

import { AdvantagesContent, ImgContent, TextContent, ButtonContent } from './BlackCard.styles'
import * as T from './BlackCard.tags'

const Onboarding = () => {
  const { goToNext } = useContext(BlackcardContext)

  const handleNext = async () => {
    T.handleTagNext()
    goToNext()
  }

  const iconProps = { color: 'var(--gray500)', width: 20, height: 20 }

  return (
    <>
      <AppBar name="Cartão Inter Black" backTag={T.handleTagHeaderOnboarding} />
      <AppContainer
        fullHeight
        style={{
          paddingTop: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <ImgContent>
            <img src={ImgBlack} alt="" style={{ width: '100%' }} />
          </ImgContent>
          <TextContent>
            <Text variant="headline-h1" semiBold style={{ marginBottom: 10 }}>
              Você já pode solicitar seu cartão Inter Black
            </Text>
            <Text variant="body-3">
              Com uma assinatura anual do Duo Gourmet você pode solicitar um upgrade para o cartão
              Inter Black que oferece um mundo de benefícios, com a facilidade e exclusividade que
              você procura (para acesso aos benefícios Mastercard é necessária função de crédito
              ativa, sujeita à análise de crédito).
            </Text>
            <AdvantagesContent>
              <div className="adv-item">
                <AirportIcon {...iconProps} />
                <Text variant="body-3" bold colorWeight={500} className="adv-text">
                  Sala Vip em aeroportos
                </Text>
              </div>
              <div className="adv-item">
                <SegurosIcon {...iconProps} />
                <Text variant="body-3" bold colorWeight={500} className="adv-text">
                  Proteção contra roubos
                </Text>
              </div>
              <div className="adv-item">
                <ConciergeIcon {...iconProps} />
                <Text variant="body-3" bold colorWeight={500} className="adv-text">
                  Mastercard AirportConcierge
                </Text>
              </div>
              <div className="adv-item">
                <OutboundIcon {...iconProps} />
                <Text variant="body-3" bold colorWeight={500} className="adv-text">
                  Travel Rewards e muito mais!
                </Text>
              </div>
            </AdvantagesContent>
          </TextContent>
        </div>
        <ButtonContent>
          <Button
            className="bt-style"
            data-testid="continue-button"
            onClick={handleNext}
            icon={<ChevronRightIcon height={24} width={24} color="var(--neutral-theme)" />}
          >
            Continuar
          </Button>
        </ButtonContent>
      </AppContainer>
    </>
  )
}

export default Onboarding
