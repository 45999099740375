import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`

export const Content = styled.div`
  text-align: center;
  margin-top: 9rem;
`
