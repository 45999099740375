import styled from 'styled-components'

export const CustomLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const ToggleIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0.6rem;
  border-radius: 1rem;
`
