import React from 'react'

import AppBar from 'components/AppBar'
import RestaurantsMap from 'components/RestaurantsMap/RestaurantsMap'
import useUserLocation from 'hooks/useUserLocation'

import { RouteComponentProps } from '@reach/router'
import { Button } from '@interco/inter-ui/components/Button'
import { interWbNavigate } from '@interco/inter-webview-bridge'

import { BottomButton, Container } from './ExperienceLocation.styles'

interface ExperienceInfoProps {
  experienceName: string
  latitude: number
  longitude: number
  duoGourmetSubscriber: boolean
}

export const ExperienceLocation = ({ location }: RouteComponentProps) => {
  const { experienceName, latitude, longitude, duoGourmetSubscriber } =
    location?.state as ExperienceInfoProps
  const userLocation = useUserLocation()

  const experienceCoordinates = {
    lat: latitude,
    lng: longitude,
  }

  const userCoordinates = {
    lat: parseFloat(userLocation?.latitude),
    lng: parseFloat(userLocation?.longitude),
  }

  return (
    <>
      <AppBar
        name={experienceName}
        subscriber={duoGourmetSubscriber}
        showHomeAction={!duoGourmetSubscriber}
      />
      <Container>
        <RestaurantsMap
          restaurantCoordinates={experienceCoordinates}
          userCoordinates={userCoordinates}
          boundsCoordinates={[{ lat: experienceCoordinates.lat, lng: experienceCoordinates.lng }]}
        />
      </Container>
      <BottomButton>
        <Button
          onClick={async () => {
            try {
              await interWbNavigate.openLocationMap(latitude, longitude)
            } catch (error) {
              window.console.log(error)
            }
          }}
        >
          Definir rota
        </Button>
      </BottomButton>
    </>
  )
}
