import styled from 'styled-components'

export const RestaurantContainer = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    align-self: start;
    margin: 1rem 0;
    padding-top: 0.2rem;
    padding-right: 0.3rem;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }
`

export const Item = styled.div`
  display: flex;
  overflow: hidden;

  .text-style {
    margin-bottom: 4px;
  }

  .wrap-text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 15rem;
  }
`

export const Content = styled.div`
  margin: 1rem 0;
  display: flex;
`

export const RestaurantDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 1rem;
`
