import styled, { css } from 'styled-components'

interface StepProps {
  isActive: boolean
}

interface LeftButtonProps {
  isShowing: boolean
}

export const Container = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: calc(100% - 64px);

  > div {
    flex: 1 1 auto;
    padding: 0 24px;
  }

  img {
    width: 100%;
  }

  .bottom-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 20px;
  }
`

export const LeftButton = styled.button`
  padding: 16px 0px 16px 24px;

  ${(props: LeftButtonProps) => {
    if (!props.isShowing) {
      return css`
        pointer-events: none;
        opacity: 0;
      `
    }

    return ''
  }}
`

export const StepsContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  flex: 0;
`

export const Step = styled.div`
  width: 24px;
  height: 2px;
  background-color: ${(props: StepProps) =>
    props.isActive ? 'var(--primary500)' : 'var(--gray200)'};
  margin: 0 8px;
  border-radius: 1px;

  &:first-child {
    margin-left: 0px;
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;

  > button {
    width: 100%;
  }
`

export const AppBarContainer = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
