import styled from 'styled-components'

export const ButtonStyle = styled.div`
  margin-right: 0.3rem;

  &:hover:enabled {
    --button-hover-background-color: transparent;
  }

  .customButton {
    font-size: 12px;
    font-weight: 400;
    padding: 8px 12px;
    --button-border-radius: 20px;
    --button-background-color: var(--gray500);
    --button-secondary-border-color: var(--gray200);
    --button-secondary-text-color: var(--gray500);
    -webkit-tap-highlight-color: transparent;

    &:hover:enabled {
      --button-background-color: var(--neutral-theme);
      --button-secondary-text-color: var(--gray500);
      --button-secondary-hover-background-color: var(--neutral-theme);
      border-color: var(--gray200);
    }
  }

  .activatedButton {
    background: var(--gray500);
    color: var(--neutral-theme);
    -webkit-tap-highlight-color: transparent;

    &:hover:enabled {
      --button-background-color: var(--gray500);
      --button-secondary-text-color: var(--neutral-theme);
      --button-secondary-hover-background-color: var(--gray500);
      border-color: var(--gray200);
    }
  }
`
