import styled from 'styled-components'

export const ImgContent = styled.div`
  margin-top: 8rem;
  display: flex;
  justify-content: center;
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 6px;
  text-align: center;
`

export const ButtonContent = styled.div`
  position: absolute;
  bottom: 0;
  padding: 1.5rem;
  width: 100%;
`
