import React, { useEffect, useState } from 'react'

import { BottomSheet } from 'components/BottomSheet'
import { StateCitiesObject } from 'types/Filters'
import { useBSState } from 'contexts/FiltersBottomsheetContext'
import { useFilters } from 'contexts/FiltersContext'
import { useConfigs } from 'contexts/ConfigsContext'

import { handleTagChangeState } from './LocationFilterTags'
import * as S from './LocationFilter.styles'
import { LocationFilterProps } from './LocationFilter.types'
import StateFilter from './StateFilter'

export const LocationFilter = ({
  statesAndCities,
  fetchHomeResponse,
  actualState,
}: LocationFilterProps) => {
  const { queryParam, setQueryParam } = useFilters()
  const { bsStateParam, setBsStateParam } = useBSState()
  const { configs } = useConfigs()

  const [tempState, setTempState] = useState(queryParam.state)

  const [selectedState, setSelectedState] = useState<StateCitiesObject>({
    label: '',
    selected: false,
    value: '',
    cities: [],
  })

  const getSelectedCity = () =>
    selectedState.cities.find((city) => city.selected) ?? {
      label: '',
      selected: false,
      value: '',
    }

  const getSelectedState = (newState?: string) => {
    let stateObject = statesAndCities.filter((state) => state.value === newState)
    if (stateObject && stateObject.length === 0) {
      stateObject = statesAndCities.filter((state) => state.selected)
    }
    if (stateObject[0].cities.length === 1 && !stateObject[0].cities[0].selected) {
      stateObject[0].cities[0].selected = true
      onFilter(stateObject[0].cities[0].value)
    }
    setSelectedState(stateObject[0])
  }

  const onFilter = async (newCity?: string) => {
    fetchHomeResponse(newCity || selectedState.cities?.filter((d) => d.selected)[0]?.value)
    onClose()
  }

  const onClose = () => {
    setBsStateParam({
      ...bsStateParam,
      showBottomSheetLocation: false,
      showBottomSheetState: false,
    })
  }

  const onDismiss = () => {
    getSelectedState(actualState)
    onClose()
    setTempState(actualState)
    setQueryParam({ ...queryParam, state: actualState })
  }

  const handleChange = (index: number) => {
    const list = selectedState.cities.map((d, idx) => ({ ...d, selected: idx === index }))
    setSelectedState({ ...selectedState, cities: list })
  }

  const handleChangeState = () => {
    handleTagChangeState({
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    }).catch(window.console.error)
    setBsStateParam({ ...bsStateParam, showBottomSheetLocation: false, showBottomSheetState: true })
  }

  useEffect(() => {
    getSelectedState(tempState)
  }, [tempState])

  const bsContent = (
    <>
      {selectedState?.cities && selectedState?.cities.length > 0 && (
        <div style={{ marginBottom: 16 }}>
          {selectedState?.cities.map((city, index) => (
            <S.FilterItem key={city.label}>
              <input
                className="input"
                type="checkbox"
                id={city.label}
                name={city.label}
                onChange={() => handleChange(index)}
                checked={city.selected}
              />
              <label className="label" htmlFor={city.label}>
                {city.label}
              </label>
            </S.FilterItem>
          ))}
        </div>
      )}
    </>
  )

  return (
    <>
      <BottomSheet
        showBottomSheet={bsStateParam?.showBottomSheetLocation}
        onDismiss={onDismiss}
        headerLabel={selectedState?.label}
        headerBtLabel="Trocar estado"
        headerBtHandler={handleChangeState}
        bsContent={bsContent}
        footerBtLabel="Aplicar"
        footerBtHandler={() => onFilter()}
        footerBtDisable={!getSelectedCity()}
      />
      <StateFilter
        fetchHomeResponse={fetchHomeResponse}
        statesAndCities={statesAndCities}
        actualState={actualState}
        tempState={tempState ?? ''}
        setTempState={setTempState}
      />
    </>
  )
}

export default LocationFilter
