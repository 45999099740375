import React, { useState } from 'react'

import AppBar from 'components/AppBar'
import { ModalityInfo, RestaurantEligibility, RestaurantResponse } from 'types/Restaurant'
import useAsync from 'hooks/UseAsync'
import Error from 'components/Error'
import PriceHank from 'components/PriceHank/PriceHank'
import { RestaurantValidation } from 'types/Validation'
import Divider from 'components/Divider'
import DynamicIcon from 'components/DynamicIcon'
import { BottomSheet } from 'components/BottomSheet'
import { useConfigs } from 'contexts/ConfigsContext'

import { interWbNavigate } from '@interco/inter-webview-bridge'
import ShoppingBagIcon from '@interco/icons/orangeds/XL/shopping-bag'
import ForkKnifeIcon from '@interco/icons/orangeds/SM/fork-knife'
import LocationIcon from '@interco/icons/orangeds/SM/location'
import ArrowIcon from '@interco/icons/orangeds/SM/chevron-right'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { Alert } from '@interco/inter-ui/components/Alert'
import { Text } from '@interco/inter-ui/components/Text'
import { Tag } from '@interco/inter-ui/components/Tag'

import * as S from './RestaurantModality.styles'
import {
  handleTagBSUseBenefit,
  handleTagClose,
  handleTagHeader,
  handleTagModality,
} from './RestaurantModality.tags'

interface ILocationProps {
  restaurantDetail: RestaurantResponse
}

const RestaurantModality = ({ location }: RouteComponentProps) => {
  const navigate = useNavigate()

  const { restaurantDetail } = location?.state as ILocationProps
  const [validationBenefit, postValidation] = useAsync<RestaurantValidation>('post')
  const [eligibility, getEligibility] = useAsync<RestaurantEligibility>('get')
  const [showBottomSheet, setShowBottomSheet] = useState<boolean>(false)
  const { configs } = useConfigs()

  const iconProps = { width: 16, height: 16, color: 'var(--gray500)', className: 'icon-style' }

  const bsContent = (
    <>
      <div style={{ marginTop: '12px ' }}>
        <Text variant="body-3" color="typography">
          Após a ativação, você deverá esperar 4 horas para usar o Duo Gourmet de novo (exceto em
          gelaterias e docerias).
        </Text>
      </div>
      <div style={{ marginTop: '12px' }}>
        {restaurantDetail.restaurant.usageRestriction ? (
          <Alert title={restaurantDetail.restaurant.usageRestrictionTitle} type="warning">
            {restaurantDetail.restaurant.usageRestrictionText}
          </Alert>
        ) : (
          <Alert type="warning">
            Use seu benefício somente ao finalizar seu pedido e pedir a conta ao garçom.
          </Alert>
        )}
      </div>
      {validationBenefit?.isError && (
        <div style={{ marginTop: '12px' }}>
          <Alert type="error">
            {validationBenefit?.error?.errors[0]?.message ??
              'Ocorreu um erro ao validar seu benefício!'}
          </Alert>
        </div>
      )}
      {!eligibility?.data?.eligible && (
        <div style={{ marginTop: '12px' }}>
          <Alert type="error">{eligibility?.data?.message}</Alert>
        </div>
      )}
    </>
  )

  const handleValidation = async () => {
    handleTagBSUseBenefit({
      restaurant: restaurantDetail.restaurant.name,
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    })
    try {
      const res = await postValidation(`app-pf/foods/orders/v1/useEvents`, {
        restaurantId: restaurantDetail.restaurant.id,
        subscription: restaurantDetail.subscription,
        modality: 'presencial',
      })
      navigate('/restaurants-benefit', {
        state: {
          ...res,
          subscription: restaurantDetail.subscription,
          restaurantId: restaurantDetail.restaurant.id,
          bankingCustomer: restaurantDetail.bankingCustomer,
        },
      })
    } catch (error) {
      window.console.error(`error:`, error)
    }
  }

  async function checkEligibility() {
    try {
      await getEligibility(
        `app-pf/foods/orders/v1/restaurants/${restaurantDetail.restaurant.id}/eligibility`,
      )
    } catch (error) {
      window.console.log(error)
    }
  }

  const handleBSClose = () => {
    handleTagClose({
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    })
    setShowBottomSheet(false)
  }

  const onClickHandle = async (choice: ModalityInfo) => {
    handleTagModality({
      restaurant: restaurantDetail.restaurant.name,
      cooking: restaurantDetail.restaurant.cooking,
      modality: choice.title,
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    })

    if (choice.title === 'Presencial') {
      await checkEligibility()
      setShowBottomSheet(true)
    } else {
      try {
        await interWbNavigate.openDeepLink(choice.deepLink)
      } catch (error) {
        window.console.error(`error:`, error)
      }
    }
  }

  if (!restaurantDetail.restaurant) {
    return (
      <>
        <AppBar name="Jeito de pedir" showHomeAction />
        <Error />
      </>
    )
  }

  return (
    <S.Container>
      <AppBar name="Benefícios" backTag={handleTagHeader} />
      <S.RestaurantInfo>
        <Text variant="headline-h3" semiBold className="rest-name">
          {restaurantDetail.restaurant.name}
        </Text>
        {restaurantDetail.duoGourmetInterCustomer && (
          <S.TagsContainer>
            {restaurantDetail.useNow && (
              <S.TagSpace>
                <Tag color="var(--success100)" colorText="var(--success500)">
                  Use agora
                </Tag>
              </S.TagSpace>
            )}
            {!restaurantDetail.available && (
              <S.TagSpace>
                <Tag color="var(--gray100)" colorText="var(--gray400)">
                  Não disponível
                </Tag>
              </S.TagSpace>
            )}
            {restaurantDetail.newRestaurant && (
              <S.TagSpace>
                <Tag theme="yellow">Novo</Tag>
              </S.TagSpace>
            )}
            {restaurantDetail.deliveryUseNow && (
              <S.TagSpace>
                <Tag color="var(--primary100)" colorText="var(--primary500)">
                  Peça agora
                </Tag>
              </S.TagSpace>
            )}
            {restaurantDetail.takeOutUseNow && (
              <S.TagSpace>
                <Tag color="var(--turquoise100)" colorText="var(--turquoise500)">
                  Retire agora
                </Tag>
              </S.TagSpace>
            )}
          </S.TagsContainer>
        )}
        <S.RestaurantDetailsContent>
          <ForkKnifeIcon {...iconProps} />
          <Text color="typography" variant="caption-1">
            {restaurantDetail.restaurant?.cooking}
          </Text>
          {restaurantDetail.restaurant?.price ? (
            <>
              <span className="dot-style">•</span>
              <PriceHank price={restaurantDetail.restaurant?.price} />
            </>
          ) : null}
        </S.RestaurantDetailsContent>
        <S.RestaurantDetailsContent>
          <ShoppingBagIcon {...iconProps} />
          {restaurantDetail.choices.map((choice, idx, arr) => (
            <div key={choice.title}>
              <Text color="typography" variant="caption-1">
                {choice.title}
              </Text>
              {idx < arr.length - 1 && (
                <span style={{ color: 'var(--gray200)', marginLeft: '3px', marginRight: '3px' }}>
                  •
                </span>
              )}
            </div>
          ))}
        </S.RestaurantDetailsContent>
        <S.RestaurantDetailsContent>
          <LocationIcon {...iconProps} />
          <S.LocationContent>
            <Text color="typography" variant="caption-1">
              {restaurantDetail.restaurant?.address}
            </Text>
            <Text color="typography" variant="caption-1">
              {restaurantDetail.restaurant?.location}
            </Text>
          </S.LocationContent>
        </S.RestaurantDetailsContent>
      </S.RestaurantInfo>
      <Divider borderWidth={8} color="var(--gray100)" className="divider-style" />
      <S.ModalityInfo>
        <div className="info-title">
          <Text variant="headline-h3" semiBold>
            Como você quer usar seu benefício?
          </Text>
        </div>
        {restaurantDetail.choices.map((choice) => (
          <div key={choice.title}>
            <S.ModalityItem onClick={() => onClickHandle(choice)}>
              <S.ModalityIcon>
                <DynamicIcon name={choice.icon} height={24} width={24} color="var(--primary500)" />
              </S.ModalityIcon>
              <S.ModalityLabel>
                <Text variant="body-3" bold colorWeight={500} className="modality-title">
                  {choice.title}
                </Text>
                <Text variant="caption-1">{choice.text}</Text>
              </S.ModalityLabel>
              <S.ArrowStyle>
                <ArrowIcon
                  width={25}
                  height={25}
                  color="var(--primary500)"
                  className="arrow-icon"
                />
              </S.ArrowStyle>
            </S.ModalityItem>
            <Divider />
          </div>
        ))}
        <div className="use-alert">
          <Alert type="grayscale">
            Após a ativação, você deverá esperar 4 horas para usar o Duo Gourmet de novo (exceto
            para galeterias e docerias).
          </Alert>
        </div>
      </S.ModalityInfo>
      <BottomSheet
        showBottomSheet={showBottomSheet}
        onDismiss={handleBSClose}
        headerLabel="Usar meu benefício"
        headerBtLabel="Fechar"
        headerBtHandler={handleBSClose}
        footerBtLabel="Usar meu benefício"
        footerBtDisable={!eligibility?.data?.eligible}
        footerBtHandler={handleValidation}
        bsContent={bsContent}
      />
    </S.Container>
  )
}

export default RestaurantModality
