import React from 'react'

import { BottomSheet } from 'components/BottomSheet'

import { Text } from '@interco/inter-ui/components/Text'

interface TooltipBsProps {
  showBottomSheet: boolean
  setShowBottomSheet: (value: boolean) => void
  bottomSheetText?: string
}

const TooltipBs = ({ showBottomSheet, setShowBottomSheet, bottomSheetText }: TooltipBsProps) => {
  const bsContent = (
    <div style={{ marginTop: '12px' }}>
      <Text variant="body-3">{bottomSheetText}</Text>
    </div>
  )

  const handleBSClose = () => setShowBottomSheet(false)

  return (
    <BottomSheet
      showBottomSheet={showBottomSheet}
      onDismiss={handleBSClose}
      headerLabel="Benefício não disponível"
      headerBtLabel="Fechar"
      headerBtHandler={handleBSClose}
      footerBtLabel="Entendi"
      footerBtHandler={handleBSClose}
      bsContent={bsContent}
    />
  )
}

export default TooltipBs
