import React, { useEffect, useCallback, useState } from 'react'

import NewRelic from 'utils/NewRelic'
import { ApiErrors } from 'types/http/ApiError'
import { Landing } from 'types/LandingPage'
import Divider from 'components/Divider'
import AppBar from 'components/AppBar'
import useAsync from 'hooks/UseAsync'
import Error from 'components/Error'
import DynamicIcon from 'components/DynamicIcon'
import { bridgeNative } from 'App'
import { useBSState } from 'contexts/FiltersBottomsheetContext'
import PromotionalCarousel from 'components/PromotionalCarousel/PromotionalCarousel'
import InvalidAssignatureBs from 'components/InvalidAssignatureBs'
import queryString from 'query-string'
import { useConfigs } from 'contexts/ConfigsContext'
import { getParamsFromURL } from 'utils/TagsUtils'
import { ETrackEvent } from 'enums/ETrackEvent'
import { LocationProps } from 'types/Home'
import { ECurrentMainTab } from 'enums/EContexts'

import { RouteComponentProps, useNavigate } from '@reach/router'
import { Babi } from '@interco/inter-ui/components/Babi'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './LandingPage.styles'
import LandingPageShimmer from './LandingPageShimmer'
import * as T from './LandingPage.tags'

const LandingPage = ({ location }: RouteComponentProps) => {
  const screenName = 'Duo Gourmet'

  const navigate = useNavigate()
  const { configs } = useConfigs()
  const { bsStateParam, setBsStateParam } = useBSState()
  const { utmParams, adjParams } = getParamsFromURL()
  const [topPageImage, setTopPageImage] = useState('')
  const [totalValue, setTotalValue] = useState('')
  const [annualPlan, setAnnualPlan] = useState('')
  const [annualPlanColor, setAnnualPlanColor] = useState('')
  const [discountValue, setDiscountValue] = useState('')

  const locationState = location?.state as LocationProps
  const city = locationState?.city || ''
  const [landingPageData, getLandingPage] = useAsync<Landing>('get')

  const fetchLandingPage = useCallback(async () => {
    try {
      await getLandingPage(
        `${process.env.REACT_APP_API}/landingPage?${queryString.stringify({ city })}`,
      )
    } catch (error) {
      const err = error as ApiErrors
      NewRelic.noticeError(err)
    }
  }, [getLandingPage])

  useEffect(() => {
    setTopPageImage(landingPageData?.data?.topPageImage || '')
    setTotalValue(landingPageData?.data?.totalValue?.replace('5x de R$ 90', '12x de R$ 45') || '')
    setAnnualPlan(landingPageData?.data?.annualPlan || '')
    setAnnualPlanColor('var(--gray400)')
    setDiscountValue(landingPageData?.data?.discountValue || '')
  }, [
    landingPageData?.data?.topPageImage,
    landingPageData?.data?.totalValue,
    landingPageData?.data?.annualPlan,
    landingPageData?.data?.discountValue,
  ])

  useEffect(() => {
    fetchLandingPage()
  }, [fetchLandingPage])

  useEffect(() => {
    T.handleTagLoad({ ...utmParams, ...adjParams, type: configs.subscriber?.customerSegmentation })
  }, [])

  const handleSubscription = async () => {
    try {
      T.handleTagSubscribe({
        ...utmParams,
        ...adjParams,
        type: configs.subscriber?.customerSegmentation,
      })
      if (!landingPageData.data?.pessoaFisica) {
        setBsStateParam({ ...bsStateParam, showBottomSheetInvalidAssignature: false })
      } else {
        await bridgeNative.execute({
          action: 'sendToSubscription',
          metadata: { productId: 'DUO_GOURMET' },
        })
      }
    } catch (error) {
      const err = error as ApiErrors
      NewRelic.noticeError(err)
    }
  }

  const getBlackBanners = () => {
    const blackBanners = landingPageData.data?.banners?.filter((banner) =>
      banner.description?.toLowerCase().includes('com a sua assinatura no app do duo gourmet'),
    )
    return blackBanners
  }

  if (landingPageData.isError) {
    return (
      <>
        <AppBar name={screenName} goBackNative showHomeAction />
        <Error goBackNative />
      </>
    )
  }

  if (landingPageData.isPending) {
    return <LandingPageShimmer />
  }

  if (landingPageData.isSuccess) {
    return (
      <>
        <AppBar
          name={screenName}
          goBackNative
          subscriber={landingPageData.data?.duoGourmetInterCustomer}
          showHomeAction={!landingPageData.data?.duoGourmetInterCustomer}
          backTag={T.handleTagHeader}
          homeTag={T.handleTagHome}
        />
        <S.Container>
          <S.WrapperHero>
            <img src={topPageImage} alt="" style={{ width: '100%', height: '100%' }} />
          </S.WrapperHero>
          <Text
            variant="headline-h1"
            semiBold
            style={{ marginTop: '24px', marginBottom: '8px' }}
            as="h1"
          >
            {landingPageData.data?.title}
          </Text>
          <Text variant="body-3">
            <div
              dangerouslySetInnerHTML={{
                __html: landingPageData.data?.description,
              }}
            />
          </Text>
        </S.Container>

        {totalValue && (
          <S.PlanWrapper>
            <Text variant="caption-1" style={{ color: annualPlanColor }}>
              <div dangerouslySetInnerHTML={{ __html: annualPlan }} />
            </Text>
            <Text variant="headline-h1">
              <div dangerouslySetInnerHTML={{ __html: totalValue }} />
            </Text>
            {/* <br /> */}
            {landingPageData.data?.paymentMethod && (
              <Text variant="caption-1">
                <div dangerouslySetInnerHTML={{ __html: landingPageData.data?.paymentMethod }} />
              </Text>
            )}
            <Text variant="caption-1" style={{ color: 'var(--success500)' }}>
              <div>{discountValue}</div>
            </Text>
          </S.PlanWrapper>
        )}

        <S.TopBody>
          {landingPageData.data.banners && landingPageData.data.banners.length > 0 && (
            <S.BannerWrapper>
              <PromotionalCarousel
                banners={landingPageData.data?.banners}
                pessoaFisica={landingPageData.data?.pessoaFisica}
                canSubscribe={landingPageData.data?.canSubscribe}
                bannerTag={{
                  ref_figma: '4',
                  flow: ETrackEvent.F_SUBSCRIPTION,
                  screen: ETrackEvent.S_LANDING_PAGE,
                  subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
                }}
              />
            </S.BannerWrapper>
          )}
          {!landingPageData.data?.canSubscribe?.canSubscribe ? (
            <Button
              onClick={() => {
                setBsStateParam({
                  ...bsStateParam,
                  showBottomSheetInvalidAssignature: true,
                  showBottomSheetPromotionalBanners: false,
                })
              }}
              className="button-style"
            >
              {landingPageData.data?.subscriptionButtonTitle}
            </Button>
          ) : (
            <Button onClick={handleSubscription} className="button-style">
              {landingPageData.data?.subscriptionButtonTitle}
            </Button>
          )}

          {!landingPageData.data?.canSubscribe?.canSubscribe && (
            <InvalidAssignatureBs
              text={landingPageData.data?.canSubscribe?.text}
              button={landingPageData.data?.canSubscribe?.button}
              pessoaFisica={landingPageData.data?.pessoaFisica}
              canSubscribe={landingPageData.data?.canSubscribe}
              isDuoGourmetLegacyUser={landingPageData.data?.duoGourmetLegacyCustomer}
              blackCardBanners={getBlackBanners()}
            />
          )}

          <Button
            variant="secondary"
            onClick={async () => {
              T.handleTagRestaurants()
              navigate(`/home/${ECurrentMainTab.home}`)
            }}
          >
            {landingPageData.data?.restaurantsButtonTitle}
          </Button>
        </S.TopBody>
        {landingPageData.data.duoGourmetLegacyCustomer &&
          landingPageData.data.tempBanners &&
          landingPageData.data.tempBanners.length > 0 && (
            <S.CarouselWrapper>
              <PromotionalCarousel
                banners={landingPageData.data.tempBanners}
                pessoaFisica={landingPageData.data?.pessoaFisica}
                canSubscribe={landingPageData.data?.canSubscribe}
                bannerTag={{
                  ref_figma: '4',
                  flow: ETrackEvent.F_SUBSCRIPTION,
                  screen: ETrackEvent.S_LANDING_PAGE,
                  subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
                }}
              />
            </S.CarouselWrapper>
          )}
        <Divider
          borderWidth={8}
          color="var(--gray100)"
          style={{ marginTop: '32px', marginBottom: '32px' }}
        />

        <S.AdvantagesWrapper>
          <Text variant="headline-h2" semiBold style={{ marginBottom: '8px' }} as="h2">
            {landingPageData.data?.advantagesTitle}
          </Text>
          {landingPageData.data?.advantagesList?.map((e) => (
            <S.AdvantagesList key={e.title}>
              <S.AdvantagesIcon>
                <DynamicIcon name={e.icon} width={24} height={24} color="var(--gray500)" />
              </S.AdvantagesIcon>
              <S.AdvantagesText>
                <Text colorWeight={500} variant="body-3" bold style={{ marginBottom: '8px' }}>
                  {e.title}
                </Text>
                <Text variant="body-3">{e.text}</Text>
              </S.AdvantagesText>
            </S.AdvantagesList>
          ))}
        </S.AdvantagesWrapper>
        <Divider borderWidth={8} color="var(--gray100)" style={{ margin: '32px 0' }} />
        <S.FooterWrapper>
          {!landingPageData.data.canSubscribe.canSubscribe ? (
            <Button
              onClick={() => {
                setBsStateParam({ ...bsStateParam, showBottomSheetInvalidAssignature: true })
              }}
            >
              {landingPageData.data?.secondarySubscriptionButtonTitle}
            </Button>
          ) : (
            <Button onClick={handleSubscription}>
              {landingPageData.data?.secondarySubscriptionButtonTitle}
            </Button>
          )}
          <Babi origin={ETrackEvent.T_PRODUCT} />
        </S.FooterWrapper>
      </>
    )
  }
  return null
}

export default LandingPage
