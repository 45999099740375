import { useCallback, useEffect, useState } from 'react'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import queryString from 'query-string'
import AppBar from 'components/AppBar'
import Error from 'components/Error'
import { useFilters } from 'contexts/FiltersContext'
import { ExperienceDetailsResponse } from 'types/Experiences'
import useAsync from 'hooks/UseAsync'
import { BottomSheet } from 'components/BottomSheet'
import { FixButton } from 'components/FixButton'
import EventSchedule from 'features/Experiences/EventSchedule'
import Divider from 'components/Divider'
import { ExperienceValidationPost } from 'types/Validation'
import ExperienceRules from 'features/Experiences/ExperienceRules'
import { ESubscriptionStatus } from 'types/Subscription'
import { bridgeNative } from 'App'
import { useBSState } from 'contexts/FiltersBottomsheetContext'
import { useConfigs } from 'contexts/ConfigsContext'
import AddressButton from 'components/AddressButton/AddressButton'

import CalendarIcon from '@interco/icons/bidis/Icons/calendar'
import PendingIcon from '@interco/icons/orangeds/SM/pending'
import CopyIcon from '@interco/icons/orangeds/XL/copy'
import ShareIcon from '@interco/icons/orangeds/SM/share'
import SiteIcon from '@interco/icons/bidis/v2/social/website'
import InstagramIcon from '@interco/icons/orangeds/XL/instagram'
import { RouteComponentProps, useNavigate, useParams } from '@reach/router'
import InterWebviewBridge, { interWbNavigate, interWbSession } from '@interco/inter-webview-bridge'
import { Alert } from '@interco/inter-ui/components/Alert'
import { Text } from '@interco/inter-ui/components/Text'
import { Carousel } from '@interco/inter-ui/components/Carousel'
import { Tag } from '@interco/inter-ui/components/Tag'

import ExperiencesDetailsShimmer from './ExperiencesDetailsShimmer'
import * as S from './ExperiencesDetails.styles'
import * as T from './ExperiencesDetails.tags'

const ExperiencesDetails = (_: RouteComponentProps) => {
  const screenTitle = 'Experiências'

  const navigate = useNavigate()
  const { configs } = useConfigs()
  const { queryParam } = useFilters()
  const { bsStateParam, setBsStateParam } = useBSState()

  const params = useParams<{ id: string }>()
  const device = InterWebviewBridge.getInstance().getEnvironment()

  const [experienceInfo, getExperienceInfo] = useAsync<ExperienceDetailsResponse>('get')
  const [validation, postValidation] = useAsync<ExperienceValidationPost>('post')
  const [showBottomSheet, setShowBottomSheet] = useState(false)

  const [oneTimeEvent, setOneTimeEvent] = useState(false)
  const [externalEvent, setExternalEvent] = useState(false)
  const [hasDiscountCode, setHasDiscountCode] = useState(false)
  const [hasSocialLink, setHasSocialLink] = useState(false)
  const [hasPartnerCoupon, setHasPartnerCoupon] = useState(false)

  useEffect(() => {
    fetchExperience()
  }, [])

  const fetchExperience = useCallback(async () => {
    const res = (await getExperienceInfo(
      `${process.env.REACT_APP_API}/experiences/${params.id}?${queryString.stringify({
        latitude: queryParam?.latitude,
        longitude: queryParam?.latitude,
        device,
      })}`,
    ).catch(window.console.error)) as ExperienceDetailsResponse

    setOneTimeEvent(!!res.experience.eventDate)
    setExternalEvent(!!res.experience?.instructions?.link)
    setHasDiscountCode(!!res.experience?.instructions?.coupon)
    setHasSocialLink(!!res?.experience.instagramUsername || !!res?.experience.site)
    setHasPartnerCoupon(
      !!res.experience?.instructions?.partner && !!res.experience?.instructions?.link,
    )

    T.handleAccess(res.experience.title)
  }, [])

  const handleOpenBenefit = () => {
    if (externalEvent) {
      setShowBottomSheet(true)
    } else {
      navigate('/experience-benefit', {
        state: { id: experienceInfo.data?.experience.id },
      })
    }
  }

  const handleValidateBenefit = async () => {
    try {
      await postValidation(`app-pf/foods/orders/v1/useEvents/experiences`, {
        subscription: experienceInfo.data?.subscription,
        experienceId: experienceInfo.data?.experience.id,
      })
    } catch (error) {
      window.console.log(error)
      return
    }

    if (externalEvent && !experienceInfo.data?.experience?.instructions?.partner) {
      interWbNavigate.openDeepLink(experienceInfo.data?.experience?.instructions?.link)
    } else {
      navigate('/experience-benefit', {
        state: { id: experienceInfo.data?.experience.id },
      })
    }

    setShowBottomSheet(false)
  }

  const handleBSClose = () => setShowBottomSheet(false)
  const handleCopyCode = async (discountCode: string) =>
    interWbSession.copyToClipboard('Código desconto Duo', discountCode).catch(window.console.error)

  const handleSubscription = async () => {
    try {
      if (!experienceInfo.data?.pessoaFisica) {
        setBsStateParam({ ...bsStateParam, showBottomSheetInvalidAssignature: true })
      } else {
        await bridgeNative.execute({
          action: 'sendToSubscription',
          metadata: { productId: 'DUO_GOURMET' },
        })
      }
    } catch (error) {
      window.console.error(`error:`, error)
    }
  }

  const bottomSheetContent = (
    <S.BSWrapper>
      <Text variant="body-3">
        <div
          dangerouslySetInnerHTML={{
            __html: experienceInfo.data?.experience?.instructions?.description,
          }}
        />
      </Text>
      {hasDiscountCode && (
        <S.BSCouponCode>
          <Text variant="caption-1">Código desconto Duo:</Text>
          <S.CopyCode>
            <Text variant="headline-h2" semiBold>
              {experienceInfo.data?.experience?.instructions?.coupon}
            </Text>
            <CopyIcon
              height={24}
              width={24}
              color="var(--primary500)"
              onClick={() => handleCopyCode(experienceInfo.data?.experience?.instructions?.coupon)}
            />
          </S.CopyCode>
        </S.BSCouponCode>
      )}
    </S.BSWrapper>
  )

  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    verticalMode: false,
  }

  if (experienceInfo?.isError) {
    return (
      <>
        <AppBar name={screenTitle} showHomeAction />
        <Error />
      </>
    )
  }

  if (validation?.isError) {
    return (
      <>
        <AppBar name={screenTitle} showHomeAction />
        <Error />
      </>
    )
  }

  if (experienceInfo?.isPending) {
    return <ExperiencesDetailsShimmer appBarName={screenTitle} />
  }

  if (experienceInfo?.isSuccess) {
    return (
      <>
        <AppBar name={screenTitle} backTag={T.handleHeaderGoBack} />
        <S.SliderTheme>
          <Carousel dotsType="medium" {...settings}>
            <S.CoverImage src={experienceInfo?.data?.experience?.imgCoverUrl} />
            {experienceInfo?.data?.experience?.otherImages?.map((e) => (
              <S.CoverImage key={e} src={e} />
            ))}
          </Carousel>
        </S.SliderTheme>
        <S.Header
          style={
            experienceInfo?.data?.experience?.otherImages.length === 0
              ? { marginTop: '1rem' }
              : { marginTop: '2rem' }
          }
        >
          <S.TitleContainer>
            <Text color="typography" variant="headline-h1" semiBold className="title">
              {experienceInfo.data?.experience.title}
            </Text>
            <S.TopIconContainer>
              <button
                id="overlay"
                type="button"
                onClick={async () =>
                  interWbSession.shareContent(experienceInfo?.data?.experience.deepLink)
                }
              >
                <ShareIcon width={24} height={24} color="var(--primary500)" />
              </button>
              {hasSocialLink && (
                <button
                  id="overlay"
                  type="button"
                  onClick={() => {
                    experienceInfo?.data?.experience.instagramUsername
                      ? interWbNavigate.openDeepLink(
                          `https://instagram.com/${experienceInfo?.data?.experience.instagramUsername}`,
                        )
                      : interWbNavigate.openDeepLink(experienceInfo?.data?.experience.site ?? '')
                  }}
                >
                  {experienceInfo?.data?.experience.instagramUsername ? (
                    <InstagramIcon width={24} height={24} color="var(--primary500)" />
                  ) : (
                    <SiteIcon width={24} height={24} color="var(--primary500)" />
                  )}
                </button>
              )}
            </S.TopIconContainer>
          </S.TitleContainer>

          {experienceInfo.data?.experience.useNow && (
            <S.TagsContainer>
              <S.TagSpace>
                <Tag color="var(--success100)" colorText="var(--success500)">
                  Use agora
                </Tag>
              </S.TagSpace>
            </S.TagsContainer>
          )}

          {oneTimeEvent && (
            <S.OneTimeSchedule>
              <CalendarIcon
                width={16}
                height={16}
                color="var(--gray500)"
                style={{ marginRight: '0.5rem' }}
              />
              <Text variant="body-3">{experienceInfo.data?.experience.eventDate}</Text>
            </S.OneTimeSchedule>
          )}

          {experienceInfo?.data?.experience?.alwaysAvailable?.enabled && (
            <S.OneTimeSchedule>
              <PendingIcon
                width={16}
                height={16}
                color="var(--gray500)"
                style={{ marginRight: '0.5rem' }}
              />
              <Text variant="caption-1" colorWeight={500}>
                Disponível todos os dias da semana
              </Text>
            </S.OneTimeSchedule>
          )}

          {experienceInfo.data?.experience?.showAddress && (
            <AddressButton
              title={experienceInfo.data?.experience?.addressReference}
              address={experienceInfo.data?.experience?.address}
              location={`${experienceInfo?.data?.experience.city} - ${experienceInfo.data?.experience?.state}`}
              onClickHandler={async () => {
                T.handleExperienceLocation({
                  event: experienceInfo.data?.experience.title,
                  plan_name: configs.subscriber.planName,
                  plan_value: configs.subscriber.planValue,
                })
                navigate(`/experiences/${experienceInfo?.data?.experience.id}/location`, {
                  state: {
                    experienceName: experienceInfo?.data?.experience.title,
                    latitude: experienceInfo?.data?.experience.coordinates?.latitude,
                    longitude: experienceInfo?.data?.experience?.coordinates?.longitude,
                    duoGourmetSubscriber: experienceInfo?.data?.duoGourmetInterCustomer,
                  },
                })
              }}
            />
          )}

          <S.BenefitAlert>
            <Alert title={experienceInfo?.data?.experience?.benefitTitle} type="success">
              {experienceInfo?.data?.experience?.benefit?.text}
            </Alert>
          </S.BenefitAlert>

          <Divider borderWidth={1} color="var(--gray200)" className="divider" />
        </S.Header>

        <S.Container>
          {!oneTimeEvent &&
            !experienceInfo?.data?.experience?.alwaysAvailable?.enabled &&
            experienceInfo.data?.experience?.schedule?.length > 0 && (
              <>
                <EventSchedule schedule={experienceInfo?.data?.experience?.schedule} />
                <Divider borderWidth={1} color="var(--gray200)" className="divider" />
              </>
            )}
          {hasPartnerCoupon && (
            <>
              <Alert title={experienceInfo?.data?.experience?.instructions.title} type="grayscale">
                <div
                  dangerouslySetInnerHTML={{
                    __html: experienceInfo.data?.experience?.instructions?.description,
                  }}
                />
              </Alert>
              <Divider borderWidth={1} color="var(--gray200)" className="divider" />
            </>
          )}
          <S.RulesContent>
            <ExperienceRules
              content={experienceInfo?.data?.experience?.benefit?.rules}
              analyticsTag={() =>
                T.handleExperienceRules({
                  event: experienceInfo.data?.experience.title,
                  plan_name: configs.subscriber.planName,
                  plan_value: configs.subscriber.planValue,
                })
              }
            />
          </S.RulesContent>
        </S.Container>
        {experienceInfo?.data?.duoGourmetInterCustomer ? (
          <S.Footer>
            {!experienceInfo?.data?.activeUseEvent && experienceInfo?.data?.available && (
              <FixButton
                label="Usar meu benefício"
                onClick={() => {
                  setShowBottomSheet(true)
                  T.handleUseBenefit({
                    event: experienceInfo.data?.experience.title,
                    neighborhood: experienceInfo.data?.experience.address,
                    benefit: experienceInfo.data?.experience.benefit.shortText,
                    city: experienceInfo.data?.experience.city,
                    plan_name: configs.subscriber.planName,
                    plan_value: configs.subscriber.planValue,
                  })
                }}
                width="100%"
              />
            )}
            {experienceInfo?.data?.activeUseEvent && (
              <FixButton label="Consultar benefício" onClick={handleOpenBenefit} width="100%" />
            )}
            {!experienceInfo?.data?.available && !experienceInfo?.data?.activeUseEvent && (
              <FixButton label="Experiência indisponível no momento" disabled width="100%" />
            )}
          </S.Footer>
        ) : (
          <S.Footer>
            <FixButton
              label="Assine agora e comece a economizar"
              onClick={handleSubscription}
              width="100%"
            />
          </S.Footer>
        )}
        <BottomSheet
          showBottomSheet={showBottomSheet}
          onDismiss={handleBSClose}
          headerBtLabel="Fechar"
          headerBtHandler={handleBSClose}
          headerLabel={experienceInfo.data?.experience?.instructions?.title}
          bsContent={bottomSheetContent}
          footerBtLabel="Continuar"
          footerBtDisable={
            (!experienceInfo.data?.available && !externalEvent) ||
            experienceInfo.data?.subscriptionStatus === ESubscriptionStatus.PENDING_PAYMENT
          }
          footerBtHandler={handleValidateBenefit}
        />
      </>
    )
  }

  return null
}

export default ExperiencesDetails
