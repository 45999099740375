import AppBar from 'components/AppBar'

import { Skeleton } from '@interco/inter-ui/components/Skeleton'

import * as S from './RestaurantsBenefit.styles'

const RestaurantBenefitShimmer = () => (
  <S.ShimmerWrapper>
    {window.scrollTo({ top: 0, behavior: 'auto' })}
    <AppBar name="Duo Gourmet" />
    <Skeleton width="4rem" height="4rem" variant="circle" className="m-3 success-img" />
    <Skeleton width="50%" height="30px" className="m-3" />
    <Skeleton width="312px" height="39px" className="m-3" />
    <Skeleton width="312px" height="280px" className="m-3 table-style" />
    <Skeleton width="312px" height="150px" className="m-3 bt-style" />
  </S.ShimmerWrapper>
)

export default RestaurantBenefitShimmer
