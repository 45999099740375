import React from 'react'

import AppBar from 'components/AppBar'

import { Skeleton } from '@interco/inter-ui/components/Skeleton'

import { Advantages, CancelSubscriptionStyles } from './CancelSubscription.styles'

const CancelSubscriptionShimmer = () => (
  <>
    <AppBar name="Cancelamento" />
    <CancelSubscriptionStyles fullHeight style={{ paddingTop: 60 }}>
      <div>
        <div className="container-price">
          <Skeleton width="40%" height="14px" />
          <Skeleton width="30%" height="20px" style={{ marginTop: 8 }} />
        </div>
        <Skeleton width="100%" height="16px" style={{ marginTop: 8 }} />

        {new Array(7).fill('').map((_, i) => (
          <Advantages key={`id1_${i.toString()}`}>
            <div>
              <Skeleton width="25px" height="25px" />
            </div>
            <div
              style={{
                marginLeft: '17px',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Skeleton width="30%" height="15px" />

              <Skeleton width="100%" height="20px" style={{ marginTop: 10 }} />
            </div>
          </Advantages>
        ))}
      </div>
    </CancelSubscriptionStyles>
  </>
)

export default CancelSubscriptionShimmer
