import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
`

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  .title {
    margin-bottom: 0.3rem;
  }
`

export const BtContainer = styled.div``
