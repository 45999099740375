import React from 'react'

import { DividerStyles } from './Divider.styles'

export type TDivider = 'dashed' | 'solid' | 'double' | 'dotted'

export interface DividerProps extends React.HTMLAttributes<unknown> {
  type?: TDivider
  borderWidth?: number
  fullScreen?: boolean
  color?: string
}
const Divider = ({
  type = 'solid',
  borderWidth,
  color = 'var(--gray200)',
  ...props
}: DividerProps) => (
  <DividerStyles
    type={type}
    data-testid="divider"
    borderWidth={borderWidth}
    color={color}
    {...props}
  />
)

export default Divider
