import { ETrackEvent } from 'enums/ETrackEvent'

import { interWbSession } from '@interco/inter-webview-bridge'

export const handleTagHeader = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_HEADER, {
      ref_figma: '1',
      flow: ETrackEvent.F_SUBSCRIPTION,
      screen: ETrackEvent.S_LANDING_PAGE,
      action_id: 'back',
      product: ETrackEvent.T_PRODUCT,
    })
    .catch(window.console.log)
}

export const handleTagHome = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_HEADER, {
      ref_figma: '2',
      flow: ETrackEvent.F_SUBSCRIPTION,
      screen: ETrackEvent.S_LANDING_PAGE,
      action_id: 'home',
      product: ETrackEvent.T_PRODUCT,
    })
    .catch(window.console.log)
}

export const handleTagSubscribe = async ({ ...props }) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '3',
      flow: ETrackEvent.F_SUBSCRIPTION,
      screen: ETrackEvent.S_LANDING_PAGE,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'assinar o duo gourmet',

      ...props,
    })
    .catch(window.console.log)
}

export const handleTagRestaurants = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '5',
      flow: ETrackEvent.F_SUBSCRIPTION,
      screen: ETrackEvent.S_LANDING_PAGE,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'mostrar restaurantes disponíveis',
    })
    .catch(window.console.log)
}

export const handleTagLoad = async ({ ...props }) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_ACCESS, {
      ref_figma: '7',
      flow: ETrackEvent.F_SUBSCRIPTION,
      screen: ETrackEvent.S_LANDING_PAGE,
      content_action: 'dado carregado',
      action_id: 'acesso lp',

      ...props,
    })
    .catch(window.console.log)
}
