import { ETrackEvent } from 'enums/ETrackEvent'

import { interWbSession } from '@interco/inter-webview-bridge'

interface SpecialSelectionsTags {
  item_name?: string
  position?: string
}

export const handleTagItem = async ({ item_name = '', position = '' }: SpecialSelectionsTags) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '6',
      flow: ETrackEvent.F_HOME_SUB,
      screen: ETrackEvent.S_HOME_SUB,
      ref_type: 'card',
      content_action: 'toque',
      action_id: 'seleções especiais',

      item_name,
      position,
    })
    .catch(window.console.log)
}
