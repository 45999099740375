import styled from 'styled-components'

export const AdvantagesWrapper = styled.div`
  margin: 0px 24px;
`

export const AdvantagesList = styled.div`
  display: flex;
  margin-top: 24px;
`

export const AdvantagesText = styled.div`
  margin-left: 17px;
  display: flex;
  flex-direction: column;
`

export const AdvantagesIcon = styled.div``

export const Container = styled.div`
  padding: 0px 24px;
  margin-top: 64px;
  margin-bottom: 24px;
`

export const WrapperHero = styled.div`
  margin-left: -24px;
  margin-right: -24px;
  height: 256px;
`
export const ContentImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray200);
  padding: 24px 0;
  border-radius: 8px;
  margin: 24px 0 18px 0;
`

export const PlanWrapper = styled.div`
  text-align: center;
  margin: 24px 22px;
`

export const BannerWrapper = styled.div`
  margin-bottom: 16px;
`

export const TopBody = styled.div`
  margin: 0 24px 14px 24px;

  button {
    width: 100%;
  }

  .button-style {
    margin: 0px 0px 12px 0px;
  }
`

export const CarouselWrapper = styled.div`
  margin: 0 24px -20px;
`

export const FooterWrapper = styled.div`
  margin: 0 0 24px;
  padding: 0px 24px 40px;

  button {
    margin-bottom: 23px;
    width: 100%;
  }
`
