import React from 'react'

import AppBar from 'components/AppBar'
import Divider from 'components/Divider'

import { Skeleton } from '@interco/inter-ui/components/Skeleton'

import * as S from './MenuUser.styles'

const MenuUsuarioShimmer = () => (
  <S.Wrapper>
    <AppBar name="Menu Duo Gourmet" />
    <S.Content>
      <S.ShimmerTotalSaved>
        <Skeleton width="40%" height="14px" />
        <Skeleton width="30%" height="30px" />
        <Skeleton width="40%" height="14px" />
      </S.ShimmerTotalSaved>
    </S.Content>
    <Divider color="var(--gray100)" borderWidth={8} style={{ marginBottom: '2rem' }} />
    {[...Array(6)].map((_, idx) => (
      <S.ShimmerLink key={`links-${idx.toString()}`}>
        <Skeleton width="100%" height="2.5rem" />
      </S.ShimmerLink>
    ))}
  </S.Wrapper>
)

export default MenuUsuarioShimmer
