import React from 'react'

import AppBar from 'components/AppBar'
import RestaurantsMap from 'components/RestaurantsMap/RestaurantsMap'
import useUserLocation from 'hooks/useUserLocation'

import { interWbNavigate } from '@interco/inter-webview-bridge'
import { RouteComponentProps } from '@reach/router'
import { Button } from '@interco/inter-ui/components/Button'

import { BottomButton, Container } from './RestaurantLocation.styles'

interface RestaurantInfoProps {
  restaurantName: string
  latitude: number
  longitude: number
  duoGourmetSubscriber: boolean
}

export const RestaurantLocation = ({ location }: RouteComponentProps) => {
  const { restaurantName, latitude, longitude, duoGourmetSubscriber } =
    location?.state as RestaurantInfoProps
  const userLocation = useUserLocation()

  const restaurantCoordinates = {
    lat: latitude,
    lng: longitude,
  }

  const userCoordinates = {
    lat: parseFloat(userLocation?.latitude),
    lng: parseFloat(userLocation?.longitude),
  }

  return (
    <>
      <AppBar
        name={restaurantName}
        subscriber={duoGourmetSubscriber}
        showHomeAction={!duoGourmetSubscriber}
      />
      <Container>
        <RestaurantsMap
          restaurantCoordinates={restaurantCoordinates}
          userCoordinates={userCoordinates}
          boundsCoordinates={[{ lat: restaurantCoordinates.lat, lng: restaurantCoordinates.lng }]}
        />
      </Container>
      <BottomButton>
        <Button
          onClick={async () => {
            try {
              await interWbNavigate.openLocationMap(latitude, longitude)
            } catch (error) {
              window.console.log(error)
            }
          }}
        >
          Definir rota
        </Button>
      </BottomButton>
    </>
  )
}
