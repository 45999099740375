import React from 'react'

import { Skeleton } from '@interco/inter-ui/components/Skeleton'

const HomeAnnouncementsShimmer = () => (
  <Skeleton
    data-testid="home-announcements-shimmer"
    width="100%"
    style={{ marginTop: '15px' }}
    height="145px"
    className="m-3"
  />
)

export default HomeAnnouncementsShimmer
