import React, { CSSProperties } from 'react'

import { MainContainer, Tab, TabList, TabPanel } from './TabPager.styles'

export type TabDefinition = {
  tabId: string | number
  label: string
  disabled?: boolean
  content: React.ReactNode
}

export type TabPagerProps = {
  currentTabId: string | number
  tabDefinitions: TabDefinition[]
  onTabSelect: (tabId: string | number) => void
  mainContainerStyle?: CSSProperties
  tabListStyle?: CSSProperties
  tabPanelStyle?: CSSProperties
}

export const TabPager = React.memo(
  ({
    currentTabId,
    tabDefinitions,
    onTabSelect,
    mainContainerStyle = {},
    tabListStyle = {},
    tabPanelStyle = {},
  }: TabPagerProps) => (
    <MainContainer data-testid="tabPagerContainer" style={mainContainerStyle}>
      <TabList role="tablist" style={tabListStyle}>
        {tabDefinitions.map((tabDefinition) => (
          <Tab
            role="tab"
            aria-selected={tabDefinition.tabId === currentTabId}
            key={tabDefinition.tabId}
            current={tabDefinition.tabId === currentTabId}
            disabled={!!tabDefinition.disabled}
            onClick={() =>
              !tabDefinition.disabled &&
              tabDefinition.tabId !== currentTabId &&
              onTabSelect(tabDefinition.tabId)
            }
          >
            {tabDefinition.label}
          </Tab>
        ))}
      </TabList>
      <TabPanel role="tabpanel" style={tabPanelStyle}>
        {tabDefinitions.find((tabDefinition) => tabDefinition.tabId === currentTabId)?.content}
      </TabPanel>
    </MainContainer>
  ),
)
