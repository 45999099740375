import styled from 'styled-components'

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1.3rem 0 1rem;
  margin-top: 3.5rem;
  overflow: hidden;
  align-items: center;

  .dividerStyle {
    margin: 0 -2rem;
  }

  .locationStyle {
    margin: 0.8rem 0;
    padding: 0.5rem 0;
    width: 100%;
  }
`

export const FilterList = styled.div`
  display: flex;
  margin: 0.5rem 6rem 0.5rem 3rem;
`

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 1.2rem;
`

export const Item = styled.div``

export const Content = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  gap: 0.5rem;
`

export const RestaurantDetails = styled.div`
  display: flex;
  flex-direction: column;
`
