import { ETrackEvent } from 'enums/ETrackEvent'
import { SubscriberTag } from 'types/AnalyticsTag'

import { interWbSession } from '@interco/inter-webview-bridge'

interface RestaurantSubTag extends SubscriberTag {
  restaurant: string
}

interface ModalityTag extends RestaurantSubTag {
  cooking: string
  modality: string
}

export const handleTagHeader = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_HEADER, {
      ref_figma: '1',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_MODALITY,
      action_id: 'back',
      product: ETrackEvent.T_PRODUCT,
    })
    .catch(window.console.log)
}

export const handleTagModality = async ({
  restaurant,
  cooking,
  modality,
  plan_name = '',
  plan_value = '',
}: ModalityTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '18',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_MODALITY,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'forma de usar',

      restaurant,
      modality,
      cooking,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagClose = async ({ plan_name = '', plan_value = '' }: SubscriberTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '19',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_BS_PRESENTIAL,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'fechar',

      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagBSUseBenefit = async ({
  restaurant,
  plan_name = '',
  plan_value = '',
}: RestaurantSubTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '20',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_BS_PRESENTIAL,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'usar meu benefício',

      restaurant,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}
