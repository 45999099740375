import { useEffect, useState } from 'react'

import queryString from 'query-string'
import LocationBt from 'components/LocationBt/LocationBt'
import Divider from 'components/Divider'
import { useBSState } from 'contexts/FiltersBottomsheetContext'
import { IWbUserLocation } from 'hooks/useUserLocation'
import { useFilters } from 'contexts/FiltersContext'
import { useConfigs } from 'contexts/ConfigsContext'
import useAsync from 'hooks/UseAsync'
import LocationFilter from 'features/Home/HomeLocationFilter/LocationFilter'
import Error from 'components/Error'
import HomeAnnouncements from 'features/Home/HomeAnnouncements/HomeAnnouncements'
import { ETrackEvent } from 'enums/ETrackEvent'
import ExperiencesCarousel from 'features/Experiences/ExperiencesCarousel'
import { HomeExperiencesResponse } from 'types/Home'
import { ECurrentMainTab } from 'enums/EContexts'
import SubscribeBt from 'features/Home/HomeButtons/SubscribeBt'

import InfoIcon from '@interco/icons/orangeds/XL/info'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { useNavigate } from '@reach/router'

import { TabsProps } from '../Home.types'
import * as S from './ExperiencesTab.styles'
import RecommendationsTabShimmer from '../RecommendationsTab/RecommendationsTabShimmer'

const ExperiencesTab = ({ userLocation }: TabsProps) => {
  const { configs } = useConfigs()
  const { queryParam, setQueryParam } = useFilters()
  const { bsStateParam, setBsStateParam } = useBSState()
  const navigate = useNavigate()

  const [experiencesResponse, getExperiencesResponse] = useAsync<HomeExperiencesResponse>('get')
  const [emptyResponse, setEmptyResponse] = useState<boolean>(false)
  const [citySelection, setCitySelection] = useState<string>(
    queryParam.city || userLocation.city || '',
  )

  const getSelectedCity = (response: HomeExperiencesResponse = experiencesResponse.data) =>
    response?.filters?.states.find((state) => state.selected)?.cities.find((c) => c.selected)?.label

  const getSelectedUF = () =>
    experiencesResponse?.data?.filters?.states.find((state) => state.selected)?.value

  const handleLocationBt = () => {
    setBsStateParam({ ...bsStateParam, showBottomSheetLocation: true })
  }

  const onClickExploreMore = () => {
    navigate(`/home/${ECurrentMainTab.home}`)
  }

  const fetchExperiencesResponse = async (city: string = citySelection) => {
    const queryToGet = { city, latitude: '', longitude: '', currentCity: userLocation?.city }
    if (userLocation?.latitude) {
      queryToGet.latitude = userLocation.latitude
      queryToGet.longitude = userLocation.longitude
    }
    const res = (await getExperiencesResponse(
      `${process.env.REACT_APP_API}/home/experiences?${queryString.stringify(queryToGet)}`,
    ).catch(window.console.log)) as HomeExperiencesResponse

    const newCity = getSelectedCity(res)

    setCitySelection(newCity ?? '')
    setEmptyResponse(res.carousels.length === 0)

    const newQueryParam = {
      ...queryParam,
      city: newCity,
      name: '',
      cookings: [],
      districts: [],
      days: [],
      mealTimes: [],
      orderMethod: [],
      price: [],
      favorites: false,
      newRestaurant: false,
    }

    if (userLocation?.latitude && !queryParam?.latitude) {
      newQueryParam.latitude = userLocation.latitude
      newQueryParam.longitude = userLocation.longitude
    }
    setQueryParam(newQueryParam)
  }

  const formatQueryParam = (currentLocation?: IWbUserLocation) => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    const newQueryParams = {
      ...queryParam,
    }

    if (currentLocation) {
      newQueryParams.latitude = currentLocation?.latitude
      newQueryParams.longitude = currentLocation?.longitude
      newQueryParams.city = currentLocation?.city
    }

    if (params && params.city) {
      newQueryParams.city = params.city
    }

    return newQueryParams
  }

  useEffect(() => {
    async function fetchData() {
      try {
        let newQueryParams = {}
        const hasLocationPermission = configs.permissionLocation.value

        if (!hasLocationPermission || queryParam?.latitude || queryParam?.city) {
          newQueryParams = { ...queryParam }
        } else {
          newQueryParams = formatQueryParam({ ...userLocation })
        }
        setQueryParam({ ...queryParam, ...newQueryParams })
      } catch (error) {
        window.console.error(error)
      }
    }

    fetchData()
    fetchExperiencesResponse()
  }, [])

  if (experiencesResponse.isError) {
    return (
      <S.Wrapper>
        <Error goBackNative className="errorStyle" />
      </S.Wrapper>
    )
  }

  if (experiencesResponse.isSuccess) {
    return (
      <S.Wrapper>
        <S.Header>
          <LocationBt
            locationLabel={`${getSelectedCity()} / ${getSelectedUF()}`}
            onClickHandler={handleLocationBt}
          />
          <LocationFilter
            statesAndCities={experiencesResponse?.data?.filters?.states}
            actualState={getSelectedUF() ?? ''}
            fetchHomeResponse={fetchExperiencesResponse}
          />
          <Divider color="var(--gray200)" className="dividerStyle" />
        </S.Header>
        {!emptyResponse && (
          <S.Container duoGourmetInterCustomer={experiencesResponse.data?.duoGourmetInterCustomer}>
            {experiencesResponse.data?.topBanners.length > 0 && (
              <>
                <S.Content>
                  <HomeAnnouncements
                    banners={experiencesResponse.data?.topBanners}
                    canSubscribe={experiencesResponse.data?.canSubscribe}
                    pessoaFisica={experiencesResponse.data?.pessoaFisica}
                    className="topAnnouncementsStyle"
                    bannerTag={{
                      ref_figma: '5',
                      flow: ETrackEvent.F_HOME_SUB,
                      screen: ETrackEvent.S_HOME_SUB,
                      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
                    }}
                  />
                </S.Content>
                <Divider color="var(--gray100)" borderWidth={8} className="dividerStyle" />
              </>
            )}
            <S.CarouselList>
              {experiencesResponse.data?.carousels.map((carousel, index) => (
                <ExperiencesCarousel
                  carousel={carousel}
                  duoGourmetInterCustomer={experiencesResponse?.data?.duoGourmetInterCustomer}
                  city={queryParam.city}
                  key={`${carousel.title}-${index.toString()}`}
                />
              ))}
            </S.CarouselList>
            <Divider color="var(--gray100)" borderWidth={8} className="dividerStyle" />
            {experiencesResponse.data?.bottomBanners.length > 0 && (
              <S.Content>
                <HomeAnnouncements
                  banners={experiencesResponse.data?.bottomBanners}
                  canSubscribe={experiencesResponse.data?.canSubscribe}
                  pessoaFisica={experiencesResponse.data?.pessoaFisica}
                  className="bottomAnnouncementsStyle"
                  bannerTag={{
                    ref_figma: '5',
                    flow: ETrackEvent.F_HOME_SUB,
                    screen: ETrackEvent.S_HOME_SUB,
                    subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
                  }}
                />
              </S.Content>
            )}
          </S.Container>
        )}
        {emptyResponse && (
          <S.EmptyResponse>
            <S.IconStyle>
              <InfoIcon width={40} height={40} color="var(--gray500)" />
            </S.IconStyle>
            <Text variant="headline-h1" semiBold>
              Nenhuma experiência disponível
            </Text>
            <Text variant="body-3">
              Não encontramos nenhuma experiência disponível na cidade selecionada. Tente outra
              cidade ou explore outras possibilidades que o Duo Gourmet pode te oferecer!
            </Text>
            <Button
              variant="primary"
              onClick={onClickExploreMore}
              style={{ width: 'calc(100% - 3rem)', position: 'fixed', bottom: 20, zIndex: 1 }}
            >
              Explorar mais
            </Button>
          </S.EmptyResponse>
        )}

        {!experiencesResponse.data?.duoGourmetInterCustomer && (
          <S.SubscribeContainer>
            <Divider color="var(--gray100)" style={{ margin: '0' }} />
            <SubscribeBt
              banners={experiencesResponse.data?.topBanners}
              canSubscribe={experiencesResponse.data?.canSubscribe}
              pessoaFisica={experiencesResponse.data?.pessoaFisica}
              subscriptionButton={experiencesResponse.data?.subscriptionButton}
            />
          </S.SubscribeContainer>
        )}
      </S.Wrapper>
    )
  }

  return (
    <S.Wrapper>
      <RecommendationsTabShimmer />
    </S.Wrapper>
  )
}

export default ExperiencesTab
