import React from 'react'

import { AppContainer } from 'App.styles'
import ImgSuccess from 'assets/imgs/blackcard/success.svg'
import ImgError from 'assets/imgs/blackcard/error.svg'
import AppBar from 'components/AppBar'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { useNavigate } from '@reach/router'

import { ButtonContent, ImgContent, TextContent } from './ReactivateSubscription.styles'
import * as T from './ReactivateSubscription.tags'

interface ReactivationProps {
  requestResponse: boolean
  setReactivateResponsePage: (showPage: boolean) => void
}

const ReactivateSubscription = ({
  requestResponse,
  setReactivateResponsePage,
}: ReactivationProps) => {
  const navigate = useNavigate()

  if (requestResponse) {
    T.handleTagSuccess()
    return (
      <>
        <AppBar name=" Assinatura e pagamento" goBackNative />
        <AppContainer>
          <ImgContent>
            <img src={ImgSuccess} alt="" />
          </ImgContent>
          <TextContent>
            <Text variant="headline-h1" semiBold style={{ marginBottom: 10 }}>
              Assinatura reativada
            </Text>
            <Text variant="body-3">
              Aproveite os benefícios da sua assinatura e economize nos melhores restaurantes.
            </Text>
          </TextContent>
        </AppContainer>
        <ButtonContent>
          <Button
            style={{ width: '100%' }}
            onClick={() => {
              T.handleTagHomeBt()
              navigate('/')
            }}
          >
            Voltar para a Home
          </Button>
        </ButtonContent>
      </>
    )
  }

  T.handleTagError()
  return (
    <>
      <AppBar name="Duo Gourmet" goBackNative />
      <AppContainer>
        <ImgContent>
          <img src={ImgError} alt="" />
        </ImgContent>
        <TextContent>
          <Text variant="headline-h1" semiBold style={{ marginBottom: 10 }}>
            Ocorreu um erro na renovação
          </Text>
          <Text variant="body-3">
            Ocorreu um erro na renovação da sua assinatura. Tente novamente mais tarde.
          </Text>
        </TextContent>
      </AppContainer>
      <ButtonContent>
        <Button
          style={{ width: '100%' }}
          onClick={() => {
            T.handleTagSubcription()
            setReactivateResponsePage(false)
          }}
        >
          Ir para minha assinatura
        </Button>
      </ButtonContent>
    </>
  )
}

export default ReactivateSubscription
