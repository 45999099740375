import React, { useEffect, useState } from 'react'

import { BottomSheet } from 'components/BottomSheet'
import { useFilters } from 'contexts/FiltersContext'
import { useBSState } from 'contexts/FiltersBottomsheetContext'
import { useConfigs } from 'contexts/ConfigsContext'

import { Text } from '@interco/inter-ui/components/Text'
import { Alert } from '@interco/inter-ui/components/Alert'
import { Button } from '@interco/inter-ui/components/Button'

import { LocationFilterLocation, StateCitiesProps } from './LocationFilter.types'
import { FilterItem, AlertStyle } from '../RestaurantsFilters.styles'
import { handleTagApply, handleTagChangeState, handleTagMap } from './LocationFilterTags'
import StateFilter from './StateFilter'

export const LocationFilter = ({
  statesAndCities,
  fetchRestaurants,
  setSearchRestaurantsText,
  setLoadingShimmerHeader,
  actualState,
}: LocationFilterLocation) => {
  const { queryParam, setQueryParam } = useFilters()
  const { bsStateParam, setBsStateParam } = useBSState()
  const { configs } = useConfigs()

  const [tempState, setTempState] = useState(queryParam.state)

  const [selectedState, setSelectedState] = useState<StateCitiesProps>({
    label: '',
    selected: false,
    value: '',
    cities: [],
  })

  const getSelectedCity = () =>
    selectedState.cities.find((city) => city.selected) ?? {
      label: '',
      selected: false,
      value: '',
    }

  const getSelectedState = (newState?: string) => {
    let stateObject = statesAndCities.filter((state) => state.value === newState)
    if (stateObject && stateObject.length === 0) {
      stateObject = statesAndCities.filter((state) => state.selected)
    }
    if (stateObject[0].cities.length === 1 && !stateObject[0].cities[0].selected) {
      stateObject[0].cities[0].selected = true
      onFilter(stateObject[0].cities[0].value)
    }
    setSelectedState(stateObject[0])
  }

  const onFilter = async (newCity?: string) => {
    setBsStateParam({
      ...bsStateParam,
      showBottomSheetLocation: false,
      showBottomSheetState: false,
    })
    setSearchRestaurantsText('')
    setLoadingShimmerHeader(true)
    fetchRestaurants({
      ...queryParam,
      name: '',
      city: newCity || selectedState.cities?.filter((d) => d.selected)[0]?.value,
      cookings: [],
      districts: [],
      days: [],
      mealTimes: [],
      orderMethod: [],
      price: [],
      favorites: false,
      newRestaurant: false,
    })
  }

  const onDismiss = () => {
    getSelectedState(actualState)
    setTempState(actualState)
    setQueryParam({ ...queryParam, state: actualState })
    setBsStateParam({
      ...bsStateParam,
      showBottomSheetLocation: false,
      showBottomSheetState: false,
    })
  }

  const handleChange = (index: number) => {
    const list = selectedState.cities.map((d, idx) => ({ ...d, selected: idx === index }))
    setSelectedState({ ...selectedState, cities: list })
  }

  const handleChangeState = () => {
    handleTagChangeState({
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    }).catch(window.console.error)
    setBsStateParam({ ...bsStateParam, showBottomSheetLocation: false, showBottomSheetState: true })
  }

  const handleSubmit = async () => {
    handleTagApply({
      content_state: selectedState.label,
      content_city: getSelectedCity().label,
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    }).catch(window.console.error)
    onFilter()
  }

  const handleGoMap = () => {
    setBsStateParam({ ...bsStateParam, showBottomSheetState: false })
    handleTagMap({
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    })
    const newCity = selectedState.cities?.filter((d) => d.selected)[0]?.value
    fetchRestaurants({ ...queryParam, city: newCity })
    setBsStateParam({ ...bsStateParam, showBottomSheetLocation: false, showMapFilter: true })
  }

  useEffect(() => {
    getSelectedState(tempState)
  }, [tempState])

  const bsContent = (
    <>
      {selectedState?.cities && selectedState?.cities.length > 0 && (
        <div style={{ marginBottom: 16 }}>
          {selectedState?.cities.map((city, index) => (
            <FilterItem key={city.label}>
              <input
                className="input"
                type="checkbox"
                id={city.label}
                name={city.label}
                onChange={() => handleChange(index)}
                checked={city.selected}
              />
              <label className="label" htmlFor={city.label}>
                {city.label}
              </label>
            </FilterItem>
          ))}
        </div>
      )}

      <AlertStyle style={{ marginBottom: 16 }}>
        <Alert type="grayscale">
          <Text variant="caption-1">
            Ao trocar de cidade os filtros selecionados anteriormente serão redefinidos.
          </Text>
        </Alert>
      </AlertStyle>
      <Button
        variant="primary"
        style={{ width: '100%', marginBottom: '16px' }}
        onClick={handleSubmit}
        disabled={!getSelectedCity()}
      >
        Aplicar
      </Button>
      <Button
        variant="secondary"
        style={{ width: '100%' }}
        onClick={handleGoMap}
        disabled={!getSelectedCity()}
      >
        Abrir mapa
      </Button>
    </>
  )

  return (
    <>
      <BottomSheet
        showBottomSheet={bsStateParam?.showBottomSheetLocation}
        onDismiss={onDismiss}
        headerLabel={selectedState?.label}
        headerBtLabel="Trocar estado"
        headerBtHandler={handleChangeState}
        bsContent={bsContent}
      />
      <StateFilter
        fetchRestaurants={fetchRestaurants}
        statesAndCities={statesAndCities}
        actualState={actualState}
        tempState={tempState ?? ''}
        setTempState={setTempState}
        setSearchRestaurantsText={setSearchRestaurantsText}
        setLoadingShimmerHeader={setLoadingShimmerHeader}
      />
    </>
  )
}

export default LocationFilter
