import { Coordinates } from 'types/Coordinates'
import { FilterRestaurants } from 'types/Filters'

export interface Restaurant {
  id: string
  coordinates: Coordinates
}

export interface MapApiResponse {
  restaurants: Restaurant[]
  filters: FilterRestaurants
  center: Coordinates
}

export interface MapFilterProps {
  clearFilters: () => void
}

export enum WEEKDAY {
  dom = 0,
  seg = 1,
  ter = 2,
  qua = 3,
  qui = 4,
  sex = 5,
  sab = 6,
}
