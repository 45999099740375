import React from 'react'

import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Text } from '@interco/inter-ui/components/Text'
import { useNavigate } from '@reach/router'

import { CardProps, RecommendationListProps } from './RecommendationList.types'
import * as S from './RecommendationList.styles'

export const RecommendationList = ({ recommendations }: RecommendationListProps) => {
  const navigate = useNavigate()

  const handleCardClick = ({ name, id }: CardProps) => {
    navigate(`/restaurants/${id}`, { state: { name } }).catch(window.console.log)
  }

  return (
    <S.Wrapper style={{ paddingTop: '32px' }}>
      <Text variant="headline-h3" semiBold>
        {recommendations.title}
      </Text>
      {recommendations.restaurants.map((rest, idx) => (
        <S.Card
          key={`rest-${idx.toString()}`}
          onClick={() => handleCardClick({ name: rest.name, id: rest.id })}
        >
          <LazyLoadImage effect="blur" src={rest.imageCoverUrl} className="imageStyle" />
          <Text variant="caption-1" bold colorWeight={500}>
            {rest.name}
          </Text>
          <Text variant="caption-2">{rest.cooking}</Text>
        </S.Card>
      ))}
    </S.Wrapper>
  )
}

export default RecommendationList
