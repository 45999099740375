import styled from 'styled-components'

export const TabContent = styled.div`
  border: 1px solid var(--gray200);
  border-radius: 0.5rem;

  label {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }
`

export const MainContainer = styled.div`
  margin-top: 0.5rem;

  div[role='tabpanel'] {
    padding: 1rem 0 0 0;
  }
`

export const ScheduleContainer = styled.div`
  border: 1px solid var(--gray200);
  border-radius: 0.5rem;
  --accordion-container-background-color: none;

  padding: 0.5rem;
  margin: 0.7rem 0 0;
`

export const MealTimeItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
