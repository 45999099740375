import { AppContainer } from 'App.styles'
import styled from 'styled-components'

export const UtilizationStyles = styled(AppContainer)`
  .text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 0;
  }
  .button {
    width: 100%;
    margin-top: 16px;
  }
  .mt-24 {
    margin-top: 24px;
  }

  .no-padding-left {
    padding-left: 0;
  }

  .big-money {
    color: var(--success500);
  }
  .container-price {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 25px;

    .container-price-money {
      color: var(--success500);
      font-weight: 600;
    }
  }

  .infinite-scroll-component > div:first-child > div {
    position: relative !important;
  }

  .pullDown-content {
    margin-bottom: 25px;
  }
`
