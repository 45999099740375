import { ETrackEvent } from 'enums/ETrackEvent'

import { interWbSession } from '@interco/inter-webview-bridge'

export const handleTagReactivateBt = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '1',
      flow: ETrackEvent.F_SIG_REACTIVATION,
      screen: ETrackEvent.S_SIGNATURE_PAYMENT,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'reativar assinatura',
    })
    .catch(window.console.log)
}

export const handleTagBSBt = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '2',
      flow: ETrackEvent.F_SIG_REACTIVATION,
      screen: ETrackEvent.S_SIGNATURE_PAYMENT,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'continuar',
    })
    .catch(window.console.log)
}

export const handleTagClose = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '3',
      flow: ETrackEvent.F_SIG_REACTIVATION,
      screen: ETrackEvent.S_SIGNATURE_PAYMENT,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'fechar',
    })
    .catch(window.console.log)
}

export const handleTagHomeBt = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '4',
      flow: ETrackEvent.F_SIG_REACTIVATION,
      screen: ETrackEvent.S_SIG_REACTIVATED,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'voltar para a home',
    })
    .catch(window.console.log)
}

export const handleTagSubcription = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '5',
      flow: ETrackEvent.F_SIG_REACTIVATION,
      screen: ETrackEvent.S_ERROR,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'ir para minha assinatura',
    })
    .catch(window.console.log)
}

export const handleTagSuccess = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_SUCCESS, {
      ref_figma: '6',
      flow: ETrackEvent.F_SIG_REACTIVATION,
      screen: ETrackEvent.S_SIG_REACTIVATED,
      content_action: 'dado carregado',
      action_id: 'assinatura reativada',
    })
    .catch(window.console.log)
}

export const handleTagError = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_ACCESS, {
      ref_figma: '7',
      flow: ETrackEvent.F_SIG_REACTIVATION,
      screen: ETrackEvent.S_ERROR,
      content_action: 'dado carregado',
      action_id: 'ocorreu um erro na ativação',
    })
    .catch(window.console.log)
}
