import queryString from 'query-string'

/**
 *
 * @param params Record<string, unknown>
 * @returns 'sort=asc&order=name'
 * @description update queryParams from current url location
 */

export function updateUrlParams(params: Record<string, unknown>): string {
  const oldParams = queryString.parse(window.location.search)
  const newParams = { ...oldParams, ...params }
  return queryString.stringify(newParams)
}

/**
 *
 * @param params string
 * @returns string
 * @description get only digits in the text
 */
export const onlyDigits = (value: string): string => value.replace(/[^\d]/g, '')

/**
 *
 * @param textValue string
 * @returns string
 * @description replace all when is not number, dot and comma with empty.
 * @example
 *  let inputText = '11,22ab'
 *  numeric(inputText) // '11,22'
 *  inputText = '15.22abc'
 *  numeric(inputText) // '15.22'
 *
 */
export const numeric = (value: string): string => value.replace(/[^\d.,]/g, '')

/**
 *
 * @param value string | number
 * @returns string
 * @description will replace dot with comma
 * @example 14.5 = 15,5
 * @deprecated
 * @see formatMoney
 */
export const formatDecimal = (value?: string | number): string =>
  value == null ? '' : value.toString().replace('.', ',')

export const formatCashbackValue = (value: string | number) => value.toString().replace(',', '.')

/**
 *
 * @param money string | number
 * @returns string
 * @description format money
 * @example
 *  let value = '11.22'
 *  formatMoney(value) // 11,22
 *  value = '1000'
 *  formatMoney(value) // '1.000'
 *  value = '1000.33'
 *  formatMoney(value) // '1.000,33'
 */
export const formatMoney = (value: string | number) =>
  new Intl.NumberFormat('pt-BR').format(Number(value))

export function on<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T['addEventListener']> | [string, () => void | null, ...unknown[]]
): void {
  if (obj && obj.addEventListener) {
    obj.addEventListener(...(args as Parameters<HTMLElement['addEventListener']>))
  }
}

export function off<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T['removeEventListener']> | [string, () => void | null, ...unknown[]]
): void {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...(args as Parameters<HTMLElement['removeEventListener']>))
  }
}
