import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  margin: 1.5rem 0;

  .wrap-text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
`

export const CarouselHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 1.5rem;

  span {
    align-self: center;
  }

  button {
    padding: 0.1rem;
  }
`
export const CarouselScroll = styled.div`
  display: flex;
  padding: 1rem 0 1rem 1.2rem;
  overflow-x: auto;
  clip-path: inset(0 0 1rem 0);
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 0.6rem;
  width: 40%;

  .text-style {
    margin-bottom: 4px;
  }
`

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.2rem;

  span {
    display: flex;
    margin: 0;
  }
`

export const TagSpace = styled.div`
  margin: 0.1rem 0.3rem 0 0;
`
