import { Dispatch } from 'react'

import { FilterCategories } from 'pages/Filters'

import CookingsFilter from './CookingsFilter'

export interface CookingsFilterProps {
  filters: FilterCategories
  setFilters: Dispatch<React.SetStateAction<FilterCategories>>
}

export default CookingsFilter
