import { AppContainer } from 'App.styles'
import styled from 'styled-components'

export const CancelSubscriptionStyles = styled(AppContainer)`
  .big-money {
    color: var(--success500);
  }
  .button {
    width: 100%;
    margin: 8px 0px;
  }
  .btn-container {
    margin-top: 20px;
  }

  .container-price {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;

    .text {
      display: flex;
      align-items: center;
      padding: 2px 0;
    }

    .container-price-money {
      color: var(--success500);
      font-weight: 600;
    }
  }
`

export const Advantages = styled.div`
  display: flex;
  margin-top: 24px;
`

export const AdvantagesList = styled.div`
  margin-left: 17px;
  display: flex;
  flex-direction: column;
`
