import { ETrackEvent } from 'enums/ETrackEvent'

import { interWbSession } from '@interco/inter-webview-bridge'

export const handleTagRetryPaymentSuccess = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_SUCCESS, {
      ref_figma: '2',
      flow: ETrackEvent.F_PENDING_PAYMENT,
      screen: ETrackEvent.S_PENDING_PAYMENT_SUCCESS,
      content_action: 'dado carregado',
      action_id: 'assinatura reativada',
    })
    .catch(window.console.log)
}

export const handleTagBackHome = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '3',
      flow: ETrackEvent.F_PENDING_PAYMENT,
      screen: ETrackEvent.S_PENDING_PAYMENT_SUCCESS,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'processamento assinatura pendente',
    })
    .catch(window.console.log)
}

export const handleTagRetryPaymentError = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_ACCESS, {
      ref_figma: '4',
      flow: ETrackEvent.F_PENDING_PAYMENT,
      screen: ETrackEvent.S_PENDING_PAYMENT_ERROR,
      content_action: 'dado carregado',
      action_id: 'ocorreu um erro na ativação',
    })
    .catch(window.console.log)
}

export const handleTagRetryBt = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '5',
      flow: ETrackEvent.F_PENDING_PAYMENT,
      screen: ETrackEvent.S_PENDING_PAYMENT_ERROR,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'ocorreu um erro',
    })
    .catch(window.console.log)
}
