import * as React from 'react'

import { bridgeNative } from 'App'

import { interWbNavigate } from '@interco/inter-webview-bridge'
import { useNavigate } from '@reach/router'

interface GoBackProps {
  goBackNative?: boolean
}

const useGoBack = ({ goBackNative }: GoBackProps) => {
  const navigate = useNavigate()

  const goBack = React.useCallback(() => {
    if (goBackNative) {
      interWbNavigate.requestGoBack()
    } else if (window.history.length === 1) {
      navigate('/')
    } else {
      navigate(-1)
    }
  }, [goBackNative])

  React.useEffect(() => {
    bridgeNative.addWebviewEventListener('addUseGoBack', goBack)
    return () => {
      bridgeNative.removeWebviewEventListener('removeUseGoBack', goBack)
    }
  }, [goBack])

  return goBack
}

export default useGoBack
