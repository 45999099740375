import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  margin: 4rem 0;
  overflow: hidden;

  .titleStyle {
    position: fixed;
    background: var(--neutral-theme);
    z-index: 2;
    left: 0;
    top: 3rem;
    width: 100%;
    padding: 1rem 1.5rem;
  }
`

export const SelectionsList = styled.div`
  margin-top: 2rem;
`
