import { ETrackEvent } from 'enums/ETrackEvent'

import { interWbSession } from '@interco/inter-webview-bridge'

export const handleTagClose = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '1',
      flow: ETrackEvent.F_MAP_FILTER,
      screen: ETrackEvent.S_MAP_FILTER,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'fechar',
    })
    .catch(window.console.log)
}

interface MapTag {
  restaurant: string
  plan_name: string
  plan_value: string
}

export const handleTagCopy = async ({ restaurant, plan_name, plan_value }: MapTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '2',
      flow: ETrackEvent.F_MAP_FILTER,
      screen: ETrackEvent.S_MAP_FILTER,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'copiar endereço',

      restaurant,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagRoute = async ({ restaurant, plan_name, plan_value }: MapTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '3',
      flow: ETrackEvent.F_MAP_FILTER,
      screen: ETrackEvent.S_MAP_FILTER,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'definir rota',

      restaurant,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagRestaurant = async ({ restaurant, plan_name, plan_value }: MapTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '4',
      flow: ETrackEvent.F_MAP_FILTER,
      screen: ETrackEvent.S_MAP_FILTER,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'ir para página do restaurante',

      restaurant,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}
