import styled from 'styled-components'

export const HeaderStyled = styled.div`
  text-align: center;
  margin-bottom: 30px;
  .p {
    font-weight: 600;
  }
`
export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StarRatingStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StarsStyled = styled.div`
  width: 65%;
  padding: 15px;
  display: flex;
  justify-content: space-between;

  button {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
`

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CommentCounter = styled.span`
  color: var(--gray400);
  font-size: 14px;
  display: flex;
  align-self: flex-end;
`

export const ResponseWrapper = styled.div`
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ResponseHeader = styled.div``
export const ResponseContainer = styled.div``

export const ResponseImg = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
`

export const ResponseText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 2rem;
  gap: 1rem;
  text-align: center;
`

export const ResponseButtons = styled.div`
  position: absolute;
  bottom: 0;
  padding: 1.5rem;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  button {
    width: 100%;
  }
`
