import React from 'react'

import Divider from 'components/Divider'
import AppBar from 'components/AppBar'
import { ScheduleContainer } from 'features/Restaurants/OperationCalendar/OperationCalendar.styles'

import { Skeleton } from '@interco/inter-ui/components/Skeleton'

import { Container, RulesContent, HeaderContainer } from './RestaurantsDetails.styles'

interface RestaurantsDetailsShimmerProps {
  appBarName?: string
}

const RestaurantsDetailsShimmer = ({ appBarName }: RestaurantsDetailsShimmerProps) => (
  <>
    <AppBar name={appBarName} />
    <Skeleton width="100%" height="200px" />
    <HeaderContainer style={{ marginTop: 20 }}>
      <Skeleton width="60%" height="30px" style={{ margin: '0.5rem 0' }} />
      <Skeleton width="40%" height="15px" style={{ margin: '0.2rem 0' }} />
      <Skeleton width="40%" height="15px" style={{ margin: '0.2rem 0' }} />
      <Skeleton width="40%" height="15px" style={{ margin: '0.2rem 0' }} />
      <Skeleton width="100%" height="3rem" style={{ marginTop: 20 }} />
    </HeaderContainer>
    <Divider borderWidth={8} color="var(--gray100)" style={{ margin: '2rem 0' }} />
    <Container style={{ marginTop: 20 }}>
      <Skeleton width="100%" height="2.5rem" />
      <Skeleton width="70%" height="1.5rem" style={{ marginTop: '1rem' }} />
      <ScheduleContainer style={{ marginBottom: '-15px' }}>
        <Skeleton width="100%" height="2rem" style={{ margin: '-0.5rem 0 0 -5px' }} />
        {[...Array(7)].map((_, idx) => (
          <Skeleton
            key={`schedule-${idx.toString()}`}
            width="100%"
            height="1.2rem"
            style={{ margin: '1rem -5px -1rem' }}
          />
        ))}
      </ScheduleContainer>
    </Container>
    <Container style={{ margin: '2.5rem 0 -1.3rem 0' }}>
      <Skeleton width="100%" height="4rem" />
    </Container>
    <Container>
      <RulesContent style={{ padding: '1.5rem' }}>
        <Skeleton width="50%" height="1.5rem" style={{ margin: '-0.5rem 0 0 -5px' }} />
        {[...Array(7)].map((_, idx) => (
          <Skeleton
            key={`schedule-${idx.toString()}`}
            width="100%"
            height="1.2rem"
            style={{ margin: '1rem -5px -1rem' }}
          />
        ))}
      </RulesContent>
    </Container>
  </>
)

export default RestaurantsDetailsShimmer
