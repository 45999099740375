export enum WEEK {
  seg = 'Segunda',
  ter = 'Terça',
  qua = 'Quarta',
  qui = 'Quinta',
  sex = 'Sexta',
  sab = 'Sábado',
  dom = 'Domingo',
}

export interface ScheduleLineProps {
  dayOfWeek: string
  lunchSchedule: string
  dinnerSchedule: string
  isToday?: boolean
}

export interface ScheduleChartProps {
  modality: string
  schedule: ScheduleLineProps[]
}
