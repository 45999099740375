import styled from 'styled-components'

export const Container = styled.div`
  padding: 16px 24px 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .success-img {
    width: 100%;
    margin: 40px 0 20px 0;
  }

  .description-text {
    padding: 0.5rem 0.5rem;
  }
`
export const AlertWrapper = styled.div`
  margin: 1rem 0 0;
`

export const BenefitInfo = styled.div`
  border: 1px solid var(--gray200);
  border-radius: 0.5rem;
  width: 100%;
  margin: 1rem;
  display: flex;
  flex-direction: column;
`

export const BenefitCode = styled.div`
  align-self: center;
  padding: 1rem 0 0;
`

export const CopyCode = styled.div`
  padding: 1rem 1rem 0;
  column-gap: 0.3rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 1rem;

  .item-icon {
    margin-right: 1rem;
  }
`
export const AlertStyle = styled.div`
  margin: 1rem 0 0;
`

export const SuccessImg = styled.div`
  margin: 0.5rem 0 0.2rem;
`
