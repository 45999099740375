import styled from 'styled-components'

// COVER STYLES
export const SliderTheme = styled.div`
  .slick-slider .slick-dots {
    text-align: left;
    padding: 0 24px;
  }

  .slick-slide div {
    width: 100%;
  }
`
export const CoverImage = styled.img`
  height: 210px;
  object-fit: cover;
  margin-top: 55px;
  width: 100%;
`
// HEADER STYLE
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  overflow: hidden;

  span {
    padding: 0 1.5rem;
  }

  .divider {
    margin: 0 0 0.5rem;
    width: 90%;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .title {
    align-self: flex-start;
  }
`

export const TopIconContainer = styled.div`
  display: flex;
  margin: 0 1.5rem 0 0.5rem;

  button {
    margin-left: 1rem;
    background-color: transparent;
    border: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
`

export const TagsContainer = styled.div`
  width: 100%;
  padding: 0 1.2rem;
  margin-top: -1rem;

  span {
    display: flex;
    padding: 0 0.2rem;
  }
`

export const TagSpace = styled.div`
  margin: 0.1rem 0.3rem 0 0;
`

export const OneTimeSchedule = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 0 1.5rem;

  span {
    padding: 0;
  }
`

export const BenefitAlert = styled.div`
  padding: 0 1.5rem;
  width: 100%;

  span {
    padding: 0;
  }
`

// BODY STYLE
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  margin: 1rem 0 0 0;

  .divider {
    margin: 1.5rem 0;
    width: 100%;
  }
`

export const AlertWrapper = styled.div`
  margin: 0.5rem 0;
`

export const RulesContent = styled.div`
  margin-bottom: 1.5rem;
  border: 1px solid var(--gray200);
  border-radius: 0.5rem;
  --accordion-container-background-color: none;

  > div:first-child {
    padding: 8px;
  }
`

export const Footer = styled.div`
  padding-bottom: 4rem;
`

// BOTTOMSHEET STYLE
export const BSWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const BSCouponCode = styled.div`
  background: var(--gray100);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;
`
export const CopyCode = styled.div`
  display: flex;
  margin: 0.3rem;
  column-gap: 0.3rem;
`

// SHIMMER STYLE
export const ShimmerHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  margin: 1rem 0 0;
  gap: 1rem;
`

export const ShimmerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  margin: 1rem 0 0;
  gap: 0.5rem;
`

export const ShimmerSchedule = styled.div`
  border: 1px solid var(--gray200);
  border-radius: 0.5rem;
  --accordion-container-background-color: none;

  padding: 0.5rem;
  margin: 0.7rem 0;

  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`
