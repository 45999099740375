import { ETrackEvent } from 'enums/ETrackEvent'
import { SearchTag, SubscriberTag } from 'types/AnalyticsTag'

import { interWbSession } from '@interco/inter-webview-bridge'

export const handleTagChangeState = async ({ plan_name = '', plan_value = '' }: SubscriberTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '1',
      flow: ETrackEvent.F_CHANGE_STATE_CITY,
      screen: ETrackEvent.S_CHANGE_STATE_CITY,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'trocar estado',

      plan_name,
      plan_value,
    })
    .catch(window.console.error)
}

export const handleTagSearch = async ({ search_term }: SearchTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_SEARCH_TERM, {
      ref_figma: '5',
      flow: ETrackEvent.F_CHANGE_STATE_CITY,
      screen: ETrackEvent.S_CITY_FILTER,
      search_term,
      product: ETrackEvent.T_PRODUCT,
    })
    .catch(window.console.log)
}

interface StateTag extends SubscriberTag {
  content_state: string
}

export const handleTagState = async ({
  content_state,
  plan_name = '',
  plan_value = '',
}: StateTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '6',
      flow: ETrackEvent.F_CHANGE_STATE_CITY,
      screen: ETrackEvent.S_CITY_FILTER,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'estados',

      content_state,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}
