import React from 'react'

import { Text } from '@interco/inter-ui/components/Text'
import ChrevronRightIcon from '@interco/icons/orangeds/SM/chevron-right'

import { ListItemContainer, ListItemIconStyles, ListItemStyles } from './ListItem.styles'

export interface ListItemProps extends React.HTMLAttributes<unknown> {
  icon?: React.SVGAttributes<SVGSVGElement>
  arrow?: boolean
  label: string
  disabled?: boolean
}

const ListItem: React.FC<ListItemProps> = ({
  label,
  icon,
  arrow,
  disabled,
  ...props
}: ListItemProps) => (
  <ListItemStyles
    {...props}
    data-testid="list-item"
    onClick={(e) => {
      if (!disabled) props.onClick && props.onClick(e)
    }}
  >
    <ListItemContainer>
      {icon && (
        <ListItemIconStyles>
          {React.cloneElement(icon as React.ReactElement, {
            stroke: disabled ? 'var(--gray300)' : undefined,
            color: disabled ? 'var(--gray300)' : 'var(--gray500)',
          })}
        </ListItemIconStyles>
      )}
      <Text variant="caption-1" colorWeight={disabled ? 300 : 500} bold>
        {label}
      </Text>
    </ListItemContainer>
    {arrow && (
      <ChrevronRightIcon
        style={{ float: 'right' }}
        height={24}
        width={24}
        color={disabled ? 'var(--gray300)' : 'var(--primary500)'}
      />
    )}
  </ListItemStyles>
)

export default ListItem
