import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;

  .errorStyle {
    padding: 0;
    height: 90vh;
    margin-top: -5rem;
  }
`

export const Content = styled.div`
  margin-top: 4rem;
  width: 100%;
  padding: 0 1.5rem;

  div[role='tabpanel'] {
    padding: 1rem 0 0 0;
  }
`

export const EmptyResponse = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  text-align: center;
`

export const IconStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gray100);
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
`

export const Footer = styled.div`
  background: var(--neutral-theme);
  padding: 0 1.5rem;
  bottom: 0;
  position: fixed;
  width: 100%;
  border-top: 1px solid var(--neutral-theme);
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: var(--neutral-theme);
  }
`
