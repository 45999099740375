import React, { useEffect, useRef, useState } from 'react'

import useAsync from 'hooks/UseAsync'
import { ApiResponsePagination, PageResponse } from 'types/http/ApiResponse'
import queryString from 'query-string'
import { ExperienceUseEvents, ExperienceRecordsResponse } from 'types/Experiences'
import InfiniteScroll from 'react-infinite-scroll-component'
import Divider from 'components/Divider'
import RecordCard from 'features/Experiences/RecordCard/RecordCard'
import Error from 'components/Error'

import InfoIcon from '@interco/icons/bidis/v2/action/info-2'
import ArrowUpIcon from '@interco/icons/orangeds/XL/arrow-up'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './ExperiencesRecords.styles'

const EndedRecords = () => {
  const [experiences, getExperiences] =
    useAsync<ApiResponsePagination<ExperienceRecordsResponse>>('get')

  const [, setLoadingShimmer] = useState<boolean>(false)
  const [scrollId, setScrollId] = useState<string>('')
  const [showBackToTop, setShowBackToTop] = useState<boolean>(false)
  const [emptyResponse, setEmptyResponse] = useState<boolean>(true)

  const [use, setUse] = useState<ExperienceUseEvents[]>([])
  const [queryParam] = useState<PageResponse>({
    offset: 0,
    size: 10,
  } as PageResponse)

  const useTotal = useRef(0)

  const fetchRecords = async () => {
    useTotal.current = 0
    setLoadingShimmer(true)
    try {
      const res = await getExperiences(
        `${process.env.REACT_APP_API}/useEvents/experiences?${queryString.stringify({
          ...queryParam,
        })}`,
      )
      setUse(res.finalizedUseEvents)
      setEmptyResponse(res.finalizedUseEvents.length === 0)
      setScrollId(res.page.scrollId)
      setLoadingShimmer(false)
      useTotal.current += use.length
    } catch (error) {
      window.console.log(error)
    }
  }

  const ref = useRef(0)

  const next = React.useCallback(async () => {
    try {
      ref.current += queryParam.size
      const res = await getExperiences(
        `${process.env.REACT_APP_API}/useEvents/experiences?${queryString.stringify({
          ...queryParam,
          scrollId,
        })}`,
      )
      setUse((d) => [...d, ...res.finalizedUseEvents])
      setScrollId(res.page.scrollId)
      useTotal.current += use.length
    } catch (error) {
      window.console.log(error)
    }
  }, [getExperiences, queryParam, scrollId])

  const BackTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    window.onscroll = () => {
      setShowBackToTop(window.scrollY > 100)
    }
    fetchRecords()
  }, [])

  if (experiences.isError) {
    return (
      <S.Wrapper>
        <Error goBackNative className="errorStyle" />
      </S.Wrapper>
    )
  }

  if (experiences.isSuccess) {
    return (
      <S.Wrapper>
        {emptyResponse && (
          <S.EmptyResponse>
            <S.IconStyle>
              <InfoIcon width={40} height={40} color="var(--gray500)" />
            </S.IconStyle>
            <Text variant="headline-h1" semiBold>
              Sem experiências por aqui
            </Text>
            <Text variant="body-3">
              Você ainda não aproveitou nenhuma experiência com o Duo Gourmet. Conheça experiências
              incríveis com o Duo Gourmet com benefícios exclusivos.
            </Text>
          </S.EmptyResponse>
        )}
        {use.length > 0 && (
          <InfiniteScroll
            dataLength={use?.length}
            next={next}
            hasMore={!!experiences?.data?.page?.scrollId}
            loader={<h4 style={{ marginTop: 10 }}>Carregando...</h4>}
          >
            {use?.map((d, idx) => (
              <div key={`${d.code}-${d.experienceId}-${idx + 0}`}>
                <RecordCard experienceEvent={d} externalEvent={!!d?.externalLink} />
                <Divider type="dashed" />
              </div>
            ))}
          </InfiniteScroll>
        )}
        <S.Footer style={{ background: 'transparent', border: 'none', paddingRight: '4.5rem' }}>
          <Button
            icon={<ArrowUpIcon width={14} height={14} color="var(--neutral-theme)" />}
            onClick={BackTop}
            size="small"
            style={{ margin: '1rem 0' }}
            className={
              showBackToTop
                ? 'animate__animated animate__bounceInUp'
                : 'animate__animated animate__backOutDown'
            }
          >
            Voltar ao topo
          </Button>
        </S.Footer>
      </S.Wrapper>
    )
  }

  return null
}

export default EndedRecords
