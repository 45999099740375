import * as React from 'react'

/**
 * useSafeDispatch hook
 *
 * This is a safer version of the dispatch function. It checks if the
 * component is still mounted before updating the state. This is required to
 * avoid getting the warning: "Can't perform a React state update on an
 * unmounted component".
 *
 * @param dispatch - the dispatch function returned by React.useReducer
 *
 * @returns - a safer version of the dispatch function
 *
 */
function useSafeDispatch<T = unknown>(dispatch: React.Dispatch<T>): React.Dispatch<T> {
  const mountedRef = React.useRef(false)

  React.useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  return React.useCallback(
    (args) => {
      if (mountedRef.current) {
        dispatch(args)
      }
    },
    [dispatch],
  )
}

export default useSafeDispatch
