import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .divider-style {
    margin: 1.5rem 0;
  }
`

export const RestaurantInfo = styled.div`
  margin: 5rem 2rem 0 2rem;
`

export const TagsContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  margin-top: 7px;
  flex-wrap: wrap;

  span {
    display: flex;
    margin: 0;
  }
`

export const TagSpace = styled.div`
  margin: 0.1rem 0.3rem 0 0;
`

export const RestaurantDetailsContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  .icon-style {
    margin-right: 0.5rem;
  }

  .dot-style {
    color: var(--gray200);
    marginleft: 3px;
    marginright: 3px;
  }
`

export const LocationContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const ModalityInfo = styled.div`
  margin: 0 2rem;

  .info-title {
    margin-bottom: 2rem;
  }

  .use-alert {
    margin: 1.5rem 0;
  }
`

export const ModalityItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  margin: 1.5rem 0;
  align-items: center;
`
export const ModalityIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary100);
  border-radius: 20%;
  width: 4rem;
  height: 4rem;
`

export const ModalityLabel = styled.div`
  align-self: center;
  width: 75%;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;

  .modality-title {
    margin-bottom: 0.5rem;
  }

  .modality-bt {
    justify-content: flex-start;
    padding: 0.5rem 0;
  }
`

export const ArrowStyle = styled.div`
  align-self: center;
`
