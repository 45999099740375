import React from 'react'

import useGoBack from 'hooks/useGoBack'

import { interWbNavigate } from '@interco/inter-webview-bridge'
import MenuIcon from '@interco/icons/orangeds/XL/menu'
import ArrowLeftIcon from '@interco/icons/orangeds/LG/arrow-left'
import HomeIcon from '@interco/icons/orangeds/MD/home'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { useNavigate } from '@reach/router'

import { AppBarStyles } from './AppBar.styles'

interface AppBarProps {
  name?: string
  subscriber?: boolean
  showHomeAction?: boolean
  goBackNative?: boolean
  backTag?: () => void
  homeTag?: () => void
}

const AppBar = ({
  subscriber,
  name,
  showHomeAction,
  goBackNative,
  backTag = () => undefined,
  homeTag = () => undefined,
}: AppBarProps) => {
  const navigate = useNavigate()

  const goBack = useGoBack({ goBackNative })

  const iconProps = { width: 30, height: 30, color: 'var(--primary500)', className: 'iconStyle' }

  return (
    <AppBarStyles>
      <Button
        icon={<ArrowLeftIcon {...iconProps} />}
        variant="link"
        onClick={async () => {
          backTag()
          goBack()
        }}
        className="iconStyle"
        style={{ padding: '12px 0 ' }}
      />
      <Text
        variant="headline-h3"
        className="appbar-title"
        semiBold
        style={{
          whiteSpace: 'nowrap',
          position: 'relative',
          left: !showHomeAction && !subscriber ? -15 : 0,
        }}
      >
        {name}
      </Text>
      {subscriber && (
        <Button
          icon={<MenuIcon {...iconProps} />}
          variant="link"
          data-testid="menu-button"
          onClick={() => {
            homeTag()
            navigate('/menu')
          }}
          style={{ padding: '12px 0 ' }}
        />
      )}
      {showHomeAction && (
        <Button
          icon={<HomeIcon {...iconProps} />}
          variant="link"
          data-testid="home-button"
          onClick={() => interWbNavigate.requestGoBack()}
          style={{ padding: '12px 0 ' }}
        />
      )}
      {!showHomeAction && !subscriber && <span />}
    </AppBarStyles>
  )
}

export default AppBar
