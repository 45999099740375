import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body, html, #root, .container-routers {
    height: 100%;
    background-color: var(--neutral-theme);
  }

  div[data-testid=snackBar-container-v2]{
    background-color: var(--gray500);
    flex-wrap: nowrap;

    > div span,
    > div p{
      color: var(--neutral-theme);
    }
  }

  .h-100 {
    height: 100%
  }
  .d-block {
    display: block
  }
  .bold {
    font-weight: bold;
  }
  .m-3 {
    margin: 3px;
  }
  .text-center {
    text-align: center;
  }
  .router {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: all .5s ease;
  }

  .fade-enter {
    opacity: 1;
    transform: translate3d(700px, 0, 0);
    transition: all .5s ease;
    width: 100%;
  }

  .fade-enter-active {
    opacity: 1;
    transition: all .5s ease;
    transform: translate3d(0, 0, 0);
    width: 100%;
  }

  .fade-exit {
    opacity: 1;
    transform: translate3d(700px, 0, 0);
    transition: all .5s ease;
    width: 100%;
  }

  .fade-exit-active {
    opacity: 0;
    transform: translate3d(-700px, 0, 0);
    transition: all .5s ease;
    width: 100%;
  }

  [data-rsbs-header] {
    background-color: var(--neutral-theme);
    border-radius: inherit;
  }
  
  [data-rsbs-footer] {
    background: var(--neutral-theme);
    box-shadow: unset;
  }

  [data-rsbs-scroll] {
    background: var(--neutral-theme);
  }

`

export const AppContainer = styled.div<{ fullHeight?: boolean; withAppBarHeight?: boolean }>`
  padding: 24px;
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};
`

export const SpaceBetween = styled.div<{
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
}>`
  ${({ direction }) => (direction === 'row' ? { width: '100%' } : { height: '100%' })}
  display: flex;
  flex-direction: ${({ direction = 'column' }) => direction};
  justify-content: space-between;
`
