import { ETrackEvent } from 'enums/ETrackEvent'
import { SubscriberTag } from 'types/AnalyticsTag'

import { interWbSession } from '@interco/inter-webview-bridge'

interface OnboardingStep extends SubscriberTag {
  screenStep: string
}

interface LoadTag extends SubscriberTag {
  action_id: string
  step: string
}

export const handleTagSkip = async ({
  screenStep,
  plan_name = '',
  plan_value = '',
}: OnboardingStep) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '1',
      flow: ETrackEvent.F_ONBOARDING,
      screen: `${ETrackEvent.F_ONBOARDING} passo ${screenStep}`,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'pular',

      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagNext = async ({ plan_name = '', plan_value = '' }: SubscriberTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '2',
      flow: ETrackEvent.F_ONBOARDING,
      screen: ETrackEvent.F_ONBOARDING,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'próximo',

      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagLoad = async ({
  action_id,
  plan_name = '',
  plan_value = '',
  step,
}: LoadTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_ACCESS, {
      ref_figma: '3',
      flow: ETrackEvent.F_ONBOARDING,
      screen: `${ETrackEvent.F_ONBOARDING} ${step}`,
      content_action: 'dado carregado',
      action_id,

      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}
