import styled from 'styled-components'

export const PersonasImgContent = styled.div`
  margin: 1.5rem 0;
  display: flex;
  justify-content: center;
`

export const ButtonContent = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1.5rem;
`
