import { ETrackEvent } from 'enums/ETrackEvent'

import { interWbSession } from '@interco/inter-webview-bridge'

interface CarouselTags {
  name?: string
  category?: string
  section?: string
}

export const handleTagSeeAll = async ({ section = '' }: CarouselTags) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '7',
      flow: ETrackEvent.F_EXPERIENCES,
      screen: ETrackEvent.S_EXPERIENCES_LIST,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'conferir todos',

      section,
    })
    .catch(window.console.log)
}

export const handleTagItem = async ({ name = '', category = '', section = '' }: CarouselTags) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '8',
      flow: ETrackEvent.F_EXPERIENCES,
      screen: ETrackEvent.S_EXPERIENCES_LIST,
      ref_type: 'card',
      content_action: 'toque',
      action_id: 'visualizar experiência',

      name,
      category,
      section,
    })
    .catch(window.console.log)
}
