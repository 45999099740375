import styled from 'styled-components'

export const Wrapper = styled.div`
  .banner-carousel {
    padding: 1rem 1.5rem 0;
  }
`

export const Container = styled.div`
  padding: 16px 24px 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .success-img {
    width: 100%;
    margin: 40px 0 20px 0;
  }

  .description-text {
    padding: 0.5rem 0.5rem;
  }
`

export const BenefitInfo = styled.div`
  border: 1px solid var(--gray200);
  border-radius: 0.5rem;
  width: 100%;
  margin: 1rem;
  display: flex;
  flex-direction: column;

  .title-text {
    align-self: center;
    padding: 1rem 0 0;
  }

  .list-item {
    display: flex;
    align-items: center;
    margin: 0.5rem 1rem;

    .item-icon {
      margin-right: 1rem;
    }
  }

  .alert-style {
    margin: 1rem 0 0;
  }
`

export const SuccessImg = styled.div`
  margin: 0.5rem 0 0.2rem;
`

export const ShimmerWrapper = styled.div`
  padding: 16px 24px 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .success-img {
    margin: 4rem 0 2rem;
  }

  .table-style {
    margin: 1.5rem 0 1rem;
  }

  .bt-style {
    margin: 0 0 1rem;
  }
`
