import { ETrackEvent } from 'enums/ETrackEvent'

import { interWbSession } from '@interco/inter-webview-bridge'

export const handleTagHeader = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_HEADER, {
      ref_figma: '1',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_USE_RATING,
      action_id: 'back',
      product: ETrackEvent.T_PRODUCT,
    })
    .catch(window.console.log)
}

interface SendRating {
  restaurant: string
  service_note: string
  delivery_note: string
  plate_note: string
  plan_name: string
  plan_value: string
}

export const handleTagSendRating = async ({
  restaurant,
  service_note,
  delivery_note,
  plate_note,
  plan_name,
  plan_value,
}: SendRating) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '4',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_USE_RATING,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'enviar avaliação',

      restaurant,
      service_note,
      delivery_note,
      plate_note,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

interface LoadTag {
  restaurant: string
}

export const handleLoadSuccessTag = async ({ restaurant }: LoadTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '22',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_RATING_SUCCESS,
      content_action: 'dado carregado',
      action_id: 'agradecemos pela sua avaliação',

      restaurant,
    })
    .catch(window.console.log)
}

export const handleLoadErrorTag = async ({ restaurant }: LoadTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '23',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_RATING_ERROR,
      content_action: 'dado carregado',
      action_id: 'experiência não avaliada',

      restaurant,
    })
    .catch(window.console.log)
}
