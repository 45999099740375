import React from 'react'

import { Footer } from 'components/FixButton/FixButton.styles'

import { Button } from '@interco/inter-ui/components/Button'

interface FixButtonProps {
  disabled?: boolean
  border?: string
  background?: string
  icon?: React.ReactNode
  width?: string
  label?: string
  size?: 'small' | 'default'
  className?: string
  children?: React.ReactNode
  onClick?(): void
}

export const FixButton = ({
  label,
  children,
  onClick,
  width,
  icon,
  background,
  border,
  size,
  className,
  disabled,
}: FixButtonProps) => (
  <Footer style={{ background, border }}>
    <Button
      size={size}
      icon={icon}
      style={{ margin: '1rem 0', width }}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
      {children}
    </Button>
  </Footer>
)
