import React, { CSSProperties, useEffect, useState } from 'react'

import AppBar from 'components/AppBar'
import { IWbUserLocation } from 'hooks/useUserLocation'
import { useConfigs } from 'contexts/ConfigsContext'
import { ECurrentMainTab } from 'enums/EContexts'

import { RouteComponentProps, useParams } from '@reach/router'
import { TabPager, TabDefinition } from '@interco/inter-ui/components/TabPager'

import RecommendationsTab from './RecommendationsTab/RecommendationsTab'
import RestaurantsTab from './RestaurantsTab'
import ExperiencesTab from './ExperiencesTab'
import * as S from './Home.styles'
import * as T from './Home.tags'

const Home = (_: RouteComponentProps) => {
  const screenName = 'Duo Gourmet'
  const params = useParams<{ tab: string }>()
  const { configs } = useConfigs()

  const [userLocation, setUserLocation] = useState<IWbUserLocation>({} as IWbUserLocation)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [currentTab, setCurrentTab] = useState<string | number>(params.tab)

  useEffect(() => {
    const permissionLocation = configs?.permissionLocation
    if (permissionLocation?.latitude && permissionLocation?.longitude && permissionLocation?.city) {
      setUserLocation({
        latitude: permissionLocation?.latitude,
        longitude: permissionLocation?.longitude,
        city: permissionLocation?.city,
      })
    }

    T.handleTagAccess()
  }, [])

  useEffect(() => {
    if (configs.permissionLocation.checked) {
      setLoaded(true)
    }
  }, [configs.permissionLocation.checked])

  const handleTabChange = (tabId: string | number) => {
    T.handleTagTabs({ tab: tabId.toString() })
    setCurrentTab(tabId)
    window.history.replaceState(null, '', `/home/${tabId}`)
  }

  const tabDefinitions: TabDefinition[] = [
    {
      tabId: ECurrentMainTab.home,
      label: 'Início',
      content: <RecommendationsTab userLocation={userLocation} handleTabChange={handleTabChange} />,
    },
    {
      tabId: ECurrentMainTab.restaurants,
      label: 'Restaurantes',
      content: <RestaurantsTab userLocation={userLocation} />,
    },
    {
      tabId: ECurrentMainTab.experiences,
      label: 'Experiências',
      content: <ExperiencesTab userLocation={userLocation} />,
    },
  ]

  const listStyle = {
    justifyContent: 'center',
    position: 'fixed',
    background: 'var(--neutral-theme)',
    zIndex: 2,
  } as CSSProperties

  if (!loaded) return <></>

  return (
    <S.Wrapper>
      <AppBar
        name={screenName}
        subscriber={configs.subscriber.duoGourmetInterCustomer}
        goBackNative
        showHomeAction={!configs.subscriber.duoGourmetInterCustomer}
        homeTag={T.handleTagHome}
        backTag={T.handleTagGoBack}
      />
      <S.Content>
        <TabPager
          currentTabId={currentTab}
          onTabSelect={handleTabChange}
          tabDefinitions={tabDefinitions}
          tabListStyle={listStyle}
        />
      </S.Content>
    </S.Wrapper>
  )
}

export default Home
