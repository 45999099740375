import React, { useEffect } from 'react'

import { bridgeNative } from 'App'
import { IBanner } from 'types/Campaign'
import { useBSState } from 'contexts/FiltersBottomsheetContext'
import InvalidAssignatureBs from 'components/InvalidAssignatureBs'
import { getParamsFromURL } from 'utils/TagsUtils'

import { interWbNavigate } from '@interco/inter-webview-bridge'
import ChevronRightIcon from '@interco/icons/orangeds/XL/chevron-right'
import { Button } from '@interco/inter-ui/components/Button'
import { Card } from '@interco/inter-ui/components/Card'
import { Text } from '@interco/inter-ui/components/Text'
import { navigate } from '@reach/router'

import { Container, ImgContainer, TextContent, BannerImage } from './PromotionalBanner.styles'
import { BannerTag, handleTagBanner } from './PromotionalBannerTags'

interface IPromotionalBannerProps {
  banner: IBanner
  pessoaFisica: boolean
  canSubscribe: {
    canSubscribe: boolean
    text: string
    button: string
  }
  bannerTag: BannerTag
}

export const PromotionalBanner = ({
  banner,
  pessoaFisica,
  canSubscribe,
  bannerTag,
}: IPromotionalBannerProps) => {
  const { bsStateParam, setBsStateParam } = useBSState()

  useEffect(() => {
    handleTagBanner({
      ...bannerTag,
      content_id: banner.sort,
      title: banner.tagAnalytics,
      content_action: 'dado carregado',
    })
  }, [])

  const onClickHandler = async () => {
    const { isDeepLink, link } = banner

    const isStandardUrl = link?.includes('https://') ?? false
    const isBancoInterScheme = link?.includes('bancointer://')

    handleTagBanner({
      ...bannerTag,
      content_id: banner.sort,
      title: banner.tagAnalytics,
      content_action: 'toque',
    })

    if (!isDeepLink && link) {
      if (isStandardUrl) {
        await interWbNavigate.openDeepLink(link).catch(window.console.error)
        return
      }

      if (!isBancoInterScheme) {
        if (link.includes('tab=')) {
          const { filterParams } = getParamsFromURL(banner.link)
          navigate(`/home/${filterParams.tab}`, { state: { sort: banner.sort }, replace: true })
        } else {
          navigate(link, { state: { sort: banner.sort } })
        }
      }
    }

    if (isDeepLink) {
      if (isBancoInterScheme) {
        await interWbNavigate.openDeepLink(banner.link as string).catch(window.console.error)
        return
      }

      if (!isBancoInterScheme) {
        if (link === 'sendToSubscription' && (!canSubscribe.canSubscribe || !pessoaFisica)) {
          setBsStateParam({ ...bsStateParam, showBottomSheetInvalidAssignature: true })
          return
        }
        await bridgeNative.execute({
          action: link as string,
          metadata: { productId: 'DUO_GOURMET' },
        })
      }
    }
  }

  if (banner.type === 'image') {
    return (
      <BannerImage data-testid="banner-click" onClick={onClickHandler}>
        <img src={banner.image} alt={banner.imageAlt} />
      </BannerImage>
    )
  }

  return (
    <Container>
      <Card className="card-style">
        {bsStateParam.showBottomSheetPromotionalBanners &&
          !canSubscribe.canSubscribe &&
          canSubscribe.text &&
          canSubscribe.button && (
            <InvalidAssignatureBs text={canSubscribe.text} button={canSubscribe.button} />
          )}
        <TextContent>
          <Text variant="headline-h3" className="headline" semiBold>
            {banner.title}
          </Text>

          <Text variant="caption-1">
            <div
              dangerouslySetInnerHTML={{
                __html: banner?.description || '',
              }}
            />
          </Text>

          {banner.button && banner.link && (
            <Button
              icon={
                <ChevronRightIcon
                  width={18}
                  height={18}
                  color="var(--primary500)"
                  className="icon-style"
                />
              }
              variant="link"
              onClick={onClickHandler}
              style={{ marginTop: '12px', padding: '0px' }}
            >
              {banner.button}
            </Button>
          )}
        </TextContent>

        <ImgContainer>
          <img src={banner.image} alt={banner.imageAlt} className="img-content" />
        </ImgContainer>
      </Card>
    </Container>
  )
}

export default PromotionalBanner
