import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const UpperContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const BottomContent = styled.div`
  width: 100%;
  padding: 16px 24px;
  position: fixed;
  bottom: 0;
  background-color: var(--neutral-theme);
  height: 80px;
  display: flex;
  border-top: 1px solid var(--gray200);
`

export const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
`
