import { ETrackEvent } from 'enums/ETrackEvent'

import { interWbSession } from '@interco/inter-webview-bridge'

export const handleTagPendingPayment = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '1',
      flow: ETrackEvent.F_PENDING_PAYMENT,
      screen: ETrackEvent.S_SIGNATURE_PAYMENT,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'pagar assinatura pendente',
    })
    .catch(window.console.log)
}

export const handleTagHeader = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_HEADER, {
      ref_figma: '12',
      flow: ETrackEvent.S_SIGNATURE_PAYMENT,
      screen: ETrackEvent.S_SIGNATURE_PAYMENT,
      action_id: 'back',
      product: ETrackEvent.T_PRODUCT,
    })
    .catch(window.console.log)
}

export const handleTagPayment = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '15',
      flow: ETrackEvent.S_SIGNATURE_PAYMENT,
      screen: ETrackEvent.S_SIGNATURE_PAYMENT,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'pagamento',
    })
    .catch(window.console.log)
}
