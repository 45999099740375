import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;

  .topAnnouncementsStyle {
    margin: 0.9rem 1.5rem 0.1rem;
  }

  .bottomAnnouncementsStyle {
    padding: 1.5rem;
    background-color: var(--gray100);
  }

  .errorStyle {
    width: 100%;
    height: 90vh;
  }
`

export const Header = styled.div`
  background: var(--neutral-theme);
  top: 5rem;
  width: 100%;
  padding: 3rem 1.5rem 0;
`

interface ContentProps {
  duoGourmetInterCustomer: boolean
}

export const Content = styled.div<ContentProps>`
  ${(props) => !props.duoGourmetInterCustomer && `margin-bottom: 5rem;`}
  overflow: hidden;
`

export const CarouselList = styled.div`
  margin-top: 1rem;
  position: relative;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 2.3rem 1.3rem;

  .textStyle {
    text-align: center;
  }
`

export const ListBt = styled.div`
  padding: 0 1.3rem 1.5rem;
`

export const SubscribeContainer = styled.div`
  margin-top: 1rem;
`
