import React from 'react'

import Divider from 'components/Divider'
import AppBar from 'components/AppBar'

import { Skeleton } from '@interco/inter-ui/components/Skeleton'

import * as S from './ExperiencesDetails.styles'

interface ExperiencesDetailsShimmerProps {
  appBarName?: string
}

const ExperiencesDetailsShimmer = ({ appBarName }: ExperiencesDetailsShimmerProps) => (
  <>
    <AppBar name={appBarName} />
    <Skeleton width="100%" height="17rem" />
    <S.ShimmerHeader>
      <Skeleton width="60%" height="30px" />
      <Skeleton width="100%" height="3rem" />
    </S.ShimmerHeader>
    <Divider borderWidth={8} color="var(--gray100)" />
    <S.ShimmerContainer>
      <Skeleton width="60%" height="2rem" />
      <S.ShimmerSchedule>
        <Skeleton width="100%" height="1.2rem" />
        <Divider color="var(--gray200)" style={{ margin: '0 0 0.5rem' }} />
        {[...Array(7)].map((_, idx) => (
          <Skeleton key={`schedule-${idx.toString()}`} width="100%" height="1.2rem" />
        ))}
      </S.ShimmerSchedule>
      <Skeleton width="100%" height="5rem" />
    </S.ShimmerContainer>
  </>
)

export default ExperiencesDetailsShimmer
