import { FilterObject } from 'types/Filters'
import { QueryParamRestaurant } from 'contexts/FiltersContext'

import Filters from './Filters'

export interface FilterCategories {
  days: FilterObject[]
  mealTimes: FilterObject[]
  cookings: FilterObject[]
  districts: FilterObject[]
  price: FilterObject[]
  orderMethod: FilterObject[]
}

export interface FilterProps extends FilterCategories {
  city: string
  fetchRestaurants: (newQueryParam: QueryParamRestaurant) => void
}

export default Filters
