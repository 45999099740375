import Divider from 'components/Divider'

import { Skeleton } from '@interco/inter-ui/components/Skeleton'

import * as S from './RecommendationsTabShimmer.styles'

const RecommendationsTabShimmer = () => (
  <S.Wrapper>
    {window.scrollTo({ top: 0, behavior: 'auto' })}
    <Skeleton width="100%" height="20px" className="m-3 locationStyle" />
    <Divider color="var(--gray200)" className="dividerStyle" />
    <Skeleton width="100%" height="140px" className="m-3 topAnnouncements" />
    <Divider color="var(--gray100)" borderWidth={8} className="dividerStyle" />
    {new Array(3).fill('').map((_, i) => (
      <S.Carousel key={`id_${i.toString()}`}>
        <S.Header>
          <Skeleton width="200px" height="24px" className="m-3" />
          <Skeleton width="100px" height="24px" className="m-3" />
        </S.Header>
        <S.Content>
          <S.Card>
            <Skeleton width="150px" height="97px" className="m-3" />
            <Skeleton width="130px" height="20px" className="m-3" />
            <Skeleton width="100px" height="20px" className="m-3" />
          </S.Card>
          <S.Card>
            <Skeleton width="150px" height="97px" className="m-3" />
            <Skeleton width="130px" height="20px" className="m-3" />
            <Skeleton width="100px" height="20px" className="m-3" />
          </S.Card>
          <S.Card>
            <Skeleton width="150px" height="97px" className="m-3" />
            <Skeleton width="130px" height="20px" className="m-3" />
            <Skeleton width="100px" height="20px" className="m-3" />
          </S.Card>
        </S.Content>
      </S.Carousel>
    ))}
  </S.Wrapper>
)

export default RecommendationsTabShimmer
