import styled from 'styled-components'

export const ContentContainer = styled.div`
  margin: 50px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .one-btn {
    margin-bottom: 4rem;
  }

  .two-btn {
    margin-bottom: 8rem;
  }
`

export const FilterItem = styled.div`
  display: inline-block;

  .input {
    display: none;
  }

  .input + .label {
    float: right;
    margin: 8px 8px 0 0;
    padding: 5px 12px;
    font-size: 12px;
    line-height: 20px;
    color: var(--gray400);
    text-align: center;
    cursor: pointer;
    border: 1px solid var(--gray200);
    border-radius: 20px;
  }

  .input:checked + .label {
    background-image: none;
    background-color: var(--gray500);
    color: var(--gray100);
    border: 1px solid var(--gray500);
  }
`
export const FilterSubtitle = styled.h1`
  margin-top: -10px;
`

export const SubCategory = styled.div`
  margin-top: 0.5rem;
`

export const AlertStyle = styled.div`
  margin: 0 0 1rem 0;
`

export const FilterContent = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 0 1.2rem 1rem 1.5rem;
  clip-path: inset(0 0 1rem 0);
`
export const colorWhite = 'var(--neutral-theme)'
export const colorBlack = 'var(--gray500)'

export const FilterCheckboxList = styled.div`
  display: flex;
`

export const ListContainer = styled.div`
  .one-btn {
    margin-bottom: 4rem;
  }

  .two-btn {
    margin-bottom: 8rem;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  background: var(--neutral-theme);
  border-top: 1px solid var(--gray200);
  padding: 0.3rem 1rem 1rem;

  .button-style {
    width: 100%;
    margin-top: 0.5rem;
  }
`
