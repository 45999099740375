import styled from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  margin: 1.5rem 0 0 0;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  margin: 1.5rem 0 0 0;
  gap: 1.5rem;

  .topAnnouncementsStyle {
    margin-bottom: 1rem;
  }
`
export const RestaurantImage = styled.img`
  height: 210px;
  object-fit: cover;
  margin-top: 55px;
`

export const CookingContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
`
export const RestaurantLocation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  margin: 24px 0 16px 0;
`
export const LocationPin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gray100);
  border-radius: 50%;
  width: 48px;
  height: 48px;
`
export const LocationText = styled.div`
  align-self: center;
  width: 75%;
  margin-left: 1rem;
`

export const RestaurantAddress = styled.div`
  font-size: 12px;
  font-weight: 600;
`
export const RestaurantCity = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: var(--gray400);
`

export const ArrowContent = styled.div`
  align-self: center;
`

export const Bar = styled.div`
  width: 100%;
  border-bottom: 8px solid var(--gray100);
`
export const RulesContent = styled.div`
  border: 1px solid var(--gray200);
  border-radius: 0.5rem;
  --accordion-container-background-color: none;
`
export const ButtonContact = styled.div`
  background: var(--neutral-theme);
  padding: 1rem 1.5rem 0 1.5rem;
  bottom: 0;
  position: fixed;
  width: 100%;
  border-top: 1px solid var(--gray100);
`

export const SliderTheme = styled.div`
  .slick-slider .slick-dots {
    bottom: -30px;
    text-align: left;
    padding: 0 24px;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const UseLimitAlert = styled.div`
  margin-bottom: 2rem;

  .buttonStyle {
    margin-top: 2rem;
    width: 100%;
  }
`

export const TopIconContainer = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  button {
    margin-left: 1rem;
    background-color: transparent;
    border: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
`

export const TagsContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: wrap;

  span {
    display: flex;
    margin: 0;
  }
`

export const TagSpace = styled.div`
  margin: 0.1rem 0.3rem 0 0;
`

export const RestaurantMenu = styled.div`
  margin-bottom: 32px;

  > button {
    width: 100%;
  }
`

export const MenuContent = styled.div`
  > div {
    margin-top: 8px;
  }
`

export const ButtonContent = styled.div`
  --button-background-color: var(--primary500);
  --button-border-radius: 8px;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
`
