import styled from 'styled-components'

export const Footer = styled.div`
  background: var(--neutral-theme);
  padding: 0 1.5rem;
  bottom: 0;
  position: fixed;
  width: 100%;
  border-top: 1px solid var(--neutral-theme);
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: var(--neutral-theme);
  }
`
