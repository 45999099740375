import Divider from 'components/Divider'

import { Skeleton } from '@interco/inter-ui/components/Skeleton'

import * as S from './ExperiencesCollectionShimmer.styles'

const RecommendationsCollectionShimmer = () => (
  <S.Wrapper>
    <Skeleton width="70%" height="30px" className="titleStyle" />
    {new Array(10).fill('').map((d, i) => (
      <S.Item key={`id_${i.toString()}`}>
        <S.Content>
          <Skeleton width="73px" height="73px" />
          <S.RestaurantDetails>
            <Skeleton width="150px" height="14px" className="m-3" />
            <Skeleton width="100px" height="14px" className="m-3" />
            <Skeleton width="100px" height="14px" className="m-3" />
            <Skeleton width="130px" height="14px" className="m-3" />
          </S.RestaurantDetails>
        </S.Content>
        <Divider color="var(--gray100)" style={{ margin: '0' }} />
      </S.Item>
    ))}
  </S.Wrapper>
)

export default RecommendationsCollectionShimmer
