import React, { useState } from 'react'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { RestaurantResponse, RestaurantEligibility } from 'types/Restaurant'
import { OperationCalendar } from 'features/Restaurants/OperationCalendar'
import { FixButton } from 'components/FixButton'
import Divider from 'components/Divider'
import AppBar from 'components/AppBar'
import useAsync from 'hooks/UseAsync'
import queryString from 'query-string'
import { RulesList } from 'features/Restaurants/RulesList'
import Error from 'components/Error'
import { RestaurantValidation } from 'types/Validation'
import { bridgeNative } from 'App'
import { BottomSheet } from 'components/BottomSheet'
import PriceHank from 'components/PriceHank/PriceHank'
import TooltipBs from 'features/Restaurants/OperationCalendar/TooltipBs'
import { useBSState } from 'contexts/FiltersBottomsheetContext'
import InvalidAssignatureBs from 'components/InvalidAssignatureBs'
import { useConfigs } from 'contexts/ConfigsContext'
import { getParamsFromURL } from 'utils/TagsUtils'
import { ESubscriptionStatus } from 'types/Subscription'
import { useFilters } from 'contexts/FiltersContext'
import { TagsList } from 'components/TagsList'
import HomeAnnouncements from 'features/Home/HomeAnnouncements/HomeAnnouncements'
import { ETrackEvent } from 'enums/ETrackEvent'
import { ECurrentMainTab } from 'enums/EContexts'

import InterWebviewBridge, { interWbNavigate, interWbSession } from '@interco/inter-webview-bridge'
import ArrowIcon from '@interco/icons/orangeds/XL/chevron-right'
import ShoppingBagIcon from '@interco/icons/orangeds/XL/shopping-bag'
import HeartIcon from '@interco/icons/orangeds/XL/heart'
import HeartFillIcon from '@interco/icons/orangeds/XL/heart-fill'
import ShareIcon from '@interco/icons/orangeds/SM/share'
import ForkKnifeIcon from '@interco/icons/orangeds/SM/fork-knife'
import LocationIcon from '@interco/icons/orangeds/SM/location'
import SiteIcon from '@interco/icons/bidis/v2/social/website'
import InstagramIcon from '@interco/icons/orangeds/XL/instagram'
import { RouteComponentProps, useNavigate, useParams } from '@reach/router'
import { Alert } from '@interco/inter-ui/components/Alert'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { Carousel } from '@interco/inter-ui/components/Carousel'

import { ILocationProps } from '.'
import RestaurantDetailShimmer from './RestaurantsDetailsShimmer'
import { handleTagBSUseBenefit, handleTagClose } from './RestaurantModality/RestaurantModality.tags'
import * as S from './RestaurantsDetails.styles'
import * as T from './RestaurantsDetails.tags'

const RestaurantsDetails = ({ location }: RouteComponentProps) => {
  const params = useParams<{ id: string }>()
  const { bsStateParam, setBsStateParam } = useBSState()
  const [showBottomSheet, setShowBottomSheet] = useState<boolean>(false)
  const [showTooltipBottomSheet, setShowTooltipBottomSheet] = useState(false)
  const [restaurantDetail, getRestaurant] = useAsync<RestaurantResponse>('get')
  const [eligibility, getEligibility] = useAsync<RestaurantEligibility>('get')
  const [validationBenefit, postValidation] = useAsync<RestaurantValidation>('post')
  const [favorite, setFavorite] = useState(restaurantDetail?.data?.favorite)
  const [restaurantName, setRestaurantName] = useState('')
  const [, postChangeFavorite] = useAsync('post')
  const { queryParam } = useFilters()
  const { configs } = useConfigs()
  const navigate = useNavigate()
  const locationState = location?.state as ILocationProps
  const [hasSocialLink, setHasSocialLink] = useState(false)
  const [hasOrderBt, setHasOrderBt] = useState(false)
  const device = InterWebviewBridge.getInstance().getEnvironment()

  const handlePhone = async () => {
    try {
      T.handleTagContact({
        restaurant: restaurantDetail?.data?.restaurant?.name,
        plan_name: configs.subscriber.planName,
        plan_value: configs.subscriber.planValue,
      })
      interWbNavigate.callPhoneNumber(restaurantDetail?.data?.restaurant?.phone)
    } catch (error) {
      window.console.log(error)
    }
  }

  const fetchRestaurant = React.useCallback(async () => {
    try {
      const res = await getRestaurant(
        `${process.env.REACT_APP_API}/restaurants/${params.id}?${queryString.stringify({
          latitude: queryParam?.latitude,
          longitude: queryParam?.latitude,
          device,
        })}`,
      )
      setFavorite(res?.favorite)
      setRestaurantName(res?.restaurant?.name)
      setHasSocialLink(!!res?.restaurant.instagramUsername || !!res?.restaurant.site)
      if (res?.choices.length === 1 && res?.choices[0].title !== 'Presencial') {
        setHasOrderBt(true)
      }
    } catch (error) {
      window.console.log(error)
    }
  }, [])

  React.useEffect(() => {
    fetchRestaurant()
    setRestaurantName(locationState?.name ?? '')
    T.handleTagAccess({ restaurant: locationState?.name ?? '' })
  }, [])

  const getBlackBanners = () => {
    const blackBanners = restaurantDetail.data?.banners.filter((banner) =>
      banner.description?.toLowerCase().includes('com a sua assinatura no app do duo gourmet'),
    )
    return blackBanners
  }

  async function checkEligibility() {
    try {
      await getEligibility(`app-pf/foods/orders/v1/restaurants/${params.id}/eligibility`)
    } catch (error) {
      window.console.log(error)
    }
  }

  const handleBSClose = () => {
    handleTagClose({
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    })
    setShowBottomSheet(false)
  }

  const handleValidation = async () => {
    handleTagBSUseBenefit({
      restaurant: restaurantDetail?.data?.restaurant?.name,
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    })
    try {
      const res = await postValidation(`app-pf/foods/orders/v1/useEvents`, {
        restaurantId: params?.id,
        subscription: restaurantDetail?.data?.subscription,
        modality: restaurantDetail?.data?.choices.map((item) => item.title).join(', '),
      })
      navigate('/restaurants-benefit', {
        state: {
          ...res,
          bankingCustomer: restaurantDetail?.data?.bankingCustomer,
        },
      })
    } catch (error) {
      window.console.error(`error:`, error)
    }
  }

  const handleSubscription = async () => {
    const { utmParams, adjParams } = getParamsFromURL()
    try {
      T.handleTagSubscribe({ ...utmParams, ...adjParams })
      if (!restaurantDetail.data?.pessoaFisica) {
        setBsStateParam({ ...bsStateParam, showBottomSheetInvalidAssignature: true })
      } else {
        await bridgeNative.execute({
          action: 'sendToSubscription',
          metadata: { productId: 'DUO_GOURMET' },
        })
      }
    } catch (error) {
      window.console.error(`error:`, error)
    }
  }

  const handleFavorite = async () => {
    try {
      T.handleTagFavorite({
        favorite: `${!restaurantDetail?.data?.restaurant?.favorite}`,
        restaurant: restaurantDetail?.data?.restaurant?.name,
        cooking: restaurantDetail?.data?.restaurant?.cooking,
        neighborhood: restaurantDetail?.data?.restaurant?.location,
        modality: restaurantDetail?.data?.choices.map((item) => item.title).join(', '),
        plan_name: configs.subscriber.planName,
        plan_value: configs.subscriber.planValue,
      })
      await postChangeFavorite(
        `app-pf/foods/shop/v1/favorites/${restaurantDetail?.data?.restaurant?.id}`,
      )
      setFavorite(!favorite)
    } catch (error) {
      window.console.error(error)
    }
  }

  const handleUseBenefit = async () => {
    T.handleTagUseBenefit({
      restaurant: restaurantDetail?.data?.restaurant.name,
      modality: restaurantDetail?.data?.choices.map((item) => item.title).join(', '),
      cooking: restaurantDetail?.data?.restaurant.cooking,
      favorite: `${restaurantDetail?.data?.favorite}`,
      neighborhood: restaurantDetail?.data?.restaurant.location,
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
    })
    if (restaurantDetail.data?.choices.length === 1) {
      switch (restaurantDetail.data?.choices[0].title) {
        case 'Presencial':
          await checkEligibility()
          setShowBottomSheet(true)
          break
        case 'Delivery':
        case 'Retirada':
          try {
            await interWbNavigate.openDeepLink(restaurantDetail.data?.choices[0].deepLink)
          } catch (error) {
            window.console.error(`error:`, error)
          }
          break
        default:
          break
      }
    } else {
      navigate(`/restaurants/${restaurantDetail?.data?.restaurant?.id}/modality`, {
        state: {
          restaurantDetail: restaurantDetail?.data,
        },
      })
    }
  }

  const bsContent = (
    <>
      <div style={{ marginTop: '1rem ' }}>
        <Text variant="body-3" color="typography">
          Após a ativação, você deverá esperar 4 horas para usar o Duo Gourmet de novo (exceto em
          gelaterias e docerias).
        </Text>
      </div>
      <div style={{ marginTop: '1rem' }}>
        {restaurantDetail?.data?.restaurant?.usageRestriction ? (
          <Alert title={restaurantDetail?.data?.restaurant?.usageRestrictionTitle} type="warning">
            {restaurantDetail?.data?.restaurant?.usageRestrictionText}
          </Alert>
        ) : (
          <Alert type="warning">
            Use seu benefício somente ao finalizar seu pedido e pedir a conta ao garçom.
          </Alert>
        )}
      </div>
      {validationBenefit?.isError && (
        <div style={{ marginTop: '1rem' }}>
          <Alert type="error">
            {validationBenefit?.error?.errors[0]?.message ??
              'Ocorreu um erro ao validar seu benefício!'}
          </Alert>
        </div>
      )}
      {!eligibility?.data?.eligible && (
        <div style={{ marginTop: '1rem' }}>
          <Alert type="error">{eligibility?.data?.message}</Alert>
        </div>
      )}
      <S.ButtonContent>
        <Button
          style={{ width: '100%' }}
          onClick={handleValidation}
          disabled={!eligibility?.data?.eligible}
          isLoading={validationBenefit.isPending}
        >
          Usar meu benefício
        </Button>
        {restaurantDetail.data?.subscriptionStatus === ESubscriptionStatus.PENDING_PAYMENT && (
          <Button
            style={{ width: '100%' }}
            variant="secondary"
            onClick={() => {
              T.handleTagCheckSubscription({
                plan_name: configs.subscriber.planName,
                plan_value: configs.subscriber.planValue,
              })
              navigate('/menu/assinatura-pagamento')
            }}
          >
            Verificar minha assinatura
          </Button>
        )}
      </S.ButtonContent>
    </>
  )

  const SubscriberInter = restaurantDetail?.data?.duoGourmetInterCustomer

  const handleRestaurantMenu = async () => {
    T.handleTagOpenMenu({
      restaurant: restaurantDetail?.data?.restaurant?.name,
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
    })
    setBsStateParam({ ...bsStateParam, showBottomSheetMenu: true })
  }

  const handleRestaurantMenuContinue = async () => {
    if (restaurantDetail?.data?.restaurant?.menuUrl) {
      await interWbNavigate.openDeepLink(restaurantDetail?.data?.restaurant?.menuUrl)
    }
  }

  const handleRestaurantMenuClose = () => {
    setBsStateParam({ ...bsStateParam, showBottomSheetMenu: false })
  }

  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    verticalMode: false,
  }

  if (restaurantDetail?.isError) {
    return (
      <>
        <AppBar name="Restaurante" showHomeAction />
        <Error />
      </>
    )
  }

  if (restaurantDetail?.isPending) {
    return <RestaurantDetailShimmer appBarName={restaurantName} />
  }

  if (restaurantDetail?.isSuccess) {
    return (
      <>
        <AppBar
          name={restaurantName}
          subscriber={SubscriberInter}
          showHomeAction={!SubscriberInter}
          backTag={T.handleTagHeader}
        />
        <S.SliderTheme>
          <Carousel dotsType="medium" {...settings}>
            <S.RestaurantImage src={restaurantDetail?.data?.restaurant?.imageCoverUrl} alt="" />
            {restaurantDetail?.data?.restaurant?.otherImages?.map((e) => (
              <S.RestaurantImage key={e} src={e} alt="" />
            ))}
          </Carousel>
        </S.SliderTheme>
        <S.HeaderContainer
          style={
            restaurantDetail?.data?.restaurant?.otherImages.length === 0
              ? { marginTop: '1rem' }
              : { marginTop: '2rem' }
          }
        >
          <S.Header>
            <Text
              color="typography"
              variant="headline-h3"
              semiBold
              style={{ marginBottom: '16px' }}
            >
              {restaurantDetail?.data?.restaurant?.name}
            </Text>
            <S.TopIconContainer>
              <button
                id="overlay"
                type="button"
                onClick={async () => {
                  T.handleTagShare({
                    plan_name: configs.subscriber.planName,
                    plan_value: configs.subscriber.planValue,
                    restaurant: restaurantDetail?.data?.restaurant?.name,
                  })
                  interWbSession.shareContent(restaurantDetail?.data?.restaurant.deepLink)
                }}
              >
                <ShareIcon width={24} height={24} color="var(--primary500)" />
              </button>
              {hasSocialLink && (
                <button
                  id="overlay"
                  type="button"
                  onClick={() => {
                    T.handleTagSocials({
                      plan_name: configs.subscriber.planName,
                      plan_value: configs.subscriber.planValue,
                      restaurant: restaurantDetail?.data?.restaurant?.name,
                    })
                    restaurantDetail?.data?.restaurant.instagramUsername
                      ? interWbNavigate.openDeepLink(
                          `https://instagram.com/${restaurantDetail?.data?.restaurant.instagramUsername}`,
                        )
                      : interWbNavigate.openDeepLink(restaurantDetail?.data?.restaurant.site)
                  }}
                >
                  {restaurantDetail?.data?.restaurant.instagramUsername ? (
                    <InstagramIcon width={24} height={24} color="var(--primary500)" />
                  ) : (
                    <SiteIcon width={24} height={24} color="var(--primary500)" />
                  )}
                </button>
              )}
              {SubscriberInter && (
                <button id="overlay" type="button" onClick={handleFavorite}>
                  {favorite ? (
                    <HeartFillIcon width={24} height={24} color="var(--primary500)" />
                  ) : (
                    <HeartIcon width={24} height={24} color="var(--primary500)" />
                  )}
                </button>
              )}
            </S.TopIconContainer>
          </S.Header>

          {restaurantDetail?.data.duoGourmetInterCustomer && (
            <S.TagsContainer>
              <TagsList
                useNow={restaurantDetail?.data?.useNow}
                available={restaurantDetail?.data?.available}
                deliveryUseNow={restaurantDetail?.data?.deliveryUseNow}
                newRestaurant={restaurantDetail?.data?.newRestaurant}
                takeOutUseNow={restaurantDetail?.data?.takeOutUseNow}
              />
            </S.TagsContainer>
          )}

          <S.CookingContent>
            <ForkKnifeIcon
              width={16}
              height={16}
              color="var(--gray500)"
              style={{ marginRight: '0.5rem' }}
            />
            <Text color="typography" variant="caption-1">
              {restaurantDetail?.data?.restaurant?.cooking}
            </Text>
            {restaurantDetail?.data?.restaurant?.price ? (
              <>
                <span style={{ color: 'var(--gray200)', marginLeft: '3px', marginRight: '3px' }}>
                  •
                </span>
                <PriceHank price={restaurantDetail?.data?.restaurant?.price} />
              </>
            ) : null}
          </S.CookingContent>

          <S.CookingContent>
            <ShoppingBagIcon
              width={16}
              height={16}
              color="var(--gray500)"
              style={{ marginRight: '0.5rem' }}
            />
            {restaurantDetail.data?.choices.map((choice, idx, arr) => (
              <div key={`${choice}-${idx.toString()}`}>
                <Text color="typography" variant="caption-1">
                  {choice.title}
                </Text>
                {idx < arr.length - 1 && (
                  <span style={{ color: 'var(--gray200)', marginLeft: '3px', marginRight: '3px' }}>
                    •
                  </span>
                )}
              </div>
            ))}
          </S.CookingContent>

          <S.RestaurantLocation
            onClick={async () => {
              T.handleTagMap({
                restaurant: restaurantDetail?.data?.restaurant?.name,
                plan_name: configs.subscriber.planName,
                plan_value: configs.subscriber.planValue,
              })
              navigate(`/restaurants/${restaurantDetail?.data?.restaurant?.id}/location`, {
                state: {
                  restaurantName: restaurantDetail?.data?.restaurant?.name,
                  latitude: restaurantDetail?.data?.restaurant?.coordinates?.latitude,
                  longitude: restaurantDetail?.data?.restaurant?.coordinates?.longitude,
                  duoGourmetSubscriber: SubscriberInter,
                },
              })
            }}
          >
            <S.LocationPin>
              <LocationIcon width={24} height={24} color="var(--gray500)" />
            </S.LocationPin>
            <S.LocationText>
              <S.RestaurantAddress>
                {restaurantDetail?.data?.restaurant?.address}
              </S.RestaurantAddress>
              <S.RestaurantCity>{`${restaurantDetail?.data?.restaurant?.location}`}</S.RestaurantCity>
            </S.LocationText>
            <S.ArrowContent>
              <ArrowIcon width={24} height={24} color="var(--primary500)" />
            </S.ArrowContent>
          </S.RestaurantLocation>

          {restaurantDetail?.data?.restaurant?.menuUrl && (
            <S.RestaurantMenu>
              <Button size="small" variant="secondary" onClick={handleRestaurantMenu}>
                Abrir o cardápio
              </Button>
            </S.RestaurantMenu>
          )}

          {restaurantDetail?.data?.restaurant?.useLimitAlert && (
            <S.UseLimitAlert>
              <Alert type="warning">{restaurantDetail?.data?.restaurant?.useLimitAlert}</Alert>
              <Button
                type="button"
                variant="secondary"
                className="buttonStyle"
                onClick={() => navigate(`/home/${ECurrentMainTab.restaurants}`)}
              >
                Encontrar outro restaurante
              </Button>
            </S.UseLimitAlert>
          )}
        </S.HeaderContainer>

        <Divider borderWidth={8} color="var(--gray100)" style={{ margin: '0' }} />
        <S.Container>
          <OperationCalendar
            presentialSchedule={restaurantDetail?.data?.restaurant?.presentialSchedule}
            takeOutSchedule={restaurantDetail?.data?.restaurant?.takeOutSchedule}
            deliverySchedule={restaurantDetail?.data?.restaurant?.deliverySchedule}
            choices={restaurantDetail?.data?.choices}
            restaurantName={restaurantDetail?.data?.restaurant.name}
          />
          <Alert title="Benefícios" type="success">
            {restaurantDetail?.data?.restaurant?.benefitDescription}
          </Alert>
          <S.RulesContent>
            <RulesList
              texts={restaurantDetail?.data?.restaurant?.restrictions}
              restaurantName={restaurantDetail?.data?.restaurant.name}
            />
          </S.RulesContent>

          {!!restaurantDetail.data?.banners.length && (
            <HomeAnnouncements
              banners={restaurantDetail.data?.banners}
              canSubscribe={restaurantDetail.data?.canSubscribe}
              pessoaFisica={restaurantDetail.data?.pessoaFisica}
              className="topAnnouncementsStyle"
              bannerTag={{
                ref_figma: '5',
                flow: ETrackEvent.F_USE_BENEFIT,
                screen: ETrackEvent.S_RESTAURANT_DETAILS,
                subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
              }}
            />
          )}
        </S.Container>
        {SubscriberInter && (
          <>
            {restaurantDetail?.data?.restaurant?.phone && (
              <S.Container style={{ margin: '1rem 0 1rem 0' }}>
                <Button
                  variant="secondary"
                  style={{ margin: '0.5rem 0', width: '100%' }}
                  onClick={() => handlePhone()}
                >
                  Entre em contato com o restaurante
                </Button>
              </S.Container>
            )}
            <S.ButtonContact>
              {restaurantDetail?.data?.available && !restaurantDetail?.data?.activeUseEvent && (
                <Button
                  onClick={handleUseBenefit}
                  variant="primary"
                  style={{ marginBottom: '0.5rem ', width: '100%' }}
                  isLoading={eligibility?.isPending}
                >
                  {hasOrderBt ? 'Fazer meu pedido' : 'Usar meu benefício'}
                </Button>
              )}
              {restaurantDetail?.data?.activeUseEvent && (
                <Button
                  onClick={async () => {
                    navigate('/restaurants-benefit', {
                      state: {
                        ...restaurantDetail?.data?.activeEvent,
                        bankingCustomer: restaurantDetail?.data?.bankingCustomer,
                        canSubscribe: restaurantDetail.data?.canSubscribe,
                        pessoaFisica: restaurantDetail.data?.pessoaFisica,
                        banners: restaurantDetail.data?.banners,
                      },
                    })
                  }}
                  style={{ marginBottom: '0.5rem ', width: '100%' }}
                  disabled={
                    restaurantDetail?.data?.activeEvent?.modality.toLocaleLowerCase() !==
                    'presencial'
                  }
                >
                  Ver meu cupom de benefício
                </Button>
              )}
              {!restaurantDetail?.data?.available && !restaurantDetail?.data?.activeUseEvent && (
                <Button disabled style={{ marginBottom: '0.5rem ', width: '100%' }}>
                  Restaurante indisponível no momento
                </Button>
              )}
              <BottomSheet
                showBottomSheet={showBottomSheet}
                onDismiss={handleBSClose}
                headerLabel="Usar meu benefício"
                bsContent={bsContent}
              />
            </S.ButtonContact>
            <S.Container style={{ paddingBottom: '3rem' }} />
          </>
        )}

        {!SubscriberInter && (
          <>
            {!restaurantDetail.data.canSubscribe.canSubscribe ? (
              <FixButton
                label="Assine agora e comece a economizar"
                onClick={async () => {
                  setBsStateParam({ ...bsStateParam, showBottomSheetInvalidAssignature: true })
                }}
                width="100%"
              />
            ) : (
              <FixButton
                width="100%"
                onClick={handleSubscription}
                label="Assine agora e comece a economizar"
              />
            )}
          </>
        )}

        {!restaurantDetail.data?.canSubscribe.canSubscribe && (
          <InvalidAssignatureBs
            text={restaurantDetail?.data?.canSubscribe.text}
            button={restaurantDetail?.data?.canSubscribe.button}
            blackCardBanners={getBlackBanners()}
            canSubscribe={restaurantDetail?.data?.canSubscribe}
            isDuoGourmetLegacyUser={restaurantDetail?.data?.duoGourmetLegacyCustomer}
          />
        )}

        <TooltipBs
          showBottomSheet={showTooltipBottomSheet}
          setShowBottomSheet={setShowTooltipBottomSheet}
          bottomSheetText={restaurantDetail?.data?.restaurant?.unavailabilityTooltip}
        />

        <BottomSheet
          showBottomSheet={bsStateParam.showBottomSheetMenu}
          onDismiss={handleRestaurantMenuClose}
          headerLabel="Abrir o cardápio"
          headerBtLabel="Fechar"
          headerBtHandler={handleRestaurantMenuClose}
          footerBtLabel="Continuar"
          footerBtHandler={handleRestaurantMenuContinue}
          bsContent={
            <S.MenuContent>
              <Text variant="body-3">
                Você será redirecionado para um link externo de cardápio. Os valores e atualizações
                dos itens é de responsabilidade do restaurante.
              </Text>
              <Alert type="grayscale">
                Atente-se às regras de uso e benefícios na página do restaurante no Duo Gourmet.
              </Alert>
            </S.MenuContent>
          }
        />
      </>
    )
  }
  return null
}

export default RestaurantsDetails
