import React from 'react'

import ChevronRightIcon from '@interco/icons/orangeds/XL/chevron-right'
import LocationIcon from '@interco/icons/orangeds/XL/location'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './LocationBt.styles'

interface LocationBtProps {
  locationLabel: string
  onClickHandler: () => void
}

const LocationBt = ({ locationLabel, onClickHandler }: LocationBtProps) => (
  <S.InputContent onClick={onClickHandler}>
    <S.StyledLocation>
      <div>
        <LocationIcon width={20} height={20} color="var(--gray500)" />
        <Text variant="caption-1" as="span" style={{ width: '80%', color: 'var(--gray500)' }}>
          {locationLabel}
        </Text>
      </div>
      <ChevronRightIcon color="var(--primary500)" />
    </S.StyledLocation>
  </S.InputContent>
)

export default LocationBt
