import { ETrackEvent } from 'enums/ETrackEvent'
import { SubscriberTag } from 'types/AnalyticsTag'

import { interWbSession } from '@interco/inter-webview-bridge'

export const handleTagHeader = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_HEADER, {
      ref_figma: '1',
      flow: ETrackEvent.F_USE_HISTORY,
      screen: ETrackEvent.S_MENU_USER,
      action_id: 'back',
      product: ETrackEvent.T_PRODUCT,
    })
    .catch(window.console.log)
}

export const handleTagTotalSaved = async ({ plan_name = '', plan_value = '' }: SubscriberTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '2',
      flow: ETrackEvent.F_USE_HISTORY,
      screen: ETrackEvent.S_MENU_USER,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'duvida sobre o saldo',

      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

interface MenuItemsTags extends SubscriberTag {
  label: string
}

export const handleTagItems = async ({ label, plan_name = '', plan_value = '' }: MenuItemsTags) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '3',
      flow: ETrackEvent.F_USE_HISTORY,
      screen: ETrackEvent.S_MENU_USER,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: label,

      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}
