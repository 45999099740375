import styled from 'styled-components'

export const Wrapper = styled.div`
  background: var(--neutral-theme);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20rem;

  padding: 1.5rem;

  .appbar-title {
    white-space: nowrap;
  }
`
