import styled from 'styled-components'

export const Container = styled.div`
  border: 1px solid var(--gray200);
  border-radius: 0.5rem;

  .card-style {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .icon-style {
    padding-left: 10px;
  }
`

export const TextContent = styled.div`
  padding-right: 16px;
  width: 70%;

  .headline {
    color: var(--gray500);
    margin-bottom: 8px;
    display: block;
    font-weight: 700;
  }
`

export const ImgContainer = styled.div`
  width: 30%;

  .img-content {
    width: 100%;
  }
`

export const BannerImage = styled.div`
  img {
    width: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
  }
`
