import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 100%;
`

export const TabList = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid var(--gray200);
  border-radius: 0.5rem;
`

export const Tab = styled.div<{ current: boolean; disabled: boolean }>`
  position: relative;
  padding: 0.5rem 0;
  cursor: pointer;
  margin: 0.2rem;
  color: var(--gray500);
  font-weight: bold;
  font-size: 0.875rem;
  width: 50%;
  text-align: center;

  ${(props) =>
    props.disabled &&
    `
    color: var(--typography300);
    cursor: not-allowed;
  `}

  ${(props) =>
    props.current &&
    `
    font-weight: bold;
    background-color: var(--primary500);
    border-radius: 0.5rem;
    color: var(--neutral-theme);
  `};
`

export const TabPanel = styled.div`
  padding: 1rem;
  background: var(--tab-panel-border-color);
`
