import React from 'react'

import { Tag } from '@interco/inter-ui/components/Tag'

import * as S from './TagsList.styles'

interface TagsListProps {
  useNow?: boolean
  available?: boolean
  newRestaurant?: boolean
  deliveryUseNow?: boolean
  takeOutUseNow?: boolean
}

export const TagsList = ({
  useNow,
  available,
  newRestaurant,
  deliveryUseNow,
  takeOutUseNow,
}: TagsListProps) => (
  <S.TagsContainer>
    {useNow && (
      <S.TagSpace>
        <Tag color="var(--success100)" colorText="var(--success500)">
          Use agora
        </Tag>
      </S.TagSpace>
    )}
    {!available && (
      <S.TagSpace>
        <Tag color="var(--gray100)" colorText="var(--gray400)">
          Não disponível
        </Tag>
      </S.TagSpace>
    )}
    {newRestaurant && (
      <S.TagSpace>
        <Tag theme="yellow">Novo</Tag>
      </S.TagSpace>
    )}
    {deliveryUseNow && (
      <S.TagSpace>
        <Tag color="var(--primary100)" colorText="var(--primary500)">
          Peça agora
        </Tag>
      </S.TagSpace>
    )}
    {takeOutUseNow && (
      <S.TagSpace>
        <Tag color="var(--turquoise100)" colorText="var(--turquoise500)">
          Retire agora
        </Tag>
      </S.TagSpace>
    )}
  </S.TagsContainer>
)

export const TagsStatus = ({
  useNow,
  available,
  newRestaurant,
  deliveryUseNow,
  takeOutUseNow,
}: TagsListProps) => {
  const statusList = []

  if (useNow) statusList.push('use agora')
  if (!available) statusList.push('indisponível')
  if (newRestaurant) statusList.push('novo')
  if (deliveryUseNow) statusList.push('peça agora')
  if (takeOutUseNow) statusList.push('retire agora')

  return statusList
}
