import { ETrackEvent } from 'enums/ETrackEvent'
import { DefaultFlowTag, SubscriberTag } from 'types/AnalyticsTag'

import { interWbSession } from '@interco/inter-webview-bridge'

export interface BannerTag extends DefaultFlowTag, SubscriberTag {
  content_id?: string
  position?: string
  section?: string
  title?: string
  category?: string
  link?: string
  blocked_OS?: string
}

export const handleTagBanner = async ({
  ref_figma,
  flow,
  screen,
  content_action = '',
  content_id = '',
  position = '',
  section = '',
  title = '',
  category = '',
  link = '',
  blocked_OS = '',
  plan_name = '',
  plan_value = '',
}: BannerTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_BANNER, {
      ref_figma,
      flow,
      screen,
      content_action,
      product: ETrackEvent.T_PRODUCT,

      action_id: 'banner',
      content_id,
      position,
      section,
      title,
      category,
      link,
      blocked_OS,

      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}
