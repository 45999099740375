import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;

  .dividerStyle {
    margin: 0 -2rem;
  }

  .topAnnouncementsStyle {
    margin: 0.9rem 1.5rem 0.1rem;
  }

  .bottomAnnouncementsStyle {
    padding: 1.5rem;
    background-color: var(--gray100);
  }

  .errorStyle {
    width: 100%;
    height: 90vh;
  }
`

export const Header = styled.div`
  background: var(--neutral-theme);
  top: 5rem;
  width: 100%;
  padding: 3rem 1.5rem 0;
`

interface ContainerProps {
  duoGourmetInterCustomer: boolean
}

export const Container = styled.div<ContainerProps>`
  ${(props) => !props.duoGourmetInterCustomer && `margin-bottom: 5rem;`}
  overflow: hidden;
`

export const Content = styled.div`
  overflow: hidden;
  margin-bottom: 1rem;
`

export const CarouselList = styled.div`
  position: relative;
`

export const EmptyResponse = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  text-align: center;
`

export const IconStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gray100);
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
`

export const SubscribeContainer = styled.div`
  margin-top: 1rem;
`
