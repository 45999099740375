import React from 'react'

import './App.css'

import LandingPage from 'pages/LandingPage'
import Home from 'pages/Home'
import RestaurantsDetails from 'pages/RestaurantsDetails/RestaurantsDetails'
import ExperiencesDetails from 'pages/ExperiencesDetails'
import MenuUserRoutes from 'pages/MenuUser/MenuUserRoutes'
import CompleteSubscription from 'pages/Subscription/CompleteSubscription'
import RestaurantsBenefit from 'pages/RestaurantsBenefit'
import Onboarding from 'pages/Onboarding/Onboarding'
import BlackCard from 'pages/BlackCard'
import OccultedRoute from 'pages/OccultedRoute/OccultedRoute'
import Error from 'components/Error'
import AppBar from 'components/AppBar'
import NewRelic from 'utils/NewRelic'
import CampaignDetail from 'pages/CampaignDetail/CampaignDetail'
import RestaurantModality from 'pages/RestaurantsDetails/RestaurantModality'
import RouterPage from 'pages/RouterPage'
import RecommendationsCollection from 'pages/RecommendationsCollection'
import Filters from 'pages/Filters'
import { RestaurantLocation } from 'features/Restaurants/RestaurantLocation'
import { ExperienceLocation } from 'features/Experiences/ExperienceLocation'
import { ErrorBoundary } from 'react-error-boundary'
import { ApiErrors } from 'types/http/ApiError'
import { useConfigs } from 'contexts/ConfigsContext'
import ExperiencesCollection from 'pages/ExperiencesCollection'
import ExperiencesBenefit from 'pages/ExperiencesBenefit'
import RestaurantsRating from 'pages/RestaurantsRating'
import ExperiencesRating from 'pages/ExperiencesRating'
import SelectionsCollection from 'pages/SelectionsCollection'

import { RouteComponentProps, Router } from '@reach/router'

const ErrorPage = (_: RouteComponentProps) => (
  <>
    <AppBar name="Duo Gourmet" goBackNative showHomeAction />
    <Error goBackNative />
  </>
)

const Routes: React.FC = () => {
  const { configs } = useConfigs()
  const myErrorHandler = (error: Error, info: { componentStack: string }) => {
    const err = error as ApiErrors
    err.message = `${err.message} | ${info.componentStack}`
    NewRelic.noticeError(err)
  }

  if (configs.isPending) {
    return <></>
  }

  if (configs.isError) {
    return (
      <ErrorBoundary FallbackComponent={ErrorPage} onError={myErrorHandler}>
        <Router className="container-routers" data-testid="app-route">
          <ErrorPage path="/" default />
        </Router>
      </ErrorBoundary>
    )
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorPage} onError={myErrorHandler}>
      <Router className="container-routers" data-testid="app-route">
        <RouterPage path="/" />
        <LandingPage path="/landing-page" />
        <Onboarding path="/onboarding" />
        <MenuUserRoutes path="/menu/*" />

        <Home path="/home/:tab" />
        <RecommendationsCollection path="/recommendations/:carouselId" />
        <SelectionsCollection path="/selections/:selectionId" />

        <Filters path="/restaurants/filters" />
        <RestaurantsDetails path="/restaurants/:id" />
        <RestaurantLocation path="/restaurants/:id/location" />
        <RestaurantModality path="/restaurants/:id/modality" />
        <RestaurantsBenefit path="/restaurants-benefit" />
        <RestaurantsRating path="/restaurants-rating" />

        <ExperiencesDetails path="/experiences/:id" />
        <ExperienceLocation path="/experiences/:id/location" />
        <ExperiencesBenefit path="/experience-benefit" />
        <ExperiencesRating path="/experiences-rating" />
        <ExperiencesCollection path="/experiences/collection/:categorySlug" />

        <BlackCard path="/blackcard" />
        <CompleteSubscription path="/complete-signed" />
        <CampaignDetail path="/campaign" />
        <OccultedRoute path="/occulted-route" />
      </Router>
    </ErrorBoundary>
  )
}

export default Routes
