import React from 'react'

import { Button } from '@interco/inter-ui/components/Button'

import { ButtonStyle } from './FilterButton.styles'

export interface FilterButtonProps {
  label: string
  icon: React.ReactNode
  handleClick: () => void
  isSelected?: boolean
}

export const FilterButton = ({ label, icon, handleClick, isSelected }: FilterButtonProps) => (
  <ButtonStyle>
    <Button
      icon={icon}
      variant="secondary"
      size="small"
      onClick={handleClick}
      className={`customButton ${isSelected && 'activatedButton'}`}
    >
      {label}
    </Button>
  </ButtonStyle>
)

export default FilterButton
