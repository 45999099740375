import { ETrackEvent } from 'enums/ETrackEvent'
import { SubscriberTag } from 'types/AnalyticsTag'

import { interWbSession } from '@interco/inter-webview-bridge'

interface ExperienceTag extends SubscriberTag {
  event: string
}

interface UseBenefitTag extends ExperienceTag {
  neighborhood: string
  benefit: string
  city: string
}

export const handleHeaderGoBack = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_HEADER, {
      ref_figma: '1',
      flow: ETrackEvent.F_EXPERIENCES,
      screen: ETrackEvent.S_EXPERIENCE_DETAILS,
      action_id: 'back',
      product: ETrackEvent.T_PRODUCT,
    })
    .catch(window.console.log)
}

export const handleExperienceLocation = async ({
  event,
  plan_name = '',
  plan_value = '',
}: ExperienceTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '13',
      flow: ETrackEvent.F_EXPERIENCES,
      screen: ETrackEvent.S_EXPERIENCE_DETAILS,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'abrir mapa',

      event,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleExperienceRules = async ({
  event,
  plan_name = '',
  plan_value = '',
}: ExperienceTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '15',
      flow: ETrackEvent.F_EXPERIENCES,
      screen: ETrackEvent.S_EXPERIENCE_DETAILS,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'regras de uso',

      event,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleUseBenefit = async ({
  event,
  neighborhood = '',
  benefit = '',
  city = '',
  plan_name = '',
  plan_value = '',
}: UseBenefitTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '17',
      flow: ETrackEvent.F_EXPERIENCES,
      screen: ETrackEvent.S_EXPERIENCE_DETAILS,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'regras de uso',

      event,
      neighborhood,
      benefit,
      city,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleAccess = async (event: string) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_ACCESS, {
      ref_figma: '21',
      flow: ETrackEvent.F_EXPERIENCES,
      screen: ETrackEvent.S_EXPERIENCE_DETAILS,
      content_action: 'dado carregado',
      action_id: event,

      event,
    })
    .catch(window.console.log)
}
