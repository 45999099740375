import React from 'react'

import ReactDOM from 'react-dom'

import App from './App'

if (process.env.NODE_ENV === 'development' && process.env.MSW_DISABLED !== '1') {
  const { worker } = require('./config/msw/browser')
  worker.start()
  window.console.info(
    '[MSW] O mock service worker está ativo! Para desabilitar, descomente a linha MSW_DISABLED=1 no arquivo .env',
    '(será necessário reiniciar o comando `yarn start` para que as alterações tenham efeito)',
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
