import React, { useEffect } from 'react'

import queryString from 'query-string'
import Error from 'components/Error'
import useAsync from 'hooks/UseAsync'
import AppBar from 'components/AppBar'
import Divider from 'components/Divider'
import { FixButton } from 'components/FixButton'
import { CollectionListResponse } from 'types/Experiences'
import ExperienceCard from 'components/ExperienceCard/ExperienceCard'
import { ECurrentMainTab } from 'enums/EContexts'

import { RouteComponentProps, useNavigate, useParams } from '@reach/router'
import { Text } from '@interco/inter-ui/components/Text'

import CarouselListShimmer from './ExperiencesCollectionShimmer'
import { CarouselProps } from '../Home/Home.types'
import { handleTagFullList } from '../Home/Home.tags'
import * as S from './ExperiencesCollection.styles'

const ExperiencesCollection = ({ location }: RouteComponentProps) => {
  const params = useParams<{ categorySlug: string }>()

  const [carouselResponse, getCarouselResponse] = useAsync<CollectionListResponse>('get')

  const { city } = location?.state as CarouselProps
  const navigate = useNavigate()

  const requestProps = {
    offset: 0,
    size: 40,
    categories: [params.categorySlug],
    city,
  }

  const fetchCarouselResponse = async () => {
    await getCarouselResponse(
      `${process.env.REACT_APP_API}/listing/experiences?${queryString.stringify({
        ...requestProps,
      })}`,
    ).catch(window.console.log)
  }

  const handleClick = () => {
    handleTagFullList({ section: carouselResponse.data?.title ?? '' })
    navigate(`/home/${ECurrentMainTab.experiences}`)
  }

  useEffect(() => {
    fetchCarouselResponse()
  }, [])

  if (carouselResponse.isError) {
    return (
      <S.Wrapper>
        <AppBar name="Duo Gourmet" goBackNative />
        <Error goBackNative className="errorStyle" />
      </S.Wrapper>
    )
  }

  if (carouselResponse.isSuccess) {
    return (
      <S.Wrapper>
        <AppBar name="Duo Gourmet" />
        <S.Content>
          <Text variant="headline-h3" semiBold className="titleStyle">
            {carouselResponse.data?.title}
          </Text>
          <S.ExperiencesList>
            {carouselResponse.data?.experiences.map((experience) => (
              <ExperienceCard key={`card_${experience.id}}`} experience={experience} />
            ))}
          </S.ExperiencesList>
        </S.Content>
        <Divider color="var(--gray100)" style={{ margin: '0' }} />
        <FixButton label="Conferir a lista completa" width="100%" onClick={handleClick} />
      </S.Wrapper>
    )
  }

  return (
    <S.Wrapper>
      <AppBar name="Duo Gourmet" goBackNative />
      <CarouselListShimmer />
    </S.Wrapper>
  )
}

export default ExperiencesCollection
