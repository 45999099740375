import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  margin-top: 4rem;
  overflow: hidden;

  .titleStyle {
    margin-bottom: 1rem;
  }
`

export const Item = styled.div``

export const Content = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  gap: 0.5rem;
`

export const RestaurantDetails = styled.div`
  display: flex;
  flex-direction: column;
`
