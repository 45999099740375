import React from 'react'

import PromotionalBanner from 'components/PromotionalBanner/PromotionalBanner'
import { IBanner } from 'types/Campaign'
import { BannerTag, handleTagBanner } from 'components/PromotionalBanner/PromotionalBannerTags'

import { Carousel } from '@interco/inter-ui/components/Carousel'

import { Container, DoubleCarousel, SliderTheme } from './PromotionalCarousel.styles'

interface PromotionalCarouselProps {
  banners: IBanner[]
  pessoaFisica: boolean
  canSubscribe: {
    canSubscribe: boolean
    text: string
    button: string
  }
  slidesToShow?: number
  bannerTag: BannerTag
}

const PromotionalCarousel = ({
  banners,
  pessoaFisica,
  canSubscribe,
  slidesToShow = 1,
  bannerTag,
}: PromotionalCarouselProps) => {
  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    verticalMode: false,
  }

  if (banners.length === 1 && banners[0].description?.includes('bônus')) {
    return <></>
  }

  if (slidesToShow > 1 && banners.length > 1) {
    return (
      <DoubleCarousel>
        <Carousel dotsType="medium" {...settings} className="carousel-style">
          {banners.map((banner, index) => (
            <div key={banner.title}>
              <img src={banner.image} alt={banner.imageAlt} />
              {handleTagBanner({
                ...bannerTag,
                position: index.toString(),
                content_id: banner.sort,
                title: banner.tagAnalytics,
                content_action: 'dado carregado',
              })}
            </div>
          ))}
        </Carousel>
      </DoubleCarousel>
    )
  }

  return (
    <Container style={banners.length > 1 ? { paddingBottom: '2.5rem' } : { paddingBottom: '0' }}>
      {banners.length > 1 ? (
        <SliderTheme>
          <Carousel dotsType="medium" {...settings}>
            {banners.map((bannerProps, index) => {
              if (!bannerProps.description?.includes('bônus')) {
                return (
                  <PromotionalBanner
                    banner={bannerProps}
                    pessoaFisica={pessoaFisica}
                    canSubscribe={canSubscribe}
                    key={bannerProps.title}
                    bannerTag={{ ...bannerTag, position: index.toString() }}
                  />
                )
              }
              return ''
            })}
          </Carousel>
        </SliderTheme>
      ) : (
        banners.map((banner) => {
          if (!banner.description?.includes('bônus')) {
            return (
              <PromotionalBanner
                banner={banner}
                pessoaFisica={pessoaFisica}
                canSubscribe={canSubscribe}
                key={banner.title}
                bannerTag={{ ...bannerTag, position: '0' }}
              />
            )
          }
          return ''
        })
      )}
    </Container>
  )
}

export default PromotionalCarousel
