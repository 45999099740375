import React, { createContext, useContext, useState, useMemo } from 'react'

import { PageResponse } from 'types/http/ApiResponse'

export interface BSStateRestaurant extends PageResponse {
  showMapFilter: boolean
  showBottomSheetLocation: boolean
  showBottomSheetState: boolean
  showBottomSheetCooking: boolean
  showBottomSheetAvailability: boolean
  showBottomSheetDistricts: boolean
  showBottomSheetInvalidAssignature: boolean
  showBottomSheetPromotionalBanners: boolean
  showBottomSheetMenu: boolean
}

export type TFiltersBSContext = {
  bsStateParam: BSStateRestaurant
  setBsStateParam: (bsParam: BSStateRestaurant) => void
}

export const FiltersBSContext = createContext<TFiltersBSContext>({} as TFiltersBSContext)
export const useBSState = () => useContext(FiltersBSContext)

const FiltersBSProvider: React.FC = ({ children }) => {
  const [bsStateParam, setBsStateParam] = useState<BSStateRestaurant>({
    showMapFilter: false,
    showBottomSheetLocation: false,
    showBottomSheetState: false,
    showBottomSheetCooking: false,
    showBottomSheetAvailability: false,
    showBottomSheetDistricts: false,
    showBottomSheetInvalidAssignature: false,
    showBottomSheetPromotionalBanners: false,
    showBottomSheetMenu: false,
  } as BSStateRestaurant)

  const value = useMemo(() => ({ bsStateParam, setBsStateParam }), [bsStateParam])

  return <FiltersBSContext.Provider value={value}>{children}</FiltersBSContext.Provider>
}

export default FiltersBSProvider
