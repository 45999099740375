import React, { useState, useEffect } from 'react'

import AppBar from 'components/AppBar'
import { AppContainer } from 'App.styles'
import { UseEventRating, UseEvents } from 'types/ExperienceEvaluation'
import useAsync from 'hooks/UseAsync'
import Divider from 'components/Divider'
import { BottomSheet } from 'components/BottomSheet'
import { useConfigs } from 'contexts/ConfigsContext'

import StarFillIcon from '@interco/icons/orangeds/XL/star-fill'
import StarIcon from '@interco/icons/orangeds/XL/star'
import { RouteComponentProps } from '@reach/router'
import { Text } from '@interco/inter-ui/components/Text'
import { Textarea } from '@interco/inter-ui/components/Textarea'
import { Input } from '@interco/inter-ui/components/Input'
import { Button } from '@interco/inter-ui/components/Button'

import * as S from './RestaurantsRating.styles'
import { handleTagHeader, handleTagSendRating } from './RestaurantsRating.tags'
import RatingResponse from './RatingResponse'

const iconSize = { width: 30, height: 30 }

const RestaurantsRating = ({ location }: RouteComponentProps) => {
  const utilization = location?.state as UseEvents
  const { configs } = useConfigs()
  const [place, setPlace] = useState(0)
  const [food, setFood] = useState(0)
  const [treatment, setTreatment] = useState(0)
  const [comment, setComment] = useState('')
  const [commentCounter, setCommentCounter] = useState(0)
  const [total, setTotal] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [disableButton, setDisableButton] = useState(true)
  const [evaluation, postEvaluation] = useAsync<UseEventRating>('post')
  const [showBottomSheet, setShowBottomSheet] = useState(false)
  const [bottomSheetText, setBottomSheetText] = useState('')
  const [canSubmit, setCanSubmit] = useState(false)

  const handleSubmit = async () => {
    if (canSubmit) {
      try {
        await postEvaluation(`app-pf/foods/orders/v1/useEvents/${utilization.sequential}/rate`, {
          subscription: utilization.subscription,
          restaurantId: utilization.restaurantId,
          billing: {
            total: total * 100,
            discount: discount * 100,
          },
          rating: {
            place,
            food,
            treatment,
            comment,
          },
        })
        handleTagSendRating({
          restaurant: utilization.restaurantName,
          service_note: treatment.toString(),
          delivery_note: place.toString(),
          plate_note: food.toString(),
          plan_name: configs.subscriber.planName,
          plan_value: configs.subscriber.planValue,
        })
      } catch (error) {
        window.console.error(`error: ${error}`)
      }
    } else {
      setShowBottomSheet(true)
    }
  }

  const onChangeTotal = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setTotal(Number((e.target as HTMLInputElement).value.replace(/[^\d,]/g, '').replace(',', '.')))
  }

  const onChangeDiscount = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscount(
      Number((e.target as HTMLInputElement).value.replace(/[^\d,]/g, '').replace(',', '.')),
    )
  }

  const onChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value)
  }

  const bsContent = (
    <div style={{ marginTop: '12px' }}>
      <Text variant="body-3">{bottomSheetText}</Text>
    </div>
  )

  const handleBSClose = () => setShowBottomSheet(false)

  useEffect(() => {
    setCommentCounter(comment.length)
  }, [comment])

  useEffect(() => {
    if (treatment && food && place) {
      setDisableButton(false)
      setCanSubmit(true)
    }

    if (discount > total) {
      setBottomSheetText('O valor economizado não pode ser maior que o total pago na conta.')
      setCanSubmit(false)
    }

    if (total && !discount) {
      setBottomSheetText('Preencha o valor economizado.')
      setCanSubmit(false)
    }
  }, [discount, food, place, total, treatment])

  if (evaluation.isError) {
    return <RatingResponse restaurant={utilization.restaurantName} success={false} />
  }

  if (evaluation.isSuccess) {
    return <RatingResponse restaurant={utilization.restaurantName} success />
  }

  if (utilization.userRating.rating) {
    return (
      <>
        <AppBar name="Avaliação" backTag={handleTagHeader} />
        <AppContainer fullHeight style={{ paddingTop: 60 }}>
          <S.HeaderStyled>
            <Text variant="body-3">Sua experiência em</Text>
            <Text variant="headline-h3" color="typography" semiBold as="p">
              {utilization.restaurantName}
            </Text>
          </S.HeaderStyled>
          <S.ReviewContainer>
            <StarRating label="Atendimento" rating={utilization.userRating.rating.treatment} />
            <StarRating label="Ambiente" rating={utilization.userRating.rating.place} />
            <StarRating label="Prato" rating={utilization.userRating.rating.food} />
            {utilization.userRating.billing.total > 0 && (
              <>
                <Divider type="dashed" style={{ marginTop: '20px' }} />
                <S.TextContainer style={{ marginTop: '1rem' }}>
                  <Text variant="caption-1">Valor pago:</Text>
                  <Text variant="caption-1" colorWeight={500} bold>
                    {utilization.userRating.billing.total.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Text>
                </S.TextContainer>
                <S.TextContainer style={{ margin: '.5rem 0 1rem 0' }}>
                  <Text variant="caption-1">Valor economizado:</Text>
                  <Text variant="caption-1" colorWeight={500} bold>
                    {utilization.userRating.billing.discount.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Text>
                </S.TextContainer>
              </>
            )}
            {utilization.userRating.rating.comment && (
              <>
                <Divider type="dashed" style={{ marginBottom: '2rem' }} />
                <Text variant="caption-1">Seu comentário:</Text>
                <Text variant="body-3" colorWeight={500} bold style={{ marginTop: '.5rem' }}>
                  {utilization.userRating.rating.comment}
                </Text>
              </>
            )}
          </S.ReviewContainer>
        </AppContainer>
      </>
    )
  }

  return (
    <>
      <AppBar name="Avaliação" />
      <AppContainer fullHeight style={{ paddingTop: 60 }}>
        <S.HeaderStyled>
          <Text variant="body-3">Conte como foi sua experiência em</Text>
          <Text variant="headline-h3" semiBold as="p">
            {utilization.restaurantName}
          </Text>
        </S.HeaderStyled>
        <S.ReviewContainer>
          <S.StarRatingStyled>
            <Text variant="body-3">Atendimento</Text>
            <S.StarsStyled>
              {[...Array(5)].map((_, index) => {
                index += 1
                return (
                  <button
                    type="button"
                    key={`atendimento-${0 + index}`}
                    onClick={() => setTreatment(index)}
                  >
                    {index > treatment ? (
                      <StarIcon {...iconSize} color="var(--gray300)" />
                    ) : (
                      <StarFillIcon {...iconSize} color="var(--alert500)" />
                    )}
                  </button>
                )
              })}
            </S.StarsStyled>
          </S.StarRatingStyled>
          <S.StarRatingStyled>
            <Text variant="body-3">Ambiente</Text>
            <S.StarsStyled>
              {[...Array(5)].map((_, index) => {
                index += 1
                return (
                  <button
                    type="button"
                    key={`ambiente-${0 + index}`}
                    onClick={() => setPlace(index)}
                  >
                    {index > place ? (
                      <StarIcon {...iconSize} color="var(--gray300)" />
                    ) : (
                      <StarFillIcon {...iconSize} color="var(--alert500)" />
                    )}
                  </button>
                )
              })}
            </S.StarsStyled>
          </S.StarRatingStyled>
          <S.StarRatingStyled>
            <Text variant="body-3">Prato</Text>
            <S.StarsStyled>
              {[...Array(5)].map((_, index) => {
                index += 1
                return (
                  <button type="button" key={`prato-${0 + index}`} onClick={() => setFood(index)}>
                    {index > food ? (
                      <StarIcon {...iconSize} color="var(--gray300)" />
                    ) : (
                      <StarFillIcon {...iconSize} color="var(--alert500)" />
                    )}
                  </button>
                )
              })}
            </S.StarsStyled>
          </S.StarRatingStyled>
          <Input
            id="total"
            label="Total pago"
            placeholder="0,00"
            type="currency"
            value={total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            onlyNumber
            style={{ marginTop: 20 }}
            onChange={onChangeTotal}
          />
          <Input
            id="discount"
            label="Total economizado"
            placeholder="0,00"
            type="currency"
            value={discount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            onlyNumber
            onChange={onChangeDiscount}
          />
          <Textarea
            id="comment"
            label="Deixe aqui seu comentário"
            rows={6}
            maxLength={500}
            onChange={onChangeComment}
          />
          <S.CommentCounter>{commentCounter}/500</S.CommentCounter>
          <Button
            id="submitButton"
            disabled={disableButton}
            onClick={handleSubmit}
            isLoading={evaluation.isPending}
            style={{ margin: '1.5rem 0' }}
          >
            Enviar avaliação
          </Button>
        </S.ReviewContainer>
        <BottomSheet
          showBottomSheet={showBottomSheet}
          onDismiss={handleBSClose}
          headerLabel="Valor economizado"
          headerBtLabel="Fechar"
          headerBtHandler={handleBSClose}
          footerBtLabel="Entendi"
          footerBtHandler={handleBSClose}
          bsContent={bsContent}
        />
      </AppContainer>
    </>
  )
}

interface StarRatingProps {
  label: string
  rating: number
}

const StarRating = ({ label, rating }: StarRatingProps) => (
  <S.StarRatingStyled>
    <Text variant="body-3">{label}</Text>
    <S.StarsStyled>
      {[...Array(5)].map((star, index) => {
        index += 1
        return index > rating ? (
          <StarFillIcon {...iconSize} color="var(--gray300)" key={`rate-${0 + index}`} />
        ) : (
          <StarFillIcon {...iconSize} color="var(--alert500)" key={`rate-${0 + index}`} />
        )
      })}
    </S.StarsStyled>
  </S.StarRatingStyled>
)

export default RestaurantsRating
