import { ETrackEvent } from 'enums/ETrackEvent'

import { interWbSession } from '@interco/inter-webview-bridge'

export const handleTagHeaderOnboarding = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_HEADER, {
      ref_figma: '8',
      flow: ETrackEvent.F_BLACKCARD,
      screen: ETrackEvent.S_BLACKCARD_ONBOARDING,
      action_id: 'back',
      product: ETrackEvent.T_PRODUCT,
    })
    .catch(window.console.log)
}

export const handleTagNext = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '1',
      flow: ETrackEvent.F_BLACKCARD,
      screen: ETrackEvent.S_BLACKCARD_ONBOARDING,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'continuar',
    })
    .catch(window.console.log)
}

interface BlackcardStepTags {
  ref_figma: string
  step: string
}

export const handleTagHeaderStep = async ({ ref_figma, step }: BlackcardStepTags) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_HEADER, {
      ref_figma,
      flow: ETrackEvent.F_BLACKCARD,
      screen: `${ETrackEvent.S_BLACKCARD_STEP}${step}`,
      action_id: 'back',
      product: ETrackEvent.T_PRODUCT,
    })
    .catch(window.console.log)
}

export const handleTagRequestStep = async ({ ref_figma, step }: BlackcardStepTags) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma,
      flow: ETrackEvent.F_BLACKCARD,
      screen: `${ETrackEvent.S_BLACKCARD_STEP}${step}`,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'solicitar cartão inter black',
    })
    .catch(window.console.log)
}

export const handleTagBSClose = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '12',
      flow: ETrackEvent.F_BLACKCARD,
      screen: ETrackEvent.S_BS_BLACKCARD,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'fechar',
    })
    .catch(window.console.log)
}

export const handleTagBSNext = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '3',
      flow: ETrackEvent.F_BLACKCARD,
      screen: ETrackEvent.S_BS_BLACKCARD,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'entendi',
    })
    .catch(window.console.log)
}

export const handleTagUpdateData = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '5',
      flow: ETrackEvent.F_BLACKCARD,
      screen: `${ETrackEvent.S_BLACKCARD_STEP}2`,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'alterar dados cadastrais',
    })
    .catch(window.console.log)
}

export const handleTagHome = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '6',
      flow: ETrackEvent.F_BLACKCARD,
      screen: `${ETrackEvent.S_BLACKCARD_STEP}3`,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'voltar para o duo gourmet',
    })
    .catch(window.console.log)
}

export const handleTagCards = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '7',
      flow: ETrackEvent.F_BLACKCARD,
      screen: `${ETrackEvent.S_BLACKCARD_STEP}3`,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'ir para cartões',
    })
    .catch(window.console.log)
}
