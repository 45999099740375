import ListItemWithSelection from './ListItemWithSelection'

export interface ListItemWithSelectionProps extends React.HTMLAttributes<unknown> {
  icon?: React.SVGAttributes<SVGSVGElement>
  arrow?: boolean
  label: string
  disabled?: boolean
  selection?: string
}

export default ListItemWithSelection
