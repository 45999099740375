export enum EUtilization {
  PRESENTIAL = 'Presencial',
}

export interface UseEvents {
  restaurantId: string
  restaurantName: string
  sequential: string
  subscription: string
  date: string
  active: boolean
  canRate: boolean
  userRating: Record<string, string>
  modality: string
}

export default interface Utilization {
  generalInformation: string
  termsUrl: string
  useEvents: UseEvents[]
  totalSaved: string
  userName: string
  bankingCustomer: boolean
}
