import React from 'react'

import AppBar from 'components/AppBar'
import ListItem from 'components/ListItem'
import Divider from 'components/Divider'
import { AppContainer } from 'App.styles'
import { bridgeNative } from 'App'

import { RouteComponentProps } from '@reach/router'

interface DocumentsProps {
  title: string
  url: string
}

const TermsAndConditions = (_: RouteComponentProps) => {
  const screenName = 'Termos'

  const openDocuments = async ({ title, url }: DocumentsProps) =>
    bridgeNative.execute({
      action: 'displayPdf',
      metadata: {
        title,
        url,
      },
    })

  const listItems = [
    {
      label: 'Termos e condições de uso',
      arrow: true,
      contentMenu: 'termos de uso',
      navigateInfo: () =>
        openDocuments({
          title: 'Termos e condições de uso',
          url: 'https://marketplace.bancointer.com.br/foods/duoterm.pdf',
        }),
    },
    {
      label: 'Regulamento Duo + Cartão Black',
      arrow: true,
      contentMenu: 'termos cartao black',
      navigateInfo: () =>
        openDocuments({
          title: 'Regulamento Duo + Cartão Black',
          url: 'https://marketplace.bancointer.com.br/foods/blackcardterm.pdf',
        }),
    },
  ]

  return (
    <>
      <AppBar name={screenName} />
      <AppContainer fullHeight style={{ paddingTop: '4rem' }}>
        {listItems.map((item) => (
          <div key={item.label}>
            <ListItem
              label={item.label}
              arrow={item.arrow}
              data-testid={item.contentMenu}
              onClick={item.navigateInfo}
            />
            <Divider />
          </div>
        ))}
      </AppContainer>
    </>
  )
}

export default TermsAndConditions
