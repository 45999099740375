import { ETrackEvent } from 'enums/ETrackEvent'

import { interWbSession } from '@interco/inter-webview-bridge'

interface BenefitSuccess {
  event: string
  date: string
}

export const handleRateLater = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '1',
      flow: ETrackEvent.F_EXPERIENCES,
      screen: ETrackEvent.S_BENEFIT_VALIDATED,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'avaliar mais tarde',
    })
    .catch(window.console.log)
}

export const handleRateNow = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '2',
      flow: ETrackEvent.F_EXPERIENCES,
      screen: ETrackEvent.S_BENEFIT_VALIDATED,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'avaliar experiencia',
    })
    .catch(window.console.log)
}

export const handleBenefitSuccess = async ({ event, date }: BenefitSuccess) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_SUCCESS, {
      ref_figma: '3',
      flow: ETrackEvent.F_EXPERIENCES,
      screen: ETrackEvent.S_BENEFIT_VALIDATED,
      action_id: 'Código disponível',

      event,
      date,
    })
    .catch(window.console.log)
}
