import styled from 'styled-components'

export const ListItemStyles = styled.div`
  margin: 13px 0px;
  border-bottom: transparent;
  border-left: transparent;
  display: flex;
  justify-content: space-between;
`

export const ListItemContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ListItemIconStyles = styled.div`
  margin-right: 20px;
  line-height: 0;
`
