import { ETrackEvent } from 'enums/ETrackEvent'

import { interWbSession } from '@interco/inter-webview-bridge'

import { ICollectionBt, ICollectionCard, IFavoriteTag, TabsTag } from './Home.types'

export const handleTagHome = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_HEADER, {
      ref_figma: '1',
      flow: ETrackEvent.F_HOME_SUB,
      screen: ETrackEvent.S_HOME_SUB,
      action_id: 'menu',
      product: ETrackEvent.T_PRODUCT,
    })
    .catch(window.console.log)
}

export const handleTagTabs = async ({ tab }: TabsTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '2',
      flow: ETrackEvent.F_HOME_SUB,
      screen: ETrackEvent.S_HOME_SUB,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'abas',

      tab,
    })
    .catch(window.console.log)
}

export const handleTagLocation = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '3',
      flow: ETrackEvent.F_HOME_SUB,
      screen: ETrackEvent.S_HOME_SUB,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'mudar cidade',
    })
    .catch(window.console.log)
}

export const handleCollectionCard = async ({
  flow,
  screen,

  restaurant,
  cooking,
  status,
  neighborhood,
  distance,
  modality,
  favorite,

  subscriber,
  plan_name = '',
  plan_value = '',
}: ICollectionCard) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '4',
      flow,
      screen,
      ref_type: 'card',
      content_action: 'toque',
      action_id: 'visualizar restaurante',

      restaurant,
      cooking,
      status,
      neighborhood,
      distance,
      modality,
      favorite,

      subscriber,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagFavorite = async ({
  flow,
  screen,
  favorite,
  restaurant,
  neighborhood,
  distance,
  cooking,
  modality,
  subscriber,
  plan_name = '',
  plan_value = '',
}: IFavoriteTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '8',
      flow,
      screen,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'favoritar',

      favorite,
      restaurant,
      neighborhood,
      distance,
      cooking,
      modality,

      subscriber,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagFullList = async ({ section }: ICollectionBt) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '12',
      flow: ETrackEvent.F_HOME_SUB,
      screen: ETrackEvent.S_COLLECTION_LIST,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'conferir lista completa',

      section,
    })
    .catch(window.console.log)
}

export const handleTagAccess = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_ACCESS, {
      ref_figma: '13',
      flow: ETrackEvent.F_HOME_SUB,
      screen: ETrackEvent.S_HOME_SUB,
      content_action: 'dado carregado',
      action_id: 'acessos a home',
    })
    .catch(window.console.log)
}

export const handleTagGoBack = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_HEADER, {
      ref_figma: '14',
      flow: ETrackEvent.F_HOME_SUB,
      screen: ETrackEvent.S_HOME_SUB,
      action_id: 'back',
      product: ETrackEvent.T_PRODUCT,
    })
    .catch(window.console.log)
}
