import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
`

export const SliderTheme = styled.div`
  .slick-list {
    .slick-track {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

export const CardStyle = styled.div`
  .slick-slide {
    margin-left: 1rem;
  }
`

export const DoubleCarousel = styled.div`
  width: 100%;
  padding-bottom: 2rem;

  .slick-slider .slick-dots {
    bottom: -1.5rem;
  }

  .slick-list {
    padding-left: 1.5rem;
  }

  .slick-slide {
    margin-right: -1rem;
  }

  .carousel-style {
    margin: 0 -24px;
  }

  img {
    width: 85%;
  }
`
