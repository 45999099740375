import React, { useEffect, useState } from 'react'

import { useDebouncedCallback } from 'use-debounce'
import { BottomSheet } from 'components/BottomSheet'
import { StateCitiesObject } from 'types/Filters'
import { useBSState } from 'contexts/FiltersBottomsheetContext'
import { useFilters } from 'contexts/FiltersContext'
import { useConfigs } from 'contexts/ConfigsContext'

import SearchIcon from '@interco/icons/orangeds/XL/search'
import { Text } from '@interco/inter-ui/components/Text'
import { Input } from '@interco/inter-ui/components/Input'
import ChevronRightIcon from '@interco/icons/orangeds/XL/chevron-right'

import { handleTagSearch, handleTagState } from './LocationFilterTags'
import * as S from './LocationFilter.styles'
import { StateFilterProps, StateSelectionProps } from './LocationFilter.types'

export const StateFilter = ({
  statesAndCities,
  fetchHomeResponse,
  actualState,
  tempState,
  setTempState,
}: StateFilterProps) => {
  const { queryParam, setQueryParam } = useFilters()
  const { bsStateParam, setBsStateParam } = useBSState()
  const { configs } = useConfigs()

  const iconSize = { width: 20, height: 20 }

  const [searchText, setSearchText] = useState('')
  const [states, setStates] = useState<StateSelectionProps>({
    statesAndCities: JSON.parse(JSON.stringify(statesAndCities)),
  })
  const [selectedState, setSelectedState] = useState<StateCitiesObject>({
    label: '',
    selected: false,
    value: '',
    cities: [],
  })

  const getSelectedState = (newState?: string) => {
    let stateObject = statesAndCities.filter((state) => state.value === newState)
    if (stateObject && stateObject.length === 0) {
      stateObject = statesAndCities.filter((state) => state.selected)
    }
    if (stateObject[0].cities.length === 1 && !stateObject[0].cities[0].selected) {
      stateObject[0].cities[0].selected = true
      onFilter(stateObject[0].cities[0].value)
    }
    setSelectedState(stateObject[0])
  }

  useEffect(() => {
    getSelectedState(tempState)
  }, [tempState])

  const handleChange = (state: string) => {
    handleTagState({
      content_state: state,
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    }).catch(window.console.error)
    setTempState(state)
    setQueryParam({ ...queryParam, state })
    setBsStateParam({ ...bsStateParam, showBottomSheetLocation: true })
  }

  const onFilter = async (newCity?: string) => {
    fetchHomeResponse(newCity || selectedState.cities?.filter((d) => d.selected)[0]?.value)
    onClose()
  }

  const onClose = () => {
    setBsStateParam({
      ...bsStateParam,
      showBottomSheetLocation: false,
      showBottomSheetState: false,
    })
  }

  const onDismiss = () => {
    getSelectedState(actualState)
    onClose()
    setTempState(actualState)
    setQueryParam({ ...queryParam, state: actualState })
  }

  const handleSearch = useDebouncedCallback(async (value: string) => {
    if (value.length === 0) {
      setStates({ statesAndCities })
    } else {
      const filteredStates = statesAndCities.filter((state) =>
        state.label.toLowerCase().includes(searchText.toLowerCase()),
      )
      handleTagSearch({ search_term: value })
      setStates({ statesAndCities: filteredStates })
    }
  }, 500)

  const bsContent = (
    <S.Container>
      <S.SearchInput>
        <Input
          iconLeft={<SearchIcon {...iconSize} color="var(--primary500)" className="iconStyle" />}
          value={searchText}
          type="search"
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
              e.currentTarget.blur()
            }
          }}
          placeholder="Pesquisar"
          className="inputStyle"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleSearch(e.target.value ?? '')
            setSearchText(e.target.value)
          }}
        />
      </S.SearchInput>

      {states.statesAndCities.map((state) => (
        <S.StateItem key={state.label} onClick={() => handleChange(state.value)}>
          <Text variant="caption-1" color="typography" colorWeight={500} bold>
            {state.label}
          </Text>
          <ChevronRightIcon
            width={18}
            height={18}
            color="var(--primary500)"
            className="icon-style"
          />
        </S.StateItem>
      ))}
    </S.Container>
  )

  return (
    <BottomSheet
      showBottomSheet={bsStateParam?.showBottomSheetState}
      onDismiss={onDismiss}
      headerLabel="Estados"
      headerBtLabel="Fechar"
      headerBtHandler={onClose}
      bsContent={bsContent}
    />
  )
}

export default StateFilter
