import { numeric } from './Utils'

export type MaskType =
  | 'alphaNumeric'
  | 'currency'
  | 'cpf'
  | 'checkingAccount'
  | 'percent'
  | 'removeSpecialCharacters'
  | 'numeric'

/**
 * Apply a mask to the value.
 *
 * Supported MaskTypes:
 * - alphaNumeric
 * - currency
 * - cpf (allows to format while user is typing)
 * - checkingAccount
 *
 * @param value
 * @param maskType 'alphaNumeric'
 * @returns value with the mask applied.
 *
 * @example
 * applyMask('@abR4cad4@br4', 'alphaNumeric') // abR4cad4br4
 * applyMask(123.45, 'currency') // R$ 123,45
 * applyMask('12345678900', 'cpf') // 123.456.789-00
 * applyMask('1234567', 'checkingAccount') // 123456-7
 * applyMask('99', 'percent') // 99%
 * applyMask('ABCD123_!@ #efgh', 'removeSpecialCharacters') // abcd123efgh
 */
export function applyMask(value: string | number, maskType: MaskType): string {
  switch (maskType) {
    case 'alphaNumeric':
      return alphaNumericMask(value.toString())
    case 'currency':
      return currencyMask(value.toString())
    case 'cpf':
      return maskCPF(value.toString())
    case 'percent':
      return maskPercent(value.toString())
    case 'checkingAccount':
      return maskCheckingAccount(value.toString())
    case 'removeSpecialCharacters':
      return removeSpecialCharacters(value.toString())
    case 'numeric':
      return numeric(value.toString())
    default:
      throw new Error(`Mask type ${maskType} not supported.`)
  }
}

function alphaNumericMask(value: string) {
  return value.replace(/[^\w]/gi, '')
}

function currencyMask(value: string) {
  const newValue = value.toString().replace(/\D/g, '')

  if (newValue === '') return newValue

  const formatter = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

  // Divided by 100 because all values are stored as integer, without dots. E.g. 5445 is R$ 54,45
  return formatter.format(Number(newValue) / 100)
}

const maskPercent = (value: string): string => {
  let newValue = value.replace(/\D/g, '')
  if (!newValue) return value
  newValue = newValue.replace(/(\d{1,})/, '$1%')
  return newValue
}

/**
 * We use multiple replaces to allow format while user is typing.
 * @param value
 * @returns formatted CPF
 */
const maskCPF = (value: string): string => {
  let newValue = value.replace(/\D/g, '')

  newValue = newValue.substring(0, 11)
  newValue = newValue.replace(/(\d{3})(\d)/, '$1.$2')
  newValue = newValue.replace(/(\d{3})(\d)/, '$1.$2')
  newValue = newValue.replace(/(\d{3})(\d{1,2})$/, '$1-$2')

  return newValue
}

const maskCheckingAccount = (value: string): string => {
  let newValue = value.replace(/\D/g, '')

  newValue = newValue.replace(/(\d)(\d{1})$/, '$1-$2')

  return newValue
}

const removeSpecialCharacters = (value: string): string => {
  const newValue = value.replace(/[^a-zA-Z0-9]+/gm, '')

  return newValue
}
