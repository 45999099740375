import React, { useCallback, useEffect } from 'react'

import queryString from 'query-string'
import { AppContainer } from 'App.styles'
import Divider from 'components/Divider'
import ImgSuccess from 'assets/imgs/blackcard/success.svg'
import HomeAnnouncements from 'features/Home/HomeAnnouncements/HomeAnnouncements'
import { ETrackEvent } from 'enums/ETrackEvent'
import useAsync from 'hooks/UseAsync'
import { RestaurantUseEvents } from 'types/Restaurant'
import { RestaurantValidation } from 'types/Validation'
import AppBar from 'components/AppBar'
import Error from 'components/Error'
import { ECurrentMainTab } from 'enums/EContexts'
import { useFilters } from 'contexts/FiltersContext'

import ScheduleIcon from '@interco/icons/orangeds/XL/agenda'
import LocationIcon from '@interco/icons/orangeds/SM/location'
import UserAccountIcon from '@interco/icons/orangeds/MD/user-account'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { Alert } from '@interco/inter-ui/components/Alert'

import * as S from './RestaurantsBenefit.styles'
import * as T from './RestaurantsBenefit.tags'
import RestaurantBenefitShimmer from './RestaurantBenefitShimmer'

const RestaurantsBenefit = ({ location }: RouteComponentProps) => {
  const navigate = useNavigate()
  const { queryParam } = useFilters()

  const validationBenefit = location?.state as RestaurantValidation
  const [userRestaurantEvent, getUserRestaurantEvent] = useAsync<RestaurantUseEvents>('get')

  const iconProps = { width: 24, height: 24, color: 'var(--gray500)', className: 'item-icon' }

  useEffect(() => {
    fetchBenefit()
  }, [])

  const fetchBenefit = useCallback(async () => {
    try {
      const res = await getUserRestaurantEvent(
        `${process.env.REACT_APP_API}/useEvents/${validationBenefit.restaurantId}/${
          validationBenefit.sequential
        }?${queryString.stringify({
          city: queryParam?.city,
        })}`,
      )

      T.handleTagLoad({
        restaurant: res.restaurantName,
        date: res.useDate,
      })
    } catch (error) {
      window.console.log(error)
    }
  }, [])

  if (userRestaurantEvent?.isError) {
    return (
      <>
        <AppBar name="Duo Gourmet" />
        <Error />
      </>
    )
  }

  if (userRestaurantEvent?.isPending) {
    return <RestaurantBenefitShimmer />
  }

  if (userRestaurantEvent?.isSuccess) {
    return (
      <S.Wrapper>
        <S.Container>
          <Text variant="headline-h3" semiBold>
            Duo Gourmet
          </Text>
          <S.SuccessImg>
            <img src={ImgSuccess} alt="" className="success-img" />
          </S.SuccessImg>
          <Text variant="headline-h2" semiBold>
            Código disponível
          </Text>
          <Text variant="body-2" className="description-text">
            Mostre este código ao garçom na hora de pagar para garantir o seu benefício.
          </Text>
          <S.BenefitInfo>
            <div className="title-text">
              <Text variant="headline-h2" semiBold>
                {userRestaurantEvent.data?.sequential}
              </Text>
            </div>
            <Divider />
            <div className="list-item">
              <UserAccountIcon {...iconProps} />
              <Text variant="body-3">{userRestaurantEvent.data?.userName}</Text>
            </div>
            <div className="list-item">
              <LocationIcon {...iconProps} />
              <Text variant="body-3">{userRestaurantEvent.data?.restaurantName}</Text>
            </div>
            <div className="list-item">
              <ScheduleIcon {...iconProps} />
              <Text variant="body-3">{userRestaurantEvent.data?.useDate}</Text>
            </div>
            <div className="alert-style">
              <Alert type="grayscale">
                Necessário que o titular da assinatura esteja presente para a utilização do
                benefício, a assinatura é pessoal e intransferível
              </Alert>
            </div>
          </S.BenefitInfo>
        </S.Container>
        <HomeAnnouncements
          banners={userRestaurantEvent.data?.banners}
          canSubscribe={userRestaurantEvent.data?.canSubscribe}
          pessoaFisica={userRestaurantEvent.data?.pessoaFisica}
          className="banner-carousel"
          bannerTag={{
            ref_figma: '5',
            flow: ETrackEvent.F_USE_BENEFIT,
            screen: ETrackEvent.S_BENEFIT_VALIDATED,
            subscriber: `${userRestaurantEvent.data?.duoGourmetInterCustomer}`,
          }}
        />
        <AppContainer>
          {userRestaurantEvent.data?.modality.toLocaleLowerCase() === 'presencial' &&
            userRestaurantEvent.data?.canRate && (
              <>
                <Button
                  style={{ width: '100%' }}
                  data-testid="experience-button"
                  onClick={() => {
                    T.handleTagPrimaryBt({ action_id: 'avaliar experiência' })
                    navigate('/restaurants-rating', { state: validationBenefit })
                  }}
                >
                  Avaliar experiência
                </Button>
                <Button
                  data-testid="later-button"
                  variant="secondary"
                  style={{ width: '100%', marginTop: '1rem' }}
                  onClick={() => {
                    T.handleTagSecondaryBt({ action_id: 'avaliar mais tarde' })
                    navigate(`/home/${ECurrentMainTab.restaurants}`)
                  }}
                >
                  Avaliar mais tarde
                </Button>
              </>
            )}
          {userRestaurantEvent.data?.modality.toLocaleLowerCase() !== 'presencial' && (
            <Button
              data-testid="restaurants-button"
              style={{ width: '100%', marginTop: '1rem' }}
              onClick={() => {
                T.handleTagPrimaryBt({ action_id: 'ver restaurantes' })
                navigate(`/home/${ECurrentMainTab.restaurants}`)
              }}
            >
              Ver restaurantes
            </Button>
          )}
          {!userRestaurantEvent.data?.canRate && userRestaurantEvent.data?.userRating?.rating && (
            <>
              <Button
                data-testid="avaliation-button"
                style={{ width: '100%' }}
                onClick={() => {
                  T.handleTagPrimaryBt({ action_id: 'ver avaliação' })
                  navigate('/restaurants-rating', { state: validationBenefit })
                }}
              >
                Ver avaliação
              </Button>
              <Button
                variant="secondary"
                data-testid="restaurants-button"
                style={{ width: '100%', marginTop: '1rem' }}
                onClick={() => {
                  T.handleTagSecondaryBt({ action_id: 'ver restaurantes' })
                  navigate(`/home/${ECurrentMainTab.restaurants}`)
                }}
              >
                Ver restaurantes
              </Button>
            </>
          )}
        </AppContainer>
      </S.Wrapper>
    )
  }

  return null
}

export default RestaurantsBenefit
