import styled from 'styled-components'

export const ExperienceLocation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1.5rem;

  span {
    padding: 0 1rem;
  }
`

export const LocationPin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gray100);
  border-radius: 50%;
  width: 55px;
  height: 45px;
`

export const LocationContent = styled.div`
  align-self: center;
  width: 80%;
`

export const LocationText = styled.div`
  text-wrap: nowrap;
  align-self: center;
  width: 100%;

  .wrap-text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 70vw;
  }

  .line-break {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    width: 70vw;
  }
`

export const ArrowContent = styled.div`
  align-self: center;
`
