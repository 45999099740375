import React, { useEffect, useState } from 'react'

import { FixButton } from 'components/FixButton'
import { useConfigs } from 'contexts/ConfigsContext'
import { handleTagBackToTop } from 'pages/Home/RestaurantsTab/RestaurantsTab.tags'

import ArrowUpIcon from '@interco/icons/orangeds/XL/arrow-up'

const BackToTopBt = () => {
  const [showBackToTop, setShowBackToTop] = useState(false)
  const { configs } = useConfigs()

  const handleBackTop = () => {
    handleTagBackToTop({
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    })
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 100)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <FixButton
      icon={<ArrowUpIcon width={14} height={14} color="var(--neutral-theme)" />}
      label="Voltar ao topo"
      onClick={handleBackTop}
      background="transparent"
      border="none"
      size="small"
      className={
        showBackToTop
          ? 'animate__animated animate__bounceInUp'
          : 'animate__animated animate__backOutDown'
      }
    />
  )
}

export default BackToTopBt
