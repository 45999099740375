import React from 'react'

import LocationIcon from '@interco/icons/orangeds/SM/location'
import ArrowIcon from '@interco/icons/orangeds/XL/chevron-right'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './AddressButton.styles'

interface AddressButtonProps {
  title: string
  address: string
  location: string
  onClickHandler: () => void
}

const AddressButton = ({ title, address, location, onClickHandler }: AddressButtonProps) => (
  <S.ExperienceLocation onClick={onClickHandler}>
    <S.LocationPin>
      <LocationIcon width={24} height={24} color="var(--gray500)" />
    </S.LocationPin>
    <S.LocationContent>
      <S.LocationText>
        <Text color="typography" variant="caption-1" bold colorWeight={500} className="wrap-text">
          {title}
        </Text>
      </S.LocationText>
      <S.LocationText>
        <Text color="typography" variant="caption-1" className="line-break">
          {address}
        </Text>
      </S.LocationText>
      <S.LocationText>
        <Text color="typography" variant="caption-1">
          {location}
        </Text>
      </S.LocationText>
    </S.LocationContent>
    <S.ArrowContent>
      <ArrowIcon width={24} height={24} color="var(--primary500)" />
    </S.ArrowContent>
  </S.ExperienceLocation>
)

export default AddressButton
