import { EEnvironment } from 'enums/EEnvironment'
import { ApiErrors } from 'types/http/ApiError'

/**
 *
 * @param ApiErrors
 * @description lets save too json value if provided
 * @returns {Record} key: value
 */
const formatErrorsToNewrelic = (errors: ApiErrors): Record<string, string | number> => {
  if (errors?.errors && Array.isArray(errors?.errors && errors?.errors[0])) {
    return Object.entries(errors?.errors[0]).reduce(
      (prev, [key, value]) => {
        prev[key] = value
        return prev
      },
      {
        statusCode: errors?.statusCode,
        method: errors?.method,
        action: errors?.action,
        message: errors?.message,
      } as Record<string, string | number>,
    )
  }
  return errors as never
}

/**
 *
 * @param errors
 * @description send custom error to newRelic
 */
const noticeError = (errors: ApiErrors) => {
  if (
    process.env.REACT_APP_ENV === EEnvironment.PRODUCTION ||
    process.env.REACT_APP_ENV === EEnvironment.STAGING
  )
    newrelic.noticeError(errors, formatErrorsToNewrelic(errors))
}

const setCustomAttribute = (name: string, value: string | number) => {
  if (
    process.env.REACT_APP_ENV === EEnvironment.PRODUCTION ||
    process.env.REACT_APP_ENV === EEnvironment.STAGING
  )
    newrelic.setCustomAttribute(name, value)
}

export default { noticeError, setCustomAttribute }
