import styled from 'styled-components'

export const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
`

export const Content = styled.div`
  margin-top: 4rem;
  width: 100%;

  div[role='tabpanel'] {
    padding: 1rem 0 0 0;
  }

  div[role='tablist'] {
    padding-top: 1.5rem;
    flex-wrap: nowrap;
  }

  div[role='tab'] {
    padding: 0.5rem 0.7rem;
  }
`
