const getURL = (uri?: string) => {
  if (uri) {
    const base = window.location.origin
    return new URL(uri, base).search
  }
  return window.location.search
}

export const getParamsFromURL = (uri?: string) => {
  const urlParams = Object.fromEntries(new URLSearchParams(getURL(uri ?? '')).entries())

  const utmParams = Object.fromEntries(
    Object.entries(urlParams).filter(([key]) => key.includes('utm_')),
  )
  const adjParams = Object.fromEntries(
    Object.entries(urlParams).filter(([key]) => key.includes('adj_')),
  )
  const filterParams = Object.fromEntries(
    Object.entries(urlParams).filter(([key]) => !key.includes('utm_') && !key.includes('adj_')),
  )

  return {
    filterParams,
    adjParams,
    utmParams,
  }
}
