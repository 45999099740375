import styled from 'styled-components'

export const InputContent = styled.div`
  padding: 1rem 0;
  width: 100%;
  --input-background-color: var(--neutral-theme);

  .city-name input {
    color: var(--gray500);
    font-weight: 400;
  }
`

export const StyledLocation = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    width: 100%;
    align-items: center;

    > span {
      padding-left: 0.5rem;
    }
  }
`
