import React from 'react'

import { ReactComponent as NotFoundIcon } from 'assets/icons/NotFound.svg'

import { Text } from '@interco/inter-ui/components/Text'

interface NotFoundProps extends React.HTMLAttributes<unknown> {
  title: string
  description: string
}

const NotFound = ({ title, description, ...props }: NotFoundProps) => (
  <div style={{ textAlign: 'center', padding: '0 20px' }} {...props}>
    <NotFoundIcon style={{ marginBottom: 24, marginTop: 24 }} />
    <Text variant="headline-h1" semiBold className="bold d-block">
      {title}
    </Text>
    <Text className="d-block" variant="body-3" style={{ marginTop: 8 }}>
      {description}
    </Text>
  </div>
)

export default NotFound
