import React from 'react'

import ScheduleChart from './ScheduleChart'
import { MainContainer } from './EventSchedule.styles'
import { EventScheduleProps } from '.'

export const EventSchedule = ({ schedule }: EventScheduleProps) => (
  <MainContainer>
    <ScheduleChart schedule={schedule} />
  </MainContainer>
)
