import React from 'react'

import { BottomSheet as BS } from 'react-spring-bottom-sheet'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './BottomSheet.styles'

interface BottomSheetProps {
  showBottomSheet: boolean
  onDismiss?: () => void
  headerLabel?: string
  headerBtLabel?: string
  headerBtHandler?: () => void
  footerBtLabel?: string
  footerBtDisable?: boolean
  footerBtLoading?: boolean
  footerBtHandler?: () => void
  bsContent: React.ReactNode
  fullScreen?: boolean
}

export const BottomSheet = ({
  showBottomSheet,
  onDismiss,
  headerLabel,
  headerBtLabel,
  headerBtHandler,
  footerBtLabel,
  footerBtDisable,
  footerBtLoading,
  footerBtHandler,
  bsContent,
  fullScreen,
}: BottomSheetProps) => {
  const header = (
    <S.Header>
      <Text variant="body-2" bold colorWeight={500}>
        {headerLabel}
      </Text>
      <Button className="close-bt" variant="link" onClick={headerBtHandler}>
        <Text variant="body-3" color="primary" bold colorWeight={500}>
          {headerBtLabel}
        </Text>
      </Button>
    </S.Header>
  )

  const footer = footerBtLabel ? (
    <S.Footer>
      <Button
        disabled={footerBtDisable}
        onClick={footerBtHandler}
        className="submit-bt"
        isLoading={footerBtLoading}
      >
        {footerBtLabel}
      </Button>
    </S.Footer>
  ) : null

  return (
    <BS
      className="bottomSheet"
      open={showBottomSheet}
      onDismiss={onDismiss}
      header={header}
      footer={footer}
      snapPoints={fullScreen ? ({ maxHeight }) => maxHeight : undefined}
    >
      <S.Content>{bsContent}</S.Content>
    </BS>
  )
}
