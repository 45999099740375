import Agenda from '@interco/icons/orangeds/SM/agenda'
import Bag from '@interco/icons/orangeds/SM/sacola'
import BookOpen from '@interco/icons/orangeds/SM/book-open'
import Calendar from '@interco/icons/bidis/v2/action/calendar'
import Card from '@interco/icons/orangeds/MD/card'
import Cashback from '@interco/icons/bidis/v2/products/banco/cashback'
import CheckCircle from '@interco/icons/orangeds/SM/check-circle'
import DeliveryTime from '@interco/icons/orangeds/SM/tempo-de-entrega'
import ForkKnife from '@interco/icons/orangeds/SM/fork-knife'
import Hamburguer from '@interco/icons/orangeds/SM/hamburguer'
import Heart from '@interco/icons/orangeds/SM/heart'
import HouseFinancial from '@interco/icons/orangeds/MD/house-financial'
import IndiqueEGanhe from '@interco/icons/orangeds/XL/indique-e-ganhe'
import Location from '@interco/icons/orangeds/SM/location'
import Market from '@interco/icons/orangeds/MD/market'
import MoneyUp from '@interco/icons/orangeds/MD/money-up'
import Paper from '@interco/icons/orangeds/LD/resumo-de-pedido'
import QMedal from '@interco/icons/orangeds/LD/q-medal'
import Receipt from '@interco/icons/orangeds/XL/receipt'
import ResumoDePedido from '@interco/icons/orangeds/SM/resumo-de-pedido'
import Reuse from '@interco/icons/orangeds/MD/reuse'
import ShoppingBag from '@interco/icons/orangeds/SM/shopping-bag'
import Ticket from '@interco/icons/orangeds/XL/ticket'
import UserAccount from '@interco/icons/orangeds/XL/user-account'
import Wallet from '@interco/icons/orangeds/XL/wallet'

export interface DynamicIconProps {
  name: string
  height: number
  width: number
  color: string
}

/**
 * use the exact same names from interco/icon lib on switch cases
 * maintains icons list in alphabetical order
 *  */

const DynamicIcon = ({ name, height, width, color }: DynamicIconProps) => {
  switch (name) {
    case 'agenda':
      return <Agenda height={height} width={width} color={color} />
    case 'bag':
    case 'sacola':
      return <Bag height={height} width={width} color={color} />
    case 'book-open':
      return <BookOpen height={height} width={width} color={color} />
    case 'card':
      return <Card height={height} width={width} color={color} />
    case 'calendar':
      return <Calendar height={height} width={width} color={color} />
    case 'cashback':
      return <Cashback height={height} data-testid={name} width={width} color={color} />
    case 'check-circle':
      return <CheckCircle height={height} width={width} color={color} />
    case 'fork-knife':
      return <ForkKnife height={height} width={width} color={color} />
    case 'hamburguer':
      return <Hamburguer height={height} data-testid={name} width={width} color={color} />
    case 'heart':
      return <Heart height={height} width={width} color={color} />
    case 'house-financial':
      return <HouseFinancial height={height} width={width} color={color} />
    case 'indique-e-ganhe':
      return <IndiqueEGanhe height={height} width={width} color={color} />
    case 'location':
      return <Location height={height} width={width} color={color} />
    case 'market':
      return <Market height={height} width={width} color={color} />
    case 'money-up':
      return <MoneyUp height={height} width={width} color={color} />
    case 'paper':
      return <Paper height={height} width={width} color={color} />
    case 'q-medal':
      return <QMedal height={height} width={width} color={color} />
    case 'receipt':
      return <Receipt height={height} data-testid={name} width={width} color={color} />
    case 'resumo-de-pedido':
      return <ResumoDePedido height={height} data-testid={name} width={width} color={color} />
    case 'reuse':
      return <Reuse height={height} width={width} color={color} />
    case 'shopping-bag':
      return <ShoppingBag height={height} width={width} color={color} />
    case 'ticket':
      return <Ticket height={height} width={width} color={color} />
    case 'tempo-de-entrega':
      return <DeliveryTime height={height} width={width} color={color} />
    case 'user-account':
      return <UserAccount height={height} width={width} color={color} />
    case 'wallet':
      return <Wallet height={height} width={width} color={color} />
    default:
      return null
  }
}

export default DynamicIcon
