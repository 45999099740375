import styled from 'styled-components'

export const MainContainer = styled.div`
  margin: 0.5rem 0 0;

  div[role='tabpanel'] {
    padding: 1rem 0 0 0;
  }
`

export const ScheduleContainer = styled.div`
  border: 1px solid var(--gray200);
  border-radius: 0.5rem;
  --accordion-container-background-color: none;

  padding: 0.5rem;
  margin: 0.5rem 0;
`

export const EventItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
