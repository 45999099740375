import { ETrackEvent } from 'enums/ETrackEvent'

import { interWbSession } from '@interco/inter-webview-bridge'

interface ButtonTag {
  action_id: string
}

export const handleTagSecondaryBt = async ({ action_id }: ButtonTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '1',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_BENEFIT_VALIDATED,
      ref_type: 'botão',
      content_action: 'toque',
      action_id,
    })
    .catch(window.console.log)
}

export const handleTagPrimaryBt = async ({ action_id }: ButtonTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '2',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_BENEFIT_VALIDATED,
      ref_type: 'botão',
      content_action: 'toque',
      action_id,
    })
    .catch(window.console.log)
}

interface BenefitTag {
  restaurant: string
  date: string
}

export const handleTagLoad = async ({ restaurant, date }: BenefitTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_SUCCESS, {
      ref_figma: '3',
      flow: ETrackEvent.F_USE_BENEFIT,
      screen: ETrackEvent.S_BENEFIT_VALIDATED,
      action_id: 'código disponível',

      restaurant,
      date,
    })
    .catch(window.console.log)
}
