import React, { ChangeEvent, useState } from 'react'

import { FilterObject } from 'types/Filters'
import { BottomSheet } from 'components/BottomSheet'
import { useBSState } from 'contexts/FiltersBottomsheetContext'
import Divider from 'components/Divider'
import { useDebouncedCallback } from 'use-debounce'
import * as T from 'pages/Filters/Filters.tags'
import { ETrackEvent } from 'enums/ETrackEvent'

import { SearchInput } from '@interco/inter-ui/components/SearchInput'
import { Button } from '@interco/inter-ui/components/Button'
import { Checkbox } from '@interco/inter-ui/components/Checkbox'
import { Text } from '@interco/inter-ui/components/Text'

import { CookingsFilterProps } from '.'
import * as S from '../RestaurantsFilters.styles'

export const CookingsFilter = ({ filters, setFilters }: CookingsFilterProps) => {
  const { bsStateParam, setBsStateParam } = useBSState()
  const [searchText, setSearchText] = useState('')
  const [cookings, setCookings] = useState<FilterObject[]>(
    JSON.parse(JSON.stringify(filters.cookings)),
  )
  const [searchList, setSearchList] = useState<FilterObject[]>(
    JSON.parse(JSON.stringify(filters.cookings)),
  )

  const onFilter = () => {
    T.handleTagSelectFilter({
      selected_filter: getStringOfSelected(cookings),
      screen: ETrackEvent.S_FILTERS_CATEGORY,
    })
    setBsStateParam({ ...bsStateParam, showBottomSheetCooking: false })
    setFilters({
      ...filters,
      cookings,
    })
    setSearchList(JSON.parse(JSON.stringify(cookings)))
    setSearchText('')
  }

  const search = useDebouncedCallback(async (value: string) => {
    value = value.trim()
    if (!value) {
      setSearchList(JSON.parse(JSON.stringify(filters.cookings)))
    }

    T.handleTagSearch({ search_term: value, screen: ETrackEvent.S_FILTERS_CATEGORY })

    setSearchList(cookings.filter((d) => d.value.toLowerCase().includes(value.toLowerCase())))
  }, 500)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
    search(e.target.value)
  }

  const isSelected = () => cookings?.some((d) => d.selected) || !!searchText

  const clearFilter = () => {
    T.handleTagClearFilter(ETrackEvent.S_FILTERS_CATEGORY)
    if (isSelected()) {
      const clearCookings = cookings?.map((d) => {
        d.selected = false
        return d
      })
      setSearchText('')
      setSearchList(clearCookings)
      setCookings(clearCookings)
    }
  }

  const onDismiss = () => {
    setCookings(JSON.parse(JSON.stringify(filters.cookings)))
    setSearchText('')
    setBsStateParam({ ...bsStateParam, showBottomSheetCooking: false })
  }

  const handleChange = (index: number, item: string) => {
    const updatedSearchList = JSON.parse(JSON.stringify(searchList))
    updatedSearchList[index].selected = !updatedSearchList[index].selected

    const updatedCookings = cookings.map((obj) => {
      if (obj.label === item) {
        obj.selected = !obj.selected
      }
      return obj
    })

    setCookings(updatedCookings)
    setSearchList(updatedSearchList)
  }

  const getSelectedLabels = (obj: FilterObject[]) => obj.map((item) => item.label)
  const getSelectedFilters = (obj: FilterObject[]) => obj.filter((item) => item.selected)
  const getStringOfSelected = (obj: FilterObject[]) =>
    getSelectedLabels(getSelectedFilters(obj)).join(', ')

  const bsContent = (
    <>
      <SearchInput
        value={searchText}
        onChange={handleInputChange}
        style={{ width: '110%', marginBottom: '1rem' }}
        placeholder="Pesquisar"
      />
      <S.ListContainer>
        <div className={isSelected() ? 'two-btn' : 'one-btn'}>
          {searchList?.map((e, i) => (
            <div key={e.value.toString()}>
              <S.FilterCheckboxList>
                <Checkbox
                  id={e.value}
                  value={i}
                  name={e.label}
                  label={<Text variant="body-3">{e.label}</Text>}
                  checked={e.selected}
                  onChange={() => handleChange(i, e.value)}
                />
              </S.FilterCheckboxList>
              <Divider style={{ margin: '0.5rem 0' }} />
            </div>
          ))}
        </div>
      </S.ListContainer>
      <S.ButtonsContainer>
        <Button data-testid="avaliation-button" onClick={onFilter} className="button-style">
          Selecionar filtros
        </Button>
        {isSelected() && (
          <Button
            variant="secondary"
            data-testid="restaurants-button"
            className="button-style"
            onClick={clearFilter}
          >
            Limpar filtros
          </Button>
        )}
      </S.ButtonsContainer>
    </>
  )

  return (
    <BottomSheet
      headerLabel="Culinária"
      showBottomSheet={bsStateParam?.showBottomSheetCooking}
      onDismiss={onDismiss}
      bsContent={bsContent}
      fullScreen
    />
  )
}

export default CookingsFilter
