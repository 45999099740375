import { ETrackEvent } from 'enums/ETrackEvent'
import { SubscriberTag } from 'types/AnalyticsTag'

import { interWbSession } from '@interco/inter-webview-bridge'

interface LoadTag {
  screen: string
  action_id: string
}

interface RestaurantInfo extends SubscriberTag {
  restaurant: string
  date: string
  action_id: string
}

export const handleTagHeader = async () => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_HEADER, {
      ref_figma: '1',
      flow: ETrackEvent.F_USE_HISTORY,
      screen: ETrackEvent.S_UTILIZATION,
      action_id: 'back',
      product: ETrackEvent.T_PRODUCT,
    })
    .catch(window.console.log)
}

export const handleTagSeven = async ({ plan_name = '', plan_value = '' }: SubscriberTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '7',
      flow: ETrackEvent.F_USE_HISTORY,
      screen: ETrackEvent.S_UTILIZATION,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'dúvida sobre o saldo',

      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagEight = async ({
  restaurant,
  date,
  action_id,
  plan_name = '',
  plan_value = '',
}: RestaurantInfo) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_FLOW, {
      ref_figma: '8',
      flow: ETrackEvent.F_USE_HISTORY,
      screen: ETrackEvent.S_UTILIZATION,
      ref_type: 'botão',
      content_action: 'toque',
      action_id,

      restaurant,
      date,
      plan_name,
      plan_value,
    })
    .catch(window.console.log)
}

export const handleTagLoad = async ({ screen, action_id }: LoadTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_DUO_GOURMET_ACCESS, {
      ref_figma: '10',
      flow: ETrackEvent.F_USE_HISTORY,
      screen,
      content_action: 'dado carregado',
      action_id,
    })
    .catch(window.console.log)
}
