import React from 'react'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import { TagsList, TagsStatus } from 'components/TagsList'
import { handleCollectionCard } from 'pages/Home/Home.tags'
import { useConfigs } from 'contexts/ConfigsContext'
import { ETrackEvent } from 'enums/ETrackEvent'

import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { useNavigate } from '@reach/router'

import { CardProps, RecommendationCarouselProps } from './RecommendationCarousel.types'
import * as S from './RecommendationCarousel.styles'
import * as T from './RecommendationCarousel.tags'

const RecommendationCarousel = ({
  carousel,
  duoGourmetInterCustomer,
  city,
}: RecommendationCarouselProps) => {
  const { configs } = useConfigs()
  const navigate = useNavigate()

  const onButtonClick = () => {
    T.handleTagSeeAll({ section: carousel.title })
    navigate(`/recommendations/${carousel.sort}`, {
      state: { carouselTitle: carousel.title, city },
    }).catch(window.console.log)
  }

  const handleCardClick = ({ restaurant, flow, screen }: CardProps) => {
    handleCollectionCard({
      flow,
      screen,

      restaurant: restaurant.name,
      cooking: restaurant.cooking,
      status: TagsStatus({
        useNow: restaurant.useNow,
        available: restaurant.available,
        newRestaurant: restaurant.newRestaurant,
        deliveryUseNow: restaurant.deliveryUseNow,
        takeOutUseNow: restaurant.takeOutUseNow,
      }).join(','),
      neighborhood: restaurant.district,
      distance: restaurant.distance,
      modality: restaurant.modality.join(','),
      favorite: `${restaurant.favorite}`,

      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
    })
    navigate(`/restaurants/${restaurant.id}`, { state: { name: restaurant.name } }).catch(
      window.console.log,
    )
  }

  return (
    <S.Wrapper>
      <S.CarouselHeader>
        <Text variant="headline-h3" semiBold>
          {carousel.title}
        </Text>
        <Button onClick={onButtonClick} variant="link">
          {carousel.button}
        </Button>
      </S.CarouselHeader>
      <S.CarouselScroll>
        {carousel.restaurants.map((rest, idx) => (
          <S.Card
            key={`rest-${idx.toString()}`}
            onClick={() =>
              handleCardClick({
                restaurant: rest,
                flow: ETrackEvent.F_HOME_SUB,
                screen: ETrackEvent.S_HOME_SUB,
              })
            }
          >
            <LazyLoadImage
              effect="blur"
              src={rest.imageCoverUrl}
              style={{
                height: '97px',
                width: '150px',
                borderRadius: '0.5rem',
                objectFit: 'cover',
              }}
            />
            <Text variant="caption-1" bold colorWeight={500} className="text-style wrap-text">
              {rest.name}
            </Text>
            <Text variant="caption-2" className="text-style">
              {rest.cooking}
            </Text>
            <span style={{ display: 'flex' }}>
              <Text variant="caption-2" className="text-style">
                {rest.district}{' '}
                {rest.distance !== '0,0Km' && (
                  <>
                    <span style={{ color: 'var(--gray200)' }}>•</span> {rest.distance}
                  </>
                )}
              </Text>
            </span>
            {duoGourmetInterCustomer && (
              <TagsList
                useNow={rest.useNow}
                available={rest.available}
                newRestaurant={rest.newRestaurant}
                deliveryUseNow={rest.deliveryUseNow}
                takeOutUseNow={rest.takeOutUseNow}
              />
            )}
          </S.Card>
        ))}
      </S.CarouselScroll>
    </S.Wrapper>
  )
}

export default RecommendationCarousel
