import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0 2.5rem;

  .saved-value {
    color: var(--success500);
  }

  svg {
    margin: 0 0 -3px 5px;
  }

  .additional-text {
    width: 70%;
  }
`
