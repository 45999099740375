import React from 'react'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import { ETrackEvent } from 'enums/ETrackEvent'

import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { useNavigate } from '@reach/router'
import { Tag } from '@interco/inter-ui/components/Tag'
import { interWbNavigate } from '@interco/inter-webview-bridge'

import * as S from './ExperiencesCarousel.styles'
import * as T from './ExperiencesCarousel.tags'
import { CardProps, ExperiencesCarouselProps } from './ExperiencesCarousel.types'

const ExperiencesCarousel = ({
  carousel,
  duoGourmetInterCustomer,
  city,
}: ExperiencesCarouselProps) => {
  const navigate = useNavigate()

  const onButtonClick = () => {
    T.handleTagSeeAll({ section: carousel.title })
    navigate(`/experiences/collection/${carousel.categorySlug}`, {
      state: { city },
    }).catch(window.console.log)
  }

  const handleCardClick = async ({ experience, carouselTitle }: CardProps) => {
    T.handleTagItem({
      name: experience.title,
      category: experience.category,
      section: carouselTitle,
    })

    if (experience.deepLink) {
      await interWbNavigate.openDeepLink(experience.deepLink)
    } else {
      navigate(`/experiences/${experience.id}`, { state: { name: experience.title } }).catch(
        window.console.log,
      )
    }
  }

  return (
    <S.Wrapper>
      <S.CarouselHeader>
        <Text variant="headline-h3" semiBold>
          {carousel.title}
        </Text>
        <Button onClick={onButtonClick} variant="link">
          {carousel.button}
        </Button>
      </S.CarouselHeader>
      <S.CarouselScroll>
        {carousel.experiences.map((item, idx) => (
          <S.Card
            key={`item-${idx.toString()}`}
            onClick={() =>
              handleCardClick({
                experience: item,
                flow: ETrackEvent.F_HOME_SUB,
                screen: ETrackEvent.S_HOME_SUB,
                carouselTitle: carousel.title,
              })
            }
          >
            <LazyLoadImage
              effect="blur"
              src={item.imgSmallCoverUrl}
              style={{
                height: '97px',
                width: '150px',
                borderRadius: '0.5rem',
                objectFit: 'cover',
              }}
            />
            <Text variant="caption-1" bold colorWeight={500} className="text-style wrap-text">
              {item.title}
            </Text>
            {duoGourmetInterCustomer && item.type === 'fixed' && item.useNow && (
              <S.TagsContainer>
                <S.TagSpace>
                  <Tag color="var(--success100)" colorText="var(--success500)">
                    Use agora
                  </Tag>
                </S.TagSpace>
              </S.TagsContainer>
            )}

            {item.type === 'temporary' && item.eventDate && (
              <Text variant="caption-2" className="text-style">
                {item.eventDate}
              </Text>
            )}

            {duoGourmetInterCustomer && (
              <S.TagsContainer>
                <S.TagSpace>
                  <Tag color="var(--success500)" colorText="var(--success100)">
                    {item.shortBenefit}
                  </Tag>
                </S.TagSpace>
              </S.TagsContainer>
            )}
          </S.Card>
        ))}
      </S.CarouselScroll>
    </S.Wrapper>
  )
}

export default ExperiencesCarousel
