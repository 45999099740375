import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 64px;
  padding-bottom: 80px;
`

export const BottomButton = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 16px 24px;
  background-color: var(--neutral-theme);
  height: 80px;

  > button {
    width: 100%;
  }
`
