import React from 'react'

import AppBar from 'components/AppBar'
import Divider from 'components/Divider'
import { SpaceBetween } from 'App.styles'

import { Skeleton } from '@interco/inter-ui/components/Skeleton'

import * as S from './Subscription.styles'

const SubscriptionShimmer = () => (
  <>
    <AppBar name="Assinatura e pagamento" />
    <S.Wrapper data-testid="shimmer-subscription" fullHeight style={{ paddingTop: 60 }}>
      <S.SubscriptionInfo>
        {new Array(4).fill('').map((_, i) => (
          <div
            key={`id_${i.toString()}`}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <SpaceBetween direction="row">
              <Skeleton width="45%" height="1.5rem" />
              <Skeleton width="45%" height="1.5rem" />
            </SpaceBetween>
          </div>
        ))}
      </S.SubscriptionInfo>

      <Divider
        borderWidth={8}
        style={{ position: 'absolute', left: 0, width: '100%', margin: '2rem 0' }}
        color="var(--gray100)"
      />

      <S.LinkMenu>
        {new Array(2).fill('').map((_, i) => (
          <div
            key={`id1${i.toString()}`}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginTop: 10,
            }}
          >
            <Skeleton width="100%" height="1.5rem" style={{ marginTop: 10 }} />
          </div>
        ))}
      </S.LinkMenu>
    </S.Wrapper>
  </>
)

export default SubscriptionShimmer
