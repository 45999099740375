import React from 'react'

import { Text } from '@interco/inter-ui/components/Text'

interface PriceHankProps {
  price: string
}

const PriceHank = ({ price }: PriceHankProps) => (
  <>
    {[...Array(5)].map((_, index) => {
      index += 1
      return (
        <Text variant="caption-1" data-testid={`pricehank-${index}`} key={`id_${index.toString()}`}>
          {index > price.length ? <span style={{ color: 'var(--gray200)' }}>$</span> : '$'}
        </Text>
      )
    })}
  </>
)
export default PriceHank
