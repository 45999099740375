import React from 'react'

import { bridgeNative } from 'App'

import { interWbNavigate } from '@interco/inter-webview-bridge'
import { RouteComponentProps } from '@reach/router'
import { Button } from '@interco/inter-ui/components/Button'

const OccultedRoute = (_: RouteComponentProps) => {
  const handleRestaurantMenuOne = async () => {
    bridgeNative.execute({
      action: 'sendToBroswer',
      metadata: {
        url: 'https://duogourmet-images.s3.us-east-2.amazonaws.com/restaurants/32rxzmqspk/menu/menu.pdf?v=202005191843',
      },
    })
  }

  const handleRestaurantMenuTwo = async () => {
    bridgeNative.execute({
      action: 'sendToBrowser',
      metadata: {
        url: 'https://duogourmet-images.s3.us-east-2.amazonaws.com/restaurants/32rxzmqspk/menu/menu.pdf?v=202005191843',
      },
    })
  }

  const handleRestaurantMenuThree = async () => {
    await interWbNavigate.openNativeBrowser(
      'https://duogourmet-images.s3.us-east-2.amazonaws.com/restaurants/32rxzmqspk/menu/menu.pdf?v=202005191843',
    )
  }

  return (
    <div style={{ padding: 20 }}>
      <Button
        style={{
          width: '100%',
          marginBottom: 20,
        }}
        onClick={handleRestaurantMenuOne}
      >
        Abrir Broswer
      </Button>

      <Button
        style={{
          width: '100%',
          marginBottom: 20,
        }}
        onClick={handleRestaurantMenuTwo}
      >
        Abrir Broswer
      </Button>
      <Button
        style={{
          width: '100%',
          marginBottom: 20,
        }}
        onClick={handleRestaurantMenuThree}
      >
        Abrir Native Browser
      </Button>
    </div>
  )
}

export default OccultedRoute
