import Divider from 'components/Divider'

import { Grid, GridItem } from '@interco/inter-ui/components/Grid'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './EventSchedule.styles'
import { EventScheduleProps, ScheduleLineProps, WEEK } from '.'

const ScheduleHeader = () => (
  <Grid templateRows="repeat(1, 1fr)" templateColumns="repeat(3, 1fr)" gap="5px">
    <GridItem>
      <Text variant="caption-1" bold>
        Dia
      </Text>
    </GridItem>
    <GridItem>
      <S.EventItem>
        <Text variant="caption-1" bold>
          Manhã/Tarde
        </Text>
      </S.EventItem>
    </GridItem>
    <GridItem>
      <S.EventItem>
        <Text variant="caption-1" bold>
          Noite
        </Text>
      </S.EventItem>
    </GridItem>
    <GridItem column="span 3">
      <Divider style={{ margin: 0 }} />
    </GridItem>
  </Grid>
)

const ScheduleChart = ({ schedule }: EventScheduleProps) => (
  <>
    <Text variant="headline-h3" semiBold>
      Disponibilidade
    </Text>
    <S.ScheduleContainer>
      <ScheduleHeader />
      <Grid
        templateRows="repeat(7, 1fr)"
        templateColumns="repeat(3, 1fr)"
        gap="5px"
        style={{ marginTop: '0.3rem' }}
      >
        {schedule?.map((item, index) => {
          if (index === 0) {
            return (
              <ScheduleLine
                key={item.dayOfWeek}
                dayOfWeek={item.dayOfWeek}
                daySchedule={item.daySchedule}
                nightSchedule={item.nightSchedule}
                isToday
              />
            )
          }

          return (
            <ScheduleLine
              key={item.dayOfWeek}
              dayOfWeek={item.dayOfWeek}
              daySchedule={item.daySchedule}
              nightSchedule={item.nightSchedule}
            />
          )
        })}
      </Grid>
    </S.ScheduleContainer>
  </>
)

const ScheduleLine = ({ dayOfWeek, daySchedule, nightSchedule, isToday }: ScheduleLineProps) => {
  const enumDay = WEEK[dayOfWeek as keyof typeof WEEK]

  return (
    <>
      <GridItem>
        <Text variant="caption-1" bold={isToday} colorWeight={500}>
          {isToday ? `Hoje, ${enumDay.toLocaleLowerCase()}` : enumDay}
        </Text>
      </GridItem>
      <GridItem>
        <S.EventItem>
          <Text variant="caption-1" bold={isToday} colorWeight={500}>
            {daySchedule}
          </Text>
        </S.EventItem>
      </GridItem>
      <GridItem>
        <S.EventItem>
          <Text variant="caption-1" bold={isToday} colorWeight={500}>
            {nightSchedule}
          </Text>
        </S.EventItem>
      </GridItem>
    </>
  )
}

export default ScheduleChart
