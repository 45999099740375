import { Dispatch } from 'react'

import { FilterCategories } from 'pages/Filters'

import DistrictsFilter from './DistrictsFilter'

export interface DistrictsFilterProps {
  filters: FilterCategories
  setFilters: Dispatch<React.SetStateAction<FilterCategories>>
}

export default DistrictsFilter
