import React from 'react'

import { RestaurantResume } from 'types/Restaurant'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Divider from 'components/Divider'
import useAsync from 'hooks/UseAsync'
import { useConfigs } from 'contexts/ConfigsContext'
import { TagsList, TagsStatus } from 'components/TagsList'
import * as T from 'pages/Home/Home.tags'

import IconHeart from '@interco/icons/orangeds/XL/heart'
import IconHeartFill from '@interco/icons/orangeds/XL/heart-fill'
import { Text } from '@interco/inter-ui/components/Text'
import { navigate } from '@reach/router'

import * as S from './RestaurantCard.styles'

interface RestaurantCardProps {
  restaurant: RestaurantResume
  flow: string
  screen: string
}

const RestaurantCard = ({ restaurant, flow, screen }: RestaurantCardProps) => {
  const [, postChangeFavorite] = useAsync('post')
  const { configs } = useConfigs()

  const handleFavorite = async () => {
    restaurant.favorite = !restaurant.favorite

    T.handleTagFavorite({
      flow,
      screen,

      favorite: `${restaurant.favorite}`,
      restaurant: restaurant.name,
      neighborhood: restaurant.district,
      distance: restaurant.distance,
      cooking: restaurant.cooking,
      modality: restaurant.modality.join(','),

      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
    })

    await postChangeFavorite(`app-pf/foods/shop/v1/favorites/${restaurant.id}`).catch(
      window.console.error,
    )
  }

  const handleClick = async () => {
    T.handleCollectionCard({
      flow,
      screen,

      restaurant: restaurant.name,
      cooking: restaurant.cooking,
      status: TagsStatus({
        useNow: restaurant.useNow,
        available: restaurant.available,
        newRestaurant: restaurant.newRestaurant,
        deliveryUseNow: restaurant.deliveryUseNow,
        takeOutUseNow: restaurant.takeOutUseNow,
      }).join(','),
      neighborhood: restaurant.district,
      distance: restaurant.distance,
      modality: restaurant.modality.join(','),
      favorite: `${restaurant.favorite}`,

      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
    })

    navigate(`/restaurants/${restaurant.id}`, {
      state: {
        name: restaurant.name,
      },
    })
  }

  return (
    <S.RestaurantContainer>
      <S.Item onClick={handleClick}>
        <S.Content>
          <LazyLoadImage
            effect="blur"
            src={restaurant.imageCoverUrl}
            style={
              configs.subscriber.duoGourmetInterCustomer
                ? {
                    height: '83px',
                    width: '83px',
                    borderRadius: '0.5rem',
                    objectFit: 'cover',
                  }
                : {
                    height: '63px',
                    width: '63px',
                    borderRadius: '0.5rem',
                    objectFit: 'cover',
                  }
            }
          />
          <S.RestaurantDetails>
            <Text variant="caption-1" colorWeight={500} bold className="text-style wrap-text">
              {restaurant.name}
            </Text>
            <Text variant="caption-2" className="text-style">
              {restaurant.cooking}
            </Text>
            <Text variant="caption-2" className="text-style">
              {restaurant.modality.map((choice, idx, arr) => (
                <span key={`modality_${choice}`}>
                  <Text variant="caption-2">{choice}</Text>
                  {idx < arr.length - 1 && (
                    <span
                      style={{ color: 'var(--gray200)', marginLeft: '3px', marginRight: '3px' }}
                    >
                      •
                    </span>
                  )}
                </span>
              ))}
            </Text>
            <Text variant="caption-2" className="text-style">
              {restaurant.district}{' '}
              {restaurant.distance !== '0,0Km' && (
                <>
                  <span style={{ color: 'var(--gray200)' }}>•</span> {restaurant.distance}
                </>
              )}
            </Text>

            {configs.subscriber.duoGourmetInterCustomer && (
              <TagsList
                useNow={restaurant.useNow}
                available={restaurant.available}
                newRestaurant={restaurant.newRestaurant}
                deliveryUseNow={restaurant.deliveryUseNow}
                takeOutUseNow={restaurant.takeOutUseNow}
              />
            )}
          </S.RestaurantDetails>
        </S.Content>
        <Divider color="var(--gray100)" style={{ margin: '0' }} />
      </S.Item>
      {configs.subscriber.duoGourmetInterCustomer && (
        <button id="overlay" type="button" onClick={handleFavorite}>
          {restaurant.favorite ? (
            <IconHeartFill height={16} width={16} color="var(--primary500)" />
          ) : (
            <IconHeart height={16} width={16} color="var(--primary500)" />
          )}
        </button>
      )}
    </S.RestaurantContainer>
  )
}

export default RestaurantCard
