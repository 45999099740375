import React from 'react'

import PromotionalCarousel from 'components/PromotionalCarousel/PromotionalCarousel'
import { Offer } from 'types/BlackCard'
import { IBanner } from 'types/Campaign'
import { BannerTag } from 'components/PromotionalBanner/PromotionalBannerTags'

import { Alert } from '@interco/inter-ui/components/Alert'

interface HomeAnnouncementsProps {
  blackCard?: Offer
  banners: IBanner[]
  pessoaFisica: boolean
  canSubscribe: {
    canSubscribe: boolean
    text: string
    button: string
  }
  className?: string
  bannerTag: BannerTag
}

const HomeAnnouncements = ({
  blackCard,
  banners,
  pessoaFisica,
  canSubscribe,
  className,
  bannerTag,
}: HomeAnnouncementsProps) => (
  <div className={className}>
    {blackCard && blackCard.alert && (
      <Alert title={blackCard.title} type="grayscale">
        <p style={{ paddingTop: '4px' }}>{blackCard.description}</p>
      </Alert>
    )}

    {banners && banners?.length > 0 && (
      <PromotionalCarousel
        banners={banners}
        pessoaFisica={pessoaFisica}
        canSubscribe={canSubscribe}
        bannerTag={bannerTag}
      />
    )}
  </div>
)

export default HomeAnnouncements
