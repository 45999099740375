import * as React from 'react'

import { interWbSession } from '@interco/inter-webview-bridge'

export interface IWbUserLocation {
  latitude: string
  longitude: string
  city: string
}

const useUserLocation = (): IWbUserLocation => {
  const [userLocation, setUserLocation] = React.useState<IWbUserLocation>({} as IWbUserLocation)

  React.useEffect(() => {
    interWbSession
      .getUserLocation()
      .then((d) => {
        setUserLocation(d)
      })
      .catch((error) => {
        window.console.log(error)
      })
  }, [])

  return userLocation
}

export default useUserLocation
