import styled from 'styled-components'

export const ImgContent = styled.div`
  margin: 2rem 0;
  display: flex;
  justify-content: center;
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;

  .center-text {
    text-align: center;
  }
`

export const DataReview = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;

  .header {
    margin-bottom: 1rem;
  }

  .item-title {
  }

  .item-body {
    margin-bottom: 1rem;
  }
`

export const AdvantagesContent = styled.div`
  margin-top: 1rem;

  .adv-text {
    padding-left: 1rem;
  }

  .adv-item {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
  }
`

export const ButtonContent = styled.div`
  .bt-spacer {
    margin-bottom: 1rem;
  }

  .bt-style {
    width: 100%;
  }
`

export const BottomSheetContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem 0.5rem 1rem;

  .linkClose {
    width: 100%;
    display: flex;
    justify-content: end;
    margin: -0.5rem 0 -0.3rem 0.5rem;
  }

  p {
    margin-bottom: 1rem;
    padding: 0 0.5rem;
  }

  .buttonClose {
    padding: 0.5rem 0.2rem;
  }
`

export const AlertStyle = styled.div`
  margin: 3rem 0;
`
