import React from 'react'

import { useBSState } from 'contexts/FiltersBottomsheetContext'
import { handleTagFilter } from 'pages/Home/RestaurantsTab/RestaurantsTab.tags'
import { useConfigs } from 'contexts/ConfigsContext'

import LocationIcon from '@interco/icons/bidis/v2/action/location'
import ListIcon from '@interco/icons/orangeds/LG/list'
import { Button } from '@interco/inter-ui/components/Button'

import * as S from './HomeButtons.styles'

const MapFloatingBt = () => {
  const { bsStateParam, setBsStateParam } = useBSState()
  const { configs } = useConfigs()

  const handleMapFilter = async () => {
    handleTagFilter({
      filter: 'mapa',
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    })
    setBsStateParam({ ...bsStateParam, showMapFilter: !bsStateParam.showMapFilter })
  }

  const iconProps = {
    width: 15,
    height: 15,
    color: 'var(--neutral-theme)',
  }

  const isSubscriber =
    configs.subscriber.duoGourmetInterCustomer || configs.subscriber.duoGourmetLegacyCustomer

  return (
    <S.MapWrapper>
      <Button onClick={handleMapFilter} className={isSubscriber ? 'isSubscriber' : 'notSubscriber'}>
        {bsStateParam.showMapFilter ? (
          <S.BtContent>
            <ListIcon {...iconProps} />
            <span>Lista</span>
          </S.BtContent>
        ) : (
          <S.BtContent>
            <LocationIcon {...iconProps} />
            <span>Mapa</span>
          </S.BtContent>
        )}
      </Button>
    </S.MapWrapper>
  )
}

export default MapFloatingBt
