import { EventSchedule } from './EventSchedule'

export enum WEEK {
  seg = 'Segunda',
  ter = 'Terça',
  qua = 'Quarta',
  qui = 'Quinta',
  sex = 'Sexta',
  sab = 'Sábado',
  dom = 'Domingo',
}

export interface ScheduleLineProps {
  dayOfWeek: string
  daySchedule: string
  nightSchedule: string
  isToday?: boolean
}

export interface EventScheduleProps {
  schedule: ScheduleLineProps[]
}

export default EventSchedule
