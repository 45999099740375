import React, { createContext, useContext, useState, useEffect, useMemo } from 'react'

import useAsync from 'hooks/UseAsync'
import { RouterResponse } from 'pages/RouterPage/RouterPage.types'
import { IBridgePermissions } from 'types/Restaurant'
import { ApiErrors } from 'types/http/ApiError'
import NewRelic from 'utils/NewRelic'
import { IWbUserLocation } from 'hooks/useUserLocation'
import Loading from 'components/Loading'

import { interWbSession } from '@interco/inter-webview-bridge'

interface ISubscriber {
  duoGourmetInterCustomer: boolean
  duoGourmetLegacyCustomer: boolean
  bankingCustomer: boolean
  planName: string
  planValue: string
  subscriptionId: string
  renewalDate: string
  subscriptionStatus: string
  customerSegmentation: string
  isEmployee?: boolean
}

export interface IPermissionLocation {
  checked: boolean
  value: boolean
  latitude?: string
  longitude?: string
  city?: string
}

interface IConfigs {
  subscriber: ISubscriber
  permissionLocation: IPermissionLocation
  isError?: boolean
  isPending?: boolean
  isSuccess?: boolean
}

export type TConfigsContext = {
  configs: IConfigs
  setConfigs: (configs: IConfigs) => void
}

export const ConfigsContext = createContext<TConfigsContext>({} as TConfigsContext)

export const useConfigs = () => useContext(ConfigsContext)

const ConfigsProvider: React.FC = ({ children }) => {
  const [subscription, getSubscription] = useAsync<ISubscriber>('get')
  const [routerResponse, getRouterResponse] = useAsync<RouterResponse>('get')
  const [configs, setConfigs] = useState<IConfigs>({
    subscriber: {
      duoGourmetInterCustomer: false,
      duoGourmetLegacyCustomer: false,
    } as ISubscriber,
    permissionLocation: {
      checked: false,
      value: false,
    },
    isPending: true,
    isError: false,
    isSuccess: false,
  } as IConfigs)

  const checkPermissions = async (): Promise<boolean> => {
    try {
      const result = (await interWbSession.getPermissions()) as never
      const newResult = result as IBridgePermissions
      const { permissions } = newResult
      const hasLocation = permissions.includes('LOCATION')
      const hasGeolocation = permissions.includes('GEOLOCATION')
      setConfigs({
        ...configs,
        permissionLocation: {
          checked: true,
          value: hasLocation || hasGeolocation,
        },
      })
      return hasLocation || hasGeolocation
    } catch (error) {
      const err = error as ApiErrors
      NewRelic.noticeError(err)
      return false
    }
  }

  useEffect(() => {
    const getUserLocation = async () => {
      let location = {} as IWbUserLocation
      try {
        location = await interWbSession.getUserLocation()
      } catch (error) {
        const err = error as ApiErrors
        NewRelic.noticeError(err)
      }
      return location
    }

    const fetchSubscription = async () => {
      try {
        const hasLocationPermission = await checkPermissions()
        let location = {} as IWbUserLocation
        if (hasLocationPermission) {
          location = await getUserLocation()
        }
        const resRouter = await getRouterResponse(`${process.env.REACT_APP_API}/router`)
        if (resRouter.isInterSubscriber) {
          const res = await getSubscription(`${process.env.REACT_APP_API}/subscription`)
          setConfigs({
            permissionLocation: {
              checked: true,
              value: hasLocationPermission,
              ...location,
            },
            subscriber: {
              duoGourmetInterCustomer: res.duoGourmetInterCustomer,
              duoGourmetLegacyCustomer: res.duoGourmetLegacyCustomer,
              bankingCustomer: res.bankingCustomer,
              planName: res.planName,
              planValue: res.planValue,
              subscriptionId: res.subscriptionId,
              renewalDate: res.renewalDate,
              subscriptionStatus: res.subscriptionStatus,
              customerSegmentation: res.customerSegmentation,
              isEmployee: resRouter.isEmployee,
            },
            isPending: false,
            isError: false,
            isSuccess: true,
          })
        } else {
          setConfigs({
            permissionLocation: {
              checked: true,
              value: hasLocationPermission,
              ...location,
            },
            subscriber: {
              ...configs.subscriber,
              duoGourmetInterCustomer: resRouter.isInterSubscriber,
              isEmployee: resRouter.isEmployee,
            },
            isPending: false,
            isError: false,
            isSuccess: true,
          })
        }
      } catch (error) {
        const sub = {} as IConfigs
        setConfigs({
          ...sub,
          isPending: false,
          isError: false,
          isSuccess: true,
        })
      }
    }
    fetchSubscription()
  }, [])

  const value = useMemo(() => ({ configs, setConfigs }), [configs])

  if (subscription.isPending || routerResponse.isPending) {
    return <Loading />
  }

  return (
    <ConfigsContext.Provider value={value}>
      {!configs.isPending && children}
    </ConfigsContext.Provider>
  )
}

export default ConfigsProvider
