import { Offer } from './BlackCard'
import { IBanner } from './Campaign'

export enum ESubscriptionStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
}

export interface SubscriptionResponse {
  totalSaved: string
  planName: string
  planValue: string
  fullSubscriptionValue: string
  renewalDate: string
  subscriptionId: string
  subscriptionStatus: keyof typeof ESubscriptionStatus
  statusMessage: string
  termsUrl: string
  passCashback: string
  blackCard: Offer
  banners: IBanner[]
  reactivateSub: ReactivateSubscription
  recurrenceId: string
  retryPayment: boolean
  discountValue: string
}

interface ReactivateSubscription {
  button: string
  title: string
  message: string
}
