import styled from 'styled-components'

export const InputSearch = styled.div`
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  input::placeholder {
    text-align: left;
  }

  .iconStyle {
    float: right;
  }

  > .inputStyle {
    width: 100%;
    height: 48px;
  }
`
