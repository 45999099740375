import React from 'react'

import Divider from 'components/Divider'

import { Skeleton } from '@interco/inter-ui/components/Skeleton'

import * as S from './RestaurantsTabShimmer.styles'

export const ShimmerHeader = () => (
  <S.Header>
    {window.scrollTo({ top: 0, behavior: 'auto' })}
    <Skeleton width="100%" height="48px" className="m-3" />
    <Skeleton width="100%" height="20px" className="m-3 locationStyle" />
    <Skeleton height="1px" className="m-3" style={{ width: 'calc(100% + 48px)' }} />
    <S.FilterList>
      {[...Array(3)].map((_item, idx) => (
        <Skeleton
          key={`shimmer-${idx.toString()}`}
          width="90px"
          height="32px"
          className="m-3"
          style={{ borderRadius: '999px', marginRight: '8px' }}
        />
      ))}
    </S.FilterList>
  </S.Header>
)

export const ShimmerBody = () => (
  <S.Body>
    {window.scrollTo({ top: 0, behavior: 'auto' })}
    {new Array(10).fill('').map((d, i) => (
      <S.Item key={`id_${i.toString()}`}>
        <S.Content>
          <Skeleton width="73px" height="73px" />
          <S.RestaurantDetails>
            <Skeleton width="150px" height="14px" className="m-3" />
            <Skeleton width="100px" height="14px" className="m-3" />
            <Skeleton width="100px" height="14px" className="m-3" />
            <Skeleton width="130px" height="14px" className="m-3" />
          </S.RestaurantDetails>
        </S.Content>
        <Divider color="var(--gray100)" style={{ margin: '0' }} />
      </S.Item>
    ))}
  </S.Body>
)
