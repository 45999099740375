import React, { useState } from 'react'

import BlackcardContext from 'contexts/BlackcardContext'
import useAsync from 'hooks/UseAsync'
import Error from 'components/Error'
import AppBar from 'components/AppBar'
import { Eligibility } from 'types/BlackCard'

import { RouteComponentProps } from '@reach/router'

import Onboarding from './Onboarding'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import RequestSuccess from './RequestSuccess'
import RequestStatus from './RequestStatus'

const BlackCard = (_: RouteComponentProps) => {
  const [step, setStep] = useState(0)
  const [, getEligibility] = useAsync<Eligibility>('get')
  const [stepsContent, setStepsContent] = useState([<Onboarding />])

  const goToNext = () => {
    setStep(step + 1)
  }

  const GenericError = () => (
    <>
      <AppBar name="Cartão Inter Black" />
      <Error />
    </>
  )

  React.useEffect(() => {
    const checkEligibility = async () => {
      try {
        const result = await getEligibility(`app-pf/foods/subscription/v1/blackCard/eligibility`)
        switch (result.code) {
          case 'BKCE1':
            setStepsContent([<Onboarding />, <StepOne />, <StepTwo />, <RequestSuccess />])
            break
          case 'BKCE10':
          case 'BKCE11':
          case 'BKCE12':
            setStepsContent([<Onboarding />, <RequestStatus issue={1} />])
            break
          case 'BKCE13':
            setStepsContent([<Onboarding />, <RequestStatus issue={2} />])
            break
          default:
            setStepsContent([<Onboarding />, <GenericError />])
            break
        }
      } catch (error) {
        window.console.error(error)
      }
    }
    checkEligibility()
  }, [])

  return (
    <BlackcardContext.Provider value={{ step, goToNext }}>
      {stepsContent[step]}
    </BlackcardContext.Provider>
  )
}

export default BlackCard
