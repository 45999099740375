import styled from 'styled-components'

export const CustomLabelContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  width: 100%;
`

export const ToggleIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0.6rem;
  border-radius: 1rem;
`

export const Rules = styled.div`
  margin: -0.8rem 0.5rem 0.8rem;

  .text-style {
    font-size: 12px;
    font-family: inherit;
  }

  ul {
    margin-left: 1rem;
  }
`
