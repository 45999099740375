import React, { createContext, useContext, useMemo, useState } from 'react'

import { PageResponse } from 'types/http/ApiResponse'

export interface QueryParamRestaurant extends PageResponse {
  name?: string
  cookings?: string[]
  districts?: string[]
  city?: string
  state?: string
  days?: string[]
  mealTimes?: string[]
  orderMethod?: string[]
  latitude?: string
  longitude?: string
  favorites?: boolean
  price?: string[]
  useNow?: boolean
  newRestaurant?: boolean
}

export type TFiltersContext = {
  queryParam: QueryParamRestaurant
  setQueryParam: (queryParam: QueryParamRestaurant) => void
}

export const FiltersContext = createContext<TFiltersContext>({} as TFiltersContext)
export const useFilters = () => useContext(FiltersContext)

const FiltersProvider: React.FC = ({ children }) => {
  const [queryParam, setQueryParam] = useState<QueryParamRestaurant>({
    offset: 0,
    size: 40,
    name: '',
    favorites: false,
  } as QueryParamRestaurant)

  const value = useMemo(() => ({ queryParam, setQueryParam }), [queryParam])

  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
}

export default FiltersProvider
